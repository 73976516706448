import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { environment } from '../../../environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { FieldsetModule } from 'primeng/fieldset';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-lead-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    AccordionModule,
    FieldsetModule,
    TagModule
  ],
  templateUrl: './lead-details.component.html',
  styleUrl: './lead-details.component.sass'
})
export class LeadDetailsComponent {

  s3ImageUrl: string = environment.s3ImageUrl;

  @Input() leadDetails: any = {};
  @Input() processingStatus: any[] = [];
  @Input() activeUser: any = "";

  @Output() addNoteEmitter = new EventEmitter<any>();
  @Output() changeStatusEmitter = new EventEmitter<any>();

  leadMessageEvent: string = "";

  constructor(
    private loadingService: LoadingService,
    private datePipe: DatePipe
  ) { }

  getLoading() {
    return this.loadingService.get();
  }

  onClickAddNote() {
    this.addNoteEmitter.emit({ message: this.leadMessageEvent });
  }

  onChangeStatus() {
    if(this.leadDetails.updatable) {
      this.changeStatusEmitter.emit();
    }
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  getEventFieldsetHeader(event: any): string {
    return `${event.createdBy}, ${this.datePipe.transform(event.createdAt, 'medium')}`;
  }

  getTagCursor(): string {
    if(this.activeUser == 'commercial' && this.leadDetails.updatable) {
      return 'pointer';
    }
    return 'auto';
  }

  getTagBackgroundColor(status: string): string {
    switch (status) {
      // Linkupp sponsorship processing status
      case 'STATUS_COMPLETED':
        return "#689f38";
      case 'STATUS_CANCELED':
        return "#d32f2f";
      default:
        return "#2196F3";
    }
  }

  identifyEvent(index: any, item: any) {
    return item.id
  }

}
