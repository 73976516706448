<div class="flex">
    <div class="hidden sm:flex flex-column h-screen text-white"
        style="position: sticky; top: 0; background: #1D2128FF;">
        <div class="m-auto">
            <p-button icon="pi pi-bars" (onClick)="showSidebarEvent()" [text]="true" />
        </div>

        <div class="text-gray-400">
            <ul class="list-none p-0 m-0" *ngIf="isSuperAdmin">
                <li>
                    <ul class="list-none p-0 m-1 overflow-hidden">
                        <li>
                            <a [routerLink]="['super-admin/metrics']" pRipple pTooltip="Métriques"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-chart-pie m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['super-admin/companies']" pRipple pTooltip="Entreprises"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-building m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['super-admin/users']" pRipple pTooltip="Utilisateurs"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-users m-auto"></i>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-0 m-0" *ngIf="isAdmin">
                <li>
                    <ul class="list-none p-0 m-1 overflow-hidden">
                        <li>
                            <a [routerLink]="['pro/admin/company-details']" pRipple pTooltip="Données entreprise"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-cog m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a (click)="showSidebarEvent('expandAdminMenuHumanResources')" pRipple
                                pTooltip="Ressources humaines" tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-users m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a (click)="showSidebarEvent('expandAdminMenuBusinessResources')" pRipple
                                pTooltip="Ressources d'affaires" tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a (click)="showSidebarEvent('expandAdminMenuPayments')" pRipple pRipple
                                pTooltip="Rémunérations" tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-wallet m-auto"></i>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-0 m-0" *ngIf="isCommercial">
                <li>
                    <ul class="list-none p-0 m-1 overflow-hidden">
                        <li>
                            <a [routerLink]="['pro/commercial/customers']" pRipple pTooltip="Mes clients"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-users m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/commercial/sponsorships']" pRipple pTooltip="Mes parrainages"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/commercial/leads']" pRipple pTooltip="Mes leads"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase m-auto"></i>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-0 m-0" *ngIf="isCustomer">
                <li>
                    <ul class="list-none p-0 m-1 overflow-hidden">
                        <li>
                            <a [routerLink]="['customer/companies']" pRipple pTooltip="Mes entreprises"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-building m-auto"></i>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['customer/sponsorships']" pRipple pTooltip="Mes parrainages"
                                tooltipPosition="right"
                                class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase m-auto"></i>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="flex align-content-end flex-wrap h-full">
            <div class="flex flex-column p-0 m-0">
                <ul class="list-none p-0 m-1 overflow-hidden">
                    <li>
                        <a (click)="onClickServiceMessage()" pRipple pTooltip="Demande de sollicitation"
                            tooltipPosition="right"
                            class="text-gray-400 flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                            <i class="pi pi-question-circle m-auto"></i>
                        </a>
                    </li>
                    <li>
                        <a (click)="myProfile()" pRipple pTooltip="Mon profil" tooltipPosition="right"
                            class="text-gray-400 flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                            <i class="pi pi-user m-auto"></i>
                        </a>
                    </li>
                    <li>
                        <a (click)="logOut()" pRipple pTooltip="Déconnexion" tooltipPosition="right"
                            class="text-red-600 no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                            <i class="pi pi-sign-out m-auto"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="flex-grow-1 px-3 py-2">
        <p-button icon="pi pi-bars" (onClick)="showSidebarEvent()" class="block sm:hidden" [text]="true" />
        <p-messages />
        <router-outlet></router-outlet>
    </div>
</div>

<p-sidebar #sidebarRef [(visible)]="sidebarVisible">
    <ng-template pTemplate="headless">
        <div class="flex flex-column h-full text-white" style="background: #1D2128FF;">
            <div class="flex p-2">
                <p-button icon="pi pi-home" label="Accueil" [rounded]="true" [text]="true" (onClick)="goToHomePage()"
                    [style]="{ color: '#545f9f', 'font-weight': '500' }" />
            </div>

            <div class="overflow-y-auto text-gray-400 font-bold">
                <ul class="list-none p-3 m-0" *ngIf="isSuperAdmin">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span>SUPER ADMIN</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['super-admin/metrics']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-chart-pie mr-2"></i>
                                    <span>Métriques</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['super-admin/companies']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-building mr-2"></i>
                                    <span>Entreprises</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['super-admin/users']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Utilisateurs</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="list-none p-3 m-0" *ngIf="isAdmin">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span>ADMIN. ENTREPRISE</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['pro/admin/company-details']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-cog mr-2"></i>
                                    <span>Données entreprise</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                    leaveToClass="hidden" leaveActiveClass="slideup"
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Ressources humaines</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                    [class]="expandAdminMenuHumanResources ? '' : 'hidden'">
                                    <li>
                                        <a [routerLink]="['pro/admin/employees']" (click)="closeCallback($event)"
                                            pRipple
                                            class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-users mr-2"></i>
                                            <span>Collaborateurs</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['pro/admin/customers']" (click)="closeCallback($event)"
                                            pRipple
                                            class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-users mr-2"></i>
                                            <span>Portefeuille client</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                    leaveToClass="hidden" leaveActiveClass="slideup"
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span>Ressources d'affaires</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                    [class]="expandAdminMenuBusinessResources ? '' : 'hidden'">
                                    <li>
                                        <a [routerLink]="['pro/admin/sponsorships']" (click)="closeCallback($event)"
                                            pRipple
                                            class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-briefcase mr-2"></i>
                                            <span>Parrainages entreprise</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['pro/admin/leads']" (click)="closeCallback($event)" pRipple
                                            class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-briefcase mr-2"></i>
                                            <span>Leads entreprise</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                    leaveToClass="hidden" leaveActiveClass="slideup"
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-wallet mr-2"></i>
                                    <span>Rémunérations</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                                    [class]="expandAdminMenuPayments ? '' : 'hidden'">
                                    <li>
                                        <a [routerLink]="['pro/admin/pending-payments']" (click)="closeCallback($event)"
                                            pRipple
                                            class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-wallet mr-2"></i>
                                            <span>En attente de paiement</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['pro/admin/payments-history']" (click)="closeCallback($event)"
                                            pRipple
                                            class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-wallet mr-2"></i>
                                            <span>Historique des paiements</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="list-none p-3 m-0" *ngIf="isCommercial">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span>COMMERCIAL</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['pro/commercial/customers']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Mes clients</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/commercial/sponsorships']" (click)="closeCallback($event)"
                                    pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span>Mes parrainages</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/commercial/leads']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span>Mes leads</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="list-none p-3 m-0" *ngIf="isCustomer">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span>CLIENT</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['customer/companies']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-building mr-2"></i>
                                    <span>Mes entreprises</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['customer/sponsorships']" (click)="closeCallback($event)" pRipple
                                    class="no-underline flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span>Mes parrainages</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="mt-auto">
                <div class="flex align-items-center">
                    <p-button class="m-2" icon="pi pi-question-circle" label="Demande de sollicitation" [text]="true"
                        (onClick)="onClickServiceMessage();closeCallback($event)"
                        [style]="{ color: 'var(--gray-400)', 'font-weight': '500' }" />
                </div>
                <div class="mx-3">
                    <p-divider [style]="{ margin: '0' }" />
                </div>                
                <div class="flex justify-content-between align-items-center">
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-user" [label]="authenticatedUsername" [text]="true"
                            (onClick)="myProfile();closeCallback($event)"
                            [style]="{ color: '#545f9f', 'font-weight': '500' }" />
                    </div>
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-sign-out" label="Déconnexion" [text]="true" severity="danger"
                            (onClick)="logOut();closeCallback($event)" />
                    </div>
                </div>
                <div class="flex justify-content-center p-2 mb-2">
                    <small>
                        <a (click)="onClickPatchNote()" class="cursor-pointer text-gray-400">Version {{ version }}</a>
                    </small>
                </div>
            </div>
        </div>
    </ng-template>
</p-sidebar>

<p-dialog [header]="dialogPatchNotesHeader" [modal]="true" [(visible)]="showPatchNotes" [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [draggable]="false">

    <div *ngFor="let patchNote of patchNotes">
        <h1>{{ patchNote.version }} - {{ patchNote.createdAt | date:'medium' }}</h1>
        <div [innerHtml]="patchNote.description"></div>
    </div>
</p-dialog>

<p-dialog header="Formulaire de sollicitation" [modal]="true" [(visible)]="showServiceMessage"
    [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [draggable]="false">

    <p class="mb-4 text-sm text-gray-500">
        <i class="pi pi-info-circle" style="font-size: 0.75rem"></i>
        Ce formulaire de sollicitation vous permet de soumettre des demandes claires et structurées au service de la plateforme.
        Il comprend des catégories spécifiques pour orienter la demande (Incident, Reporter un bug, Suggestion et Autre question).
        En centralisant ces informations, ce formulaire facilite le traitement rapide et efficace des demandes, tout en assurant une meilleure organisation des échanges par email entre vous et notre service.
    </p>

    <form [formGroup]="serviceMessageForm" (ngSubmit)="onSubmitServiceMessage()">
        <div class="flex flex-column gap-2">
            <div class="flex flex-column gap-1">
                <label class="text-xs font-medium" for="companyName">Cétgorie</label>
                <p-dropdown formControlName="categoryId" [options]="serviceMessageCategories" optionLabel="label"
                    optionValue="id" placeholder="Choisir une catégorie" />
            </div>
            <div class="flex flex-column gap-1">
                <label class="text-xs font-medium" for="subject">Sujet</label>
                <input pInputText id="subject" aria-describedby="subject-help" formControlName="subject" />
                <small class="text-red-600" id="subject-edit"
                    [hidden]="!serviceMessageForm.controls.subject.errors?.['pattern']">
                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                </small>
            </div>
            <div class="flex flex-column gap-1">
                <label class="text-xs font-medium" for="message">Messgae (max. 255 caractères)</label>
                <textarea pInputTextarea formControlName="message" maxlength="255" class="w-12"></textarea>
                <small class="text-red-600" id="message-edit"
                    [hidden]="!serviceMessageForm.controls.message.errors?.['pattern']">
                    Règles: entre 15 et 255 caractères.
                </small>
            </div>
            <div class="flex gap-2 justify-content-end mt-5">
                <p-button icon="pi pi-times" label="Annuler" severity="danger"
                    (onClick)="closeServiceMessageDialog()" />
                <p-button label="Enregistrer" icon="pi pi-save" type="submit"
                    [disabled]="!serviceMessageForm.valid || getLoading()" />
            </div>
        </div>
    </form>
</p-dialog>