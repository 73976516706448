import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BookDemoRequest } from '../payloads/requests/bookDemoRequest';

const API = `${environment.rootApiUrl}/api/public`;

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(
    private http: HttpClient
  ) { }

  bookDemo(request: BookDemoRequest): Observable<any> {
    return this.http.post(`${API}/book-demo`, request);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${API}/forgot-password`, {},
      {
        params: {
          email: email
        }
      }
    );
  }

  checkTokenBeforeReset(token: string): Observable<any> {
    return this.http.get(`${API}/check-token-before-reset`,
      {
        params: {
          token: token
        }
      }
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.put(`${API}/reset-password`, password,
      {
        params: {
          token: token
        }
      }
    );
  }

}
