export class BookDemoRequest {

  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;

  constructor(companyName: string | null, firstName: string | null, lastName: string | null, email: string | null, phone: string | null) {
    this.companyName = companyName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
  }

}