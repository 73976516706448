import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { TimelineModule } from 'primeng/timeline';
import { environment } from '../../../../../environments/environment';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-company-details',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    InputTextModule,
    AccordionModule,
    TimelineModule,
    TooltipModule
  ],
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.sass'
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {

  companyDetails: any = {};

  s3ImageUrl: string = environment.s3ImageUrl;

  private _destroy$ = new Subject<void>();

  constructor(
    private superAdminService: SuperAdminService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.superAdminService.getCompanyDetails(this.route.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companyDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  identifyRole(index: any, item: any) {
    return item.id
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
