import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LeadDetailsComponent as ReusableLeadDetails } from '../../../../../reusable-components/lead-details/lead-details.component';

@Component({
  selector: 'app-lead-details-admin',
  standalone: true,
  imports: [
    ReusableLeadDetails
  ],
  templateUrl: './lead-details.component.html',
  styleUrl: './lead-details.component.sass'
})
export class LeadDetailsComponent implements OnInit, OnDestroy {

  leadDetails: any = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.adminService.getLeadDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.leadDetails = response;
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
