import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MyProfileComponent } from './components/portal/my-profile/my-profile.component';
import { authCanActivateGuard } from './auth-can-activate.guard';
import { PortalComponent } from './components/portal/portal.component';
import { EmployeesComponent } from './components/portal/pro/admin/employees/employees.component';
import { CustomerComponent } from './components/portal/customer/customer.component';
import { ProComponent } from './components/portal/pro/pro.component';
import { AdminComponent } from './components/portal/pro/admin/admin.component';
import { CommercialComponent } from './components/portal/pro/commercial/commercial.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CompaniesComponent as CompaniesComponentCustomer } from './components/portal/customer/companies/companies.component';
import { UnavailableComponent } from './components/unavailable/unavailable.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { CustomersComponent as CustomersComponentAdmin } from './components/portal/pro/admin/customers/customers.component';
import { SuperAdminComponent } from './components/portal/super-admin/super-admin.component';
import { CompaniesComponent as CompaniesComponentSuperAdmin } from './components/portal/super-admin/companies/companies.component';
import { CompanyDetailsComponent as CompanyDetailsComponentAdmin } from './components/portal/pro/admin/company-details/company-details.component';
import { CompanyDetailsComponent as CompanyDetailsComponentSuperAdmin } from './components/portal/super-admin/company-details/company-details.component';
import { EmployeeDetailsComponent } from './components/portal/pro/admin/employee-details/employee-details.component';
import { SponsorshipDetailsComponent as SponsorshipDetailsComponentCustomer } from './components/portal/customer/sponsorship-details/sponsorship-details.component';
import { SponsorshipDetailsComponent as SponsorshipDetailsComponentCommercial } from './components/portal/pro/commercial/sponsorship-details/sponsorship-details.component';
import { SponsorshipsComponent as SponsorshipsComponentCustomer } from './components/portal/customer/sponsorships/sponsorships.component';
import { SponsorshipsComponent as SponsorshipsComponentCommercial } from './components/portal/pro/commercial/sponsorships/sponsorships.component';
import { SponsorshipsComponent as SponsorshipsComponentAdmin } from './components/portal/pro/admin/sponsorships/sponsorships.component';
import { CustomersComponent as CustomersComponentCommercial } from './components/portal/pro/commercial/customers/customers.component';
import { SponsorshipDetailsComponent as SponsorshipDetailsComponentAdmin } from './components/portal/pro/admin/sponsorship-details/sponsorship-details.component';
import { LeadsComponent as LeadsComponentAdmin } from './components/portal/pro/admin/leads/leads.component';
import { LeadDetailsComponent as LeadDetailsComponentAdmin } from './components/portal/pro/admin/lead-details/lead-details.component';
import { LeadsComponent as LeadsComponentCommercial } from './components/portal/pro/commercial/leads/leads.component';
import { LeadDetailsComponent as LeadDetailsComponentCommercial } from './components/portal/pro/commercial/lead-details/lead-details.component';
import { PendingPaymentsComponent } from './components/portal/pro/admin/pending-payments/pending-payments.component';
import { PaymentsHistoryComponent } from './components/portal/pro/admin/payments-history/payments-history.component';
import { PaymentDetailsComponent } from './components/portal/pro/admin/payment-details/payment-details.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { UsersComponent } from './components/portal/super-admin/users/users.component';
import { UserDetailsComponent } from './components/portal/super-admin/user-details/user-details.component';
import { MetricsComponent } from './components/portal/super-admin/metrics/metrics.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    title: 'Accueil'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
    canActivate: [authCanActivateGuard]
  },
  {
    path: 'portal',
    component: PortalComponent,
    canActivate: [authCanActivateGuard],
    children: [
      {
        path: 'my-profile',
        component: MyProfileComponent,
        title: 'MON PROFIL',
        canActivate: [authCanActivateGuard]
      },
      {
        path: 'super-admin',
        component: SuperAdminComponent,
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'companies',
            component: CompaniesComponentSuperAdmin,
            title: 'ENTREPRISES'
          },
          {
            path: 'company-details/:reference',
            component: CompanyDetailsComponentSuperAdmin,
            title: 'DETAILS ENTREPRISE'
          },
          {
            path: 'users',
            component: UsersComponent,
            title: 'UTILISATEURS'
          },
          {
            path: 'user-details/:reference',
            component: UserDetailsComponent,
            title: 'DETAILS UTILISATEUR'
          },
          {
            path: 'metrics',
            component: MetricsComponent,
            title: 'METRIQUES'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/super-admin/metrics'
          }
        ]
      },
      {
        path: 'customer',
        component: CustomerComponent,
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'sponsorship-details/:reference',
            component: SponsorshipDetailsComponentCustomer,
            title: 'DETAILS PARRAINAGE'
          },
          {
            path: 'sponsorships',
            component: SponsorshipsComponentCustomer,
            title: 'PARRAINAGES'
          },
          {
            path: 'companies',
            component: CompaniesComponentCustomer,
            title: 'MES ENTREPRISES'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/customer/sponsorships'
          }
        ]
      },
      {
        path: 'pro',
        component: ProComponent,
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'admin',
            component: AdminComponent,
            canActivate: [authCanActivateGuard],
            children: [
              {
                path: 'company-details',
                component: CompanyDetailsComponentAdmin,
                title: 'DONNEES ENTREPRISE'
              },
              {
                path: 'employees',
                component: EmployeesComponent,
                title: 'COLLABORATEURS'
              },
              {
                path: 'customers',
                component: CustomersComponentAdmin,
                title: 'PORTEFEUILLE CLIENT'
              },
              {
                path: 'employee-details/:reference',
                component: EmployeeDetailsComponent,
                title: 'DETAILS EMPLOYE'
              },
              {
                path: 'sponsorships',
                component: SponsorshipsComponentAdmin,
                title: 'PARRAINAGES'
              },
              {
                path: 'sponsorship-details/:reference',
                component: SponsorshipDetailsComponentAdmin,
                title: 'DETAILS PARRAINAGE'
              },
              {
                path: 'pending-payments',
                component: PendingPaymentsComponent,
                title: 'EN ATTENTE DE PAIEMENT'
              },
              {
                path: 'payments-history',
                component: PaymentsHistoryComponent,
                title: 'HISTORIQUE DES PAIEMENTS'
              },
              {
                path: 'payment-details/:id',
                component: PaymentDetailsComponent,
                title: 'DETAILS PAIEMENT'
              },
              {
                path: 'leads',
                component: LeadsComponentAdmin,
                title: 'LEADS'
              },
              {
                path: 'lead-details/:reference',
                component: LeadDetailsComponentAdmin,
                title: 'DETAILS LEAD'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/portal/pro/admin/company-details'
              }
            ]
          },
          {
            path: 'commercial',
            component: CommercialComponent,
            canActivate: [authCanActivateGuard],
            children: [
              {
                path: 'sponsorship-details/:reference',
                component: SponsorshipDetailsComponentCommercial,
                title: 'DETAILS PARRAINAGE'
              },
              {
                path: 'sponsorships',
                component: SponsorshipsComponentCommercial,
                title: 'PARRAINAGES'
              },
              {
                path: 'customers',
                component: CustomersComponentCommercial,
                title: 'MES CLIENTS'
              },
              {
                path: 'leads',
                component: LeadsComponentCommercial,
                title: 'LEADS'
              },
              {
                path: 'lead-details/:reference',
                component: LeadDetailsComponentCommercial,
                title: 'DETAILS LEAD'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/portal/pro/commercial/sponsorships'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'Forgot password',
    canActivate: [authCanActivateGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    title: 'Reset password',
    canActivate: [authCanActivateGuard]
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    title: 'Not found'
  },
  {
    path: 'unavailable',
    component: UnavailableComponent,
    title: 'Unavailable'
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    title: 'Forbidden'
  },
  {
    path: '**',
    redirectTo: '/page-not-found'
  }
];
