import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../reusable-components/sponsorship-details/sponsorship-details.component';

@Component({
  selector: 'app-sponsorship-details-customer',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};
  transactionDetails: any = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.customerService.getSponsorshipDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails = response;
          this.sponsorshipDetails.bridgeTransactionDetailsOrStatus = JSON.parse(this.sponsorshipDetails.bridgeTransactionDetailsOrStatus);
        }
      });
  }

  addNote(event: any) {
    this.customerService.addNoteToSponsorship(this.sponsorshipDetails.id, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails.events.unshift(response);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
