import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewSponsorshipRequest } from '../payloads/requests/newSponsorshipRequest';
import { environment } from '../../environments/environment';
import { CheckCustomerRequest } from '../payloads/requests/checkCustomerRequest';

const API = `${environment.rootApiUrl}/api/customer`;

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient
  ) { }

  getAllSponsorships(): Observable<any> {
    return this.http.get(`${API}/sponsorships`);
  }

  getSponsorshipDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/sponsorships/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  addNoteToSponsorship(sponsorshipId: number, message: string): Observable<any> {
    return this.http.post(`${API}/sponsorships/add-note`, message,
      {
        params: {
          sponsorshipId: sponsorshipId
        }
      }
    );
  }

  createSponsorship(request: NewSponsorshipRequest): Observable<any> {
    return this.http.post(`${API}/sponsorships`, request);
  }

  checkCustomer(request: CheckCustomerRequest, sponsorshipCreationMode: boolean): Observable<any> {
    return this.http.post(`${API}/sponsorships/check-customer`, request,
      {
        params: {
          sponsorshipCreationMode: sponsorshipCreationMode
        }
      }
    );
  }

  getAllCompanies(sponsorshipCreationMode: boolean): Observable<any> {
    return this.http.get(`${API}/companies`,
      {
        params: {
          sponsorshipCreationMode: sponsorshipCreationMode
        }
      }
    );
  }

}
