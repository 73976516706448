<app-sponsorship-details [sponsorshipDetails]="sponsorshipDetails" [processingStatus]="processingStatus"
    [activeUser]="'commercial'" (addNoteEmitter)="addNote($event)"
    (changeStatusEmitter)="updateStatusDialog()"></app-sponsorship-details>

<p-dialog header="Changer le statut" [(visible)]="showChangeStatusDialog" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [draggable]="false" [resizable]="false"
    [style]="{ width: '25rem' }">

    <p-messages key="message-key-dialog-update-status" />

    <div class="w-full p-2 mb-2">
        <div class="flex flex-column gap-3">
            <div *ngFor="let status of processingStatus; trackBy:identifyStatus" class="field-checkbox">
                <p-radioButton [inputId]="status.id" name="status" [value]="status.id" [(ngModel)]="selectedStatus"
                    (onClick)="onClickStatus($event)" />
                <label [for]="status.id" class="ml-2">
                    {{ status.label }}
                </label>
            </div>
        </div>
    </div>

    <div [hidden]="hideCustomRewardAmount">
        <div class="flex flex-column gap-1 p-2 mb-5">
            <label class="font-medium" for="integeronly">Personnaliser le montant de la récompense</label>
            <p-inputNumber class="w-full" styleClass="w-full" inputId="integeronly" [(ngModel)]="customRewardAmount"
                [placeholder]="getCustomRewardAmountPlaceHolder()" mode="currency" currency="EUR" locale="fr-FR" />
            <small class="text-red-600" [hidden]="!badRequestCustomReward.length">
                {{ badRequestCustomReward }}
            </small>
        </div>
    </div>

    <div class="flex gap-2 justify-content-end">
        <p-button icon="pi pi-times" label="Annuler" severity="danger" (onClick)="rejectUpdateStatus()" />
        <p-button label="Enregistrer" icon="pi pi-save" (onClick)="acceptUpdateStatus()" [loading]="getLoading()"
            [disabled]="selectedStatus == null || selectedStatus == initialSelectedStatus || getLoading()" />
    </div>
</p-dialog>