import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CommercialService } from '../../../../../services/commercial.service';
import { SponsorshipsComponent as ReusableSponsorships } from '../../../../../reusable-components/sponsorships/sponsorships.component';

@Component({
  selector: 'app-sponsorships-commercial',
  standalone: true,
  imports: [
    ReusableSponsorships
  ],
  templateUrl: './sponsorships.component.html',
  styleUrl: './sponsorships.component.sass'
})
export class SponsorshipsComponent implements OnInit, OnDestroy {

  sponsorships: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    this.commercialService.getAllSponsorships()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorships = response;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
