<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="p-2 surface-card" *ngIf="!companyDetailsIsSet() || getLoading()">
            <div class="flex mb-3">
                <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                <div>
                    <p-skeleton width="10rem" styleClass="mb-2" />
                    <p-skeleton width="5rem" styleClass="mb-2" />
                    <p-skeleton height=".5rem" />
                </div>
            </div>
            <p-skeleton width="100%" height="150px" />
            <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
            </div>
        </div>

        <div *ngIf="companyDetailsIsSet()">
            <div class="md:flex justify-content-between">
                <div class="p-2 mb-2 md:mb-5 text-sm text-500 font-medium">
                    Référence: {{ companyDetails.reference}}
                </div>
                <div class="p-2 mb-5">
                    <p-tag class="cursor-pointer"
                        severity="success"
                        icon="pi pi-sparkles"
                        [value]="companyDetails.subscription?.label | uppercase" [rounded]="true" (click)="clickShowSubscriptionDetails()" />
                </div>
            </div>

            <div class="flex justify-content-between p-2 mb-8">
                <div class="flex">
                    <div class="flex flex-column">
                        <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                            <img src="assets/images/web/no_pic.png" class="w-full"
                                *ngIf="companyDetails.logoFilePath === null" [alt]="companyDetails.name" />
                            <img [src]="getLogoFullUrl(companyDetails.logoFilePath)" class="w-full"
                                [alt]="companyDetails.name" *ngIf="companyDetails.logoFilePath != null" />
                        </div>
                        <div class="flex justify-content-center font-bold">
                            {{ companyDetails.name | uppercase}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    SIREN
                </div>
                <div class="flex">
                    {{ companyDetails.siren }}
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    COORDONNÉES
                </div>
                <div class="flex">
                    {{ companyDetails.email }}
                </div>
                <div class="flex">
                    {{ companyDetails.phone }}
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    ADRESSE
                </div>
                <div class="flex">
                    {{ companyDetails.address }}
                </div>
                <div class="flex">
                    {{ companyDetails.postalCode }}
                </div>
                <div class="flex">
                    {{ companyDetails.city }}
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    DESCRIPTION
                </div>
                <div class="flex">
                    {{ companyDetails.description }}
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    ADMINISTRATEUR
                </div>
                <div class="flex">
                    {{ companyDetails.adminFullName }}
                </div>
                <div class="flex">
                    {{ companyDetails.adminEmail }}
                </div>
                <div class="flex">
                    {{ companyDetails.adminPhone }}
                </div>
            </div>

            <div class="md:flex justify-content-between mb-5">
                <div class="flex flex-column p-2 md:mb-0 mb-5">
                    <div class="flex font-bold mb-2">
                        PARRAINAGES LINKUPP
                    </div>
                    <div class="flex">
                        <span class="font-medium">
                            Tous:&nbsp;
                        </span>
                        {{ companyDetails.sponsorshipsNumber }}
                    </div>
                    <div class="flex">
                        <span class="font-medium">
                            En cours:&nbsp;
                        </span>
                        {{ companyDetails.runningSponsorshipsNumber }}
                    </div>
                    <div class="flex">
                        <span class="font-medium">
                            Validé(s):&nbsp;
                        </span>
                        {{ companyDetails.completedSponsorshipsNumber }}
                    </div>
                    <div class="flex">
                        <span class="font-medium">
                            Annulé(s):&nbsp;
                        </span>
                        {{ companyDetails.canceledSponsorshipsNumber }}
                    </div>
                </div>
                <div class="flex flex-column p-2 md:p-5">
                    <div class="flex font-bold mb-2">
                        LEADS LINKUPP
                    </div>
                    <div class="flex md:justify-content-end">
                        <span class="font-medium">
                            Tous:&nbsp;
                        </span>
                        {{ companyDetails.leadsNumber }}
                    </div>
                    <div class="flex md:justify-content-end">
                        <span class="font-medium">
                            En cours:&nbsp;
                        </span>
                        {{ companyDetails.runningLeadsNumber }}
                    </div>
                    <div class="flex md:justify-content-end">
                        <span class="font-medium">
                            Validé(s):&nbsp;
                        </span>
                        {{ companyDetails.completedLeadsNumber }}
                    </div>
                    <div class="flex md:justify-content-end">
                        <span class="font-medium">
                            Annulé(s):&nbsp;
                        </span>
                        {{ companyDetails.canceledLeadsNumber }}
                    </div>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    RESSOURCES HUMAINES
                </div>
                <p-accordion [multiple]="true">
                    <p-accordionTab header="EMPLOYÉS" *ngIf="companyDetails.employees?.length > 0">
                        <div class="flex flex-column p-2 mb-5">
                            <p-table #dt1 [value]="companyDetails.employees" sortField="firstName" [sortOrder]="1"
                                [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']"
                                [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'"
                                [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]">
            
                                <ng-template pTemplate="caption">
                                    <div class="flex align-items-center justify-content-end gap-2">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                                                placeholder="Chercher..." />
                                        </span>
                                    </div>
                                </ng-template>
            
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                                        <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                                        <th>Rôle(s)</th>
                                        <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                                        <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-employee>
                                    <tr>
                                        <td>
                                            <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                                            <span>{{ employee.firstName }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Nom:&nbsp;</span>
                                            <span>{{ employee.lastName }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Rôles:&nbsp;</span>
                                            <div class="flex flex-row flex-wrap gap-4">
                                                <div class="flex" *ngFor="let role of employee.roles; trackBy:identifyRole">
                                                    <i [class]="role.primengIco" [pTooltip]="role.label" tooltipPosition="top"></i>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Email:&nbsp;</span>
                                            <span>{{ employee.email }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                                            <span>{{ employee.phone }}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-accordionTab>
                    <p-accordionTab header="CLIENTS" *ngIf="companyDetails.customers?.length > 0">
                        <div class="flex flex-column p-2 mb-5">
                            <p-table #dt2 [value]="companyDetails.customers" sortField="firstName" [sortOrder]="1"
                                [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']"
                                [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'"
                                [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]">
            
                                <ng-template pTemplate="caption">
                                    <div class="flex align-items-center justify-content-end gap-2">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
                                                placeholder="Chercher..." />
                                        </span>
                                    </div>
                                </ng-template>
            
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                                        <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                                        <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                                        <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-customer>
                                    <tr>
                                        <td>
                                            <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                                            <span>{{ customer.firstName }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Nom:&nbsp;</span>
                                            <span>{{ customer.lastName }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Email:&nbsp;</span>
                                            <span>{{ customer.email }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                                            <span>{{ customer.phone }}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-accordionTab>
                    <p-accordionTab header="PROSPECTS" *ngIf="companyDetails.prospects?.length > 0">
                        <div class="flex flex-column p-2 mb-5">        
                            <p-table #dt3 [value]="companyDetails.prospects" sortField="firstName" [sortOrder]="1"
                                [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']"
                                [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'"
                                [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]">
            
                                <ng-template pTemplate="caption">
                                    <div class="flex align-items-center justify-content-end gap-2">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text"
                                                (input)="dt3.filterGlobal($any($event.target).value, 'contains')"
                                                placeholder="Chercher..." />
                                        </span>
                                    </div>
                                </ng-template>
            
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                                        <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                                        <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                                        <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-prospect>
                                    <tr>
                                        <td>
                                            <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                                            <span>{{ prospect.firstName }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Nom:&nbsp;</span>
                                            <span>{{ prospect.lastName }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Email:&nbsp;</span>
                                            <span>{{ prospect.email }}</span>
                                        </td>
                                        <td>
                                            <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                                            <span>{{ prospect.phone }}</span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
            
            <div class="flex flex-column p-2 mb-5" *ngIf="companyDetails.events?.length > 0">
                <div class="flex font-bold mb-2">
                    HISTORIQUE D'ENTREPRISE
                </div>
                <p-accordion>
                    <p-accordionTab header="Historique">
                        <p-timeline [value]="companyDetails.events" class="m-1">
                            <ng-template pTemplate="content" let-event>
                                <small>{{ event.createdBy }}, {{ event.createdAt | date:'medium' }}</small>
                                <p class="p-text-secondary">{{ event.eventMessage }}</p>
                            </ng-template>
                        </p-timeline>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Détails de l'offre" [modal]="true" [(visible)]="showSubscriptionDetails" [style]="{ width: '25rem' }">
    <p-messages key="message-key-dialog-plan-details" />

    <form [formGroup]="updatePlanForm" (ngSubmit)="onSubmitUpdatePlan()">
        <div class="flex flex-column">
            <div class="font-medium mb-2">
                <span class="underline">Offre :</span>

                <div class="flex gap-3 mt-2">
                    <div *ngFor="let plan of plans; trackBy:identifyPlan" class="field-checkbox">
                        <p-radioButton 
                            [inputId]="plan.id"
                            [value]="plan.id"
                            formControlName="planId"
                            (onClick)="onClickRadioButton($event)" />
                        <label [for]="plan.id" class="ml-2">
                            {{ plan.label }}
                        </label>
                    </div>
                </div>

                <div [hidden]="hidePremiumPlanSettings" class="mt-1 mb-3">
                    <div class="flex flex-column gap-1">
                        <p-inputGroup>
                            <p-inputGroupAddon>
                                <i class="pi pi-user"></i>
                            </p-inputGroupAddon>
                            <p-inputNumber placeholder="Quotas utilisateurs" formControlName="premiumCustomMaxUsers" [useGrouping]="false" />
                        </p-inputGroup>
                        <p-inputGroup>
                            <p-inputGroupAddon>
                                <i class="pi pi-send"></i>
                            </p-inputGroupAddon>
                            <p-inputNumber placeholder="Quotas transactions" formControlName="premiumCustomMaxBridgeTransastionPerMonth" [useGrouping]="false" />
                        </p-inputGroup>
                        <p-inputGroup>
                            <p-inputGroupAddon>
                                <i class="pi pi-money-bill"></i>
                            </p-inputGroupAddon>
                            <p-inputNumber placeholder="Prix/mois" formControlName="premiumCustomPrice" mode="currency" currency="EUR" locale="fr-FR" />
                        </p-inputGroup>
                    </div>
                </div>

            </div>
            <div class="font-medium" *ngIf="companyDetails.subscription?.name === 'PLAN_PREMIUM'">
                <span class="underline">Quotas :</span>
                <ul>
                    <li>{{ companyDetails.subscription?.maxUsers }} utilisateur(s)</li>
                    <li>{{ companyDetails.subscription?.maxBridgeTransastionPerMonth }} transactions/mois</li>
                </ul>
            </div>
            <div class="font-medium mb-2" [hidden]="hidePlanPrice">
                <span class="underline">Prix :</span>
                <span>&nbsp;{{ selectedPlanPrice }} €/mois</span>
            </div>
            <div class="font-medium">
                <span class="underline">Description :</span>
                <div [innerHtml]="selectedPlanDescritpion"></div>
            </div>
            <div class="font-medium">
                <span class="underline">Limites :</span>
                <div class="flex flex-column gap-1 mt-2">
                    <span>Utilisateur(s)</span>
                    <p-progressBar [value]="getUserPercentage()">
                        <ng-template pTemplate="content" let-value> 
                            <span>{{ companyDetails.countUsers }}/{{ companyDetails.subscription?.maxUsers }}</span>    
                        </ng-template>
                    </p-progressBar>
                </div>
                <div class="flex flex-column gap-1 mt-2">
                    <span>Transaction(s)/mois</span>
                    <p-progressBar [value]="getTransactionsPercentage()">
                        <ng-template pTemplate="content" let-value> 
                            <span>{{ companyDetails.countMonthlyTransactions }}/{{ companyDetails.subscription?.maxBridgeTransastionPerMonth }}</span>    
                        </ng-template>
                    </p-progressBar>
                </div>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" severity="danger" (onClick)="closeSubscriptionDetails()" />
            <p-button label="Enregistrer" icon="pi pi-save" type="submit" [loading]="getLoading()"
                [disabled]="!updatePlanForm.valid || getLoading()" />
        </div>
    </form>
</p-dialog>