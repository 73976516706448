import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { environment } from '../../../environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';
import { FieldsetModule } from 'primeng/fieldset';
import { SkeletonModule } from 'primeng/skeleton';
import { BadgeModule } from 'primeng/badge';

@Component({
  selector: 'app-sponsorship-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    AccordionModule,
    TooltipModule,
    TagModule,
    FieldsetModule,
    SkeletonModule,
    BadgeModule
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent {

  s3ImageUrl: string = environment.s3ImageUrl;

  @Input() sponsorshipDetails: any = {};
  @Input() processingStatus: any[] = [];
  @Input() activeUser: string = "";

  @Output() addNoteEmitter = new EventEmitter<any>();
  @Output() changeStatusEmitter = new EventEmitter<any>();

  sponsorshipMessageEvent: string = "";

  constructor(
    private loadingService: LoadingService,
    private datePipe: DatePipe
  ) { }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  onClickAddNote() {
    this.addNoteEmitter.emit({ message: this.sponsorshipMessageEvent });
    this.sponsorshipMessageEvent = "";
  }

  onChangeStatus() {
    if(this.sponsorshipDetails.updatable) {
      this.changeStatusEmitter.emit();
    }    
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  getTagBackgroundColor(status: string): string {
    switch (status) {
      // Bridge payment status
      case 'CREA':
        return "#2196F3";
      case 'ACTC':
        return "#2196F3";
      case 'PDNG':
        return "#fbc02d";
      case 'ACSC':
        return "#689f38";
      case 'RJCT':
        return "#d32f2f";
      // Linkupp sponsorship processing status
      case 'STATUS_COMPLETED':
        return "#689f38";
      case 'STATUS_CANCELED':
        return "#d32f2f";
      default:
        return "#2196F3";
    }
  }

  setStatusTagTooltipDesc(status: string): string {
    switch (status) {
      case 'CREA':
        return "Le paiement a été créé côté Bridge";
      case 'ACTC':
        return "Le paiement a été créé côté banque";
      case 'PDNG':
        return "Le paiement a été confirmé et est en attente d'exécution par la banque";
      case 'ACSC':
        return "Le paiement a été exécuté par la banque";
      case 'RJCT':
        return "Le paiement a été rejeté";
      default:
        return "";
    }
  }

  setStatusReasonTagTooltipDesc(statusReason: string): string {
    switch (statusReason) {
      case 'AC01':
        return "Le numéro de compte est invalide ou n'existe pas";
      case 'AC04':
        return "Le compte est fermé et ne peut pas être utilisé";
      case 'AC06':
        return "Le compte est bloqué et ne peut pas être utilisé";
      case 'AG01':
        return "Transaction interdite sur ce type de compte";
      case 'AM18':
        return "Le nombre de transactions dépasse la limite d'acceptation ASPSP";
      case 'CH03':
        return "La date d'exécution demandée est trop éloignée dans le futur";
      case 'CUST':
        return "Le rejet est dû au débiteur : refus ou manque de liquidité";
      case 'DS02':
        return "Un utilisateur autorisé a annulé la commande";
      case 'FF01':
        return "Le rejet est dû à la demande de paiement d'origine qui n'est pas valide (syntaxe, structure ou valeurs)";
      case 'FRAD':
        return "La demande de paiement est considérée comme frauduleuse";
      case 'MS03':
        return "Aucune raison précisée par l'ASPSP (généralement la banque)";
      case 'NOAS':
        return "Le PSU (l'utilisateur) n'a ni accepté ni rejeté la demande de paiement et un délai d'attente s'est produit";
      case 'RR01':
        return "Le compte du débiteur et/ou l'identification sont manquants ou incohérents";
      case 'RR03':
        return "La spécification du nom et/ou de l'adresse du créancier nécessaire aux exigences réglementaires est insuffisante ou manquante";
      case 'RR04':
        return "Rejet pour des raisons réglementaires";
      case 'RR12':
        return "Identification non valide ou manquante requise dans un pays ou un type de paiement particulier";
      default:
        return "";
    }
  }

  getEventFieldsetHeader(event: any): string {
    return `${event.createdBy}, ${this.datePipe.transform(event.createdAt, 'medium')}`;
  }

  getTagCursor(): string {
    if(this.activeUser == 'commercial' && this.sponsorshipDetails.updatable) {
      return 'pointer';
    }
    return 'auto';
  }

  identifyEvent(index: number) {
    return index
  }

  sponsorshipDetailsIsSet(): boolean {
    if(!Object.keys(this.sponsorshipDetails).length) {
      return false;
    }
    return true;
  }

  hideErrorBadge(status: string): boolean {
    if(status === "RJCT") {
      return false;
    }
    return true;
  }

}
