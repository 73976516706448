<!-- Main structure -->
<div class="block h-screen surface-50">
    <div class="h-full px-4 py-8 md:px-6 lg:px-8 flex justify-content-center">
        <div class="text-700 text-center flex flex-column align-items-center justify-content-center">
            <div class="flex text-900 font-bold text-5xl mb-3">503 Unavailable</div>
            <div class="flex text-700 text-2xl mb-3">Le service backend est momentanément indisponible.</div>
            <p-button icon="pi pi-home" [rounded]="true" [text]="true" (onClick)="goToHomePage()" />
        </div>
    </div>
</div>
<!-- Main structure -->