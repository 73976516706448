<div class="flex">
    <div class="hidden xl:flex flex-column h-screen" style="position: sticky; top: 0; background: #1D2128FF; color: #A7ADB7FF;">
        <div class="flex justify-content-between p-2">
            <div class="flex">
                <p-button icon="pi pi-home" [rounded]="true" [text]="true" (onClick)="goToHomePage()" />
            </div>
            <div class="flex">
                <p-button icon="pi pi-sun" [rounded]="true" [text]="true" (onClick)="changeTheme('saga-blue')"
                    [hidden]="theme === 'saga-blue'" />
                <p-button icon="pi pi-moon" [rounded]="true" [text]="true" (onClick)="changeTheme('arya-blue')"
                    [hidden]="theme === 'arya-blue'" />
            </div>
        </div>
        
        <div>
            <ul class="list-none p-3 m-0" *ngIf="isSuperAdmin">
                <li>
                    <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                        <span class="font-bold">SUPER ADMIN</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a [routerLink]="['super-admin/companies']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-building mr-2"></i>
                                <span class="font-bold">Entreprises</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-3 m-0" *ngIf="isAdmin">
                <li>
                    <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                        <span class="font-bold">ADMIN. ENTREPRISE</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a [routerLink]="['pro/admin/company-details']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-cog mr-2"></i>
                                <span class="font-bold">Données entreprise</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                leaveToClass="hidden" leaveActiveClass="slideup"
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-users mr-2"></i>
                                <span class="font-bold">Ressources humaines</span>
                                <i class="pi pi-chevron-down ml-auto"></i>
                            </a>
                            <ul
                                class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                <li>
                                    <a [routerLink]="['pro/admin/employees']" pRipple
                                        class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-users mr-2"></i>
                                        <span class="font-bold">Collaborateurs</span>
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['pro/admin/customers']" pRipple
                                        class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-users mr-2"></i>
                                        <span class="font-bold">Portefeuille client</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                leaveToClass="hidden" leaveActiveClass="slideup"
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase mr-2"></i>
                                <span class="font-bold">Ressources d'affaires</span>
                                <i class="pi pi-chevron-down ml-auto"></i>
                            </a>
                            <ul
                                class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                <li>
                                    <a [routerLink]="['pro/admin/sponsorships']" pRipple
                                        class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-briefcase mr-2"></i>
                                        <span class="font-bold">Parrainages entreprise</span>
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['pro/admin/leads']" pRipple
                                        class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-briefcase mr-2"></i>
                                        <span class="font-bold">Leads entreprise</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                leaveToClass="hidden" leaveActiveClass="slideup"
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-wallet mr-2"></i>
                                <span class="font-bold">Rémunérations</span>
                                <i class="pi pi-chevron-down ml-auto"></i>
                            </a>
                            <ul
                                class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                <li>
                                    <a [routerLink]="['pro/admin/pending-payments']" pRipple
                                        class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-wallet mr-2"></i>
                                        <span class="font-bold">En attente de paiement</span>
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['pro/admin/payments-history']" pRipple
                                        class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-wallet mr-2"></i>
                                        <span class="font-bold">Historique des paiements</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-3 m-0" *ngIf="isCommercial">
                <li>
                    <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                        <span class="font-bold">COMMERCIAL</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a [routerLink]="['pro/commercial/customers']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-users mr-2"></i>
                                <span class="font-bold">Mes clients</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/commercial/sponsorships']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase mr-2"></i>
                                <span class="font-bold">Mes parrainages</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/commercial/leads']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase mr-2"></i>
                                <span class="font-bold">Mes leads</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="list-none p-3 m-0" *ngIf="isCustomer">
                <li>
                    <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                        <span class="font-bold">CLIENT</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 overflow-hidden">
                        <li>
                            <a [routerLink]="['customer/companies']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-building mr-2"></i>
                                <span class="font-bold">Mes entreprises</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['customer/sponsorships']" pRipple
                                class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-briefcase mr-2"></i>
                                <span class="font-bold">Mes parrainages</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="flex align-content-end flex-wrap h-full">
            <div class="mt-auto" *ngIf="isUser">
                <div class="flex justify-content-between align-items-center">
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-user" [label]="authenticatedUsername" [rounded]="true"
                            [text]="true" (onClick)="myProfile()" />
                    </div>
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-sign-out" label="Déconnexion" [rounded]="true" [text]="true"
                            severity="danger" (onClick)="logOut()" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-grow-1 px-3 py-2">
        <p-button icon="pi pi-bars" (onClick)="showSidebarEvent()" class="block xl:hidden" [text]="true" />
        <p-messages />
        <router-outlet></router-outlet>
    </div>
</div>

<p-sidebar #sidebarRef [(visible)]="sidebarVisible">
    <ng-template pTemplate="headless">
        <div class="flex flex-column h-full" style="background: #1D2128FF; color: #A7ADB7FF;">
            <div class="flex justify-content-between p-2">
                <div class="flex">
                    <p-button icon="pi pi-home" [rounded]="true" [text]="true" (onClick)="goToHomePage()" />
                </div>
                <div class="flex">
                    <p-button icon="pi pi-sun" [rounded]="true" [text]="true" (onClick)="changeTheme('saga-blue')"
                        [hidden]="theme === 'saga-blue'" />
                    <p-button icon="pi pi-moon" [rounded]="true" [text]="true" (onClick)="changeTheme('arya-blue')"
                        [hidden]="theme === 'arya-blue'" />
                </div>
            </div>

            <div class="overflow-y-auto mt-5">
                <ul class="list-none p-3 m-0" *ngIf="isSuperAdmin">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span class="font-bold">SUPER ADMIN</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['super-admin/companies']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-building mr-2"></i>
                                    <span class="font-bold">Entreprises</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="list-none p-3 m-0" *ngIf="isAdmin">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span class="font-bold">ADMIN. ENTREPRISE</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['pro/admin/company-details']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-cog mr-2"></i>
                                    <span class="font-bold">Données entreprise</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                    leaveToClass="hidden" leaveActiveClass="slideup"
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-users mr-2"></i>
                                    <span class="font-bold">Ressources humaines</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul
                                    class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <a [routerLink]="['pro/admin/employees']" (click)="closeCallback($event)" pRipple
                                            class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-users mr-2"></i>
                                            <span class="font-bold">Collaborateurs</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['pro/admin/customers']" (click)="closeCallback($event)" pRipple
                                            class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-users mr-2"></i>
                                            <span class="font-bold">Portefeuille client</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                    leaveToClass="hidden" leaveActiveClass="slideup"
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span class="font-bold">Ressources d'affaires</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul
                                    class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <a [routerLink]="['pro/admin/sponsorships']" (click)="closeCallback($event)" pRipple
                                            class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-briefcase mr-2"></i>
                                            <span class="font-bold">Parrainages entreprise</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['pro/admin/leads']" (click)="closeCallback($event)" pRipple
                                            class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-briefcase mr-2"></i>
                                            <span class="font-bold">Leads entreprise</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                                    leaveToClass="hidden" leaveActiveClass="slideup"
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-wallet mr-2"></i>
                                    <span class="font-bold">Rémunérations</span>
                                    <i class="pi pi-chevron-down ml-auto"></i>
                                </a>
                                <ul
                                    class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <a [routerLink]="['pro/admin/pending-payments']" (click)="closeCallback($event)" pRipple
                                            class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-wallet mr-2"></i>
                                            <span class="font-bold">En attente de paiement</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['pro/admin/payments-history']" (click)="closeCallback($event)" pRipple
                                            class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                            <i class="pi pi-wallet mr-2"></i>
                                            <span class="font-bold">Historique des paiements</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="list-none p-3 m-0" *ngIf="isCommercial">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span class="font-bold">COMMERCIAL</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['pro/commercial/customers']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-users mr-2"></i>
                                    <span class="font-bold">Mes clients</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/commercial/sponsorships']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span class="font-bold">Mes parrainages</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/commercial/leads']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span class="font-bold">Mes leads</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="list-none p-3 m-0" *ngIf="isCustomer">
                    <li>
                        <div pRipple pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer p-ripple">
                            <span class="font-bold">CLIENT</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <a [routerLink]="['customer/companies']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-building mr-2"></i>
                                    <span class="font-bold">Mes entreprises</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['customer/sponsorships']" (click)="closeCallback($event)" pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                    <i class="pi pi-briefcase mr-2"></i>
                                    <span class="font-bold">Mes parrainages</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="mt-auto" *ngIf="isUser">
                <div class="flex justify-content-between align-items-center">
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-user" [label]="authenticatedUsername" [rounded]="true"
                            [text]="true" (onClick)="myProfile();closeCallback($event)" />
                    </div>
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-sign-out" label="Déconnexion" [rounded]="true" [text]="true"
                            severity="danger" (onClick)="logOut();closeCallback($event)" />
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-sidebar>