<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="p-2 surface-card" *ngIf="!employeeDetailsIsSet() || getLoading()">
            <div class="flex mb-3">
                <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                <div>
                    <p-skeleton width="10rem" styleClass="mb-2" />
                    <p-skeleton width="5rem" styleClass="mb-2" />
                    <p-skeleton height=".5rem" />
                </div>
            </div>
            <p-skeleton width="100%" height="150px" />
            <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
            </div>
        </div>

        <div *ngIf="employeeDetailsIsSet()">
            <div class="flex p-2 mb-8">
                <div class="flex">
                    <div class="flex flex-column">
                        <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                            <img src="assets/images/web/user_details.png" class="w-full" alt="User picture" />
                        </div>
                        <div class="flex justify-content-center font-bold">
                            {{ employeeDetails.firstName }} {{ employeeDetails.lastName }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    COORDONNÉES
                </div>
                <div class="flex">
                    {{ employeeDetails.phone }}
                </div>
                <div class="flex">
                    {{ employeeDetails.email }}
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5">
                <div class="flex font-bold mb-2">
                    <span class="flex align-items-center mr-1">ROLES</span>
                    <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="updateRoles()" [hidden]="hideActionRoles" [disabled]="getLoading()" />
                    <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="revertRoles()" [hidden]="hideActionRoles" />
                </div>
                <div class="flex flex-column gap-2">
                    <div class="flex gap-3">
                        <p-checkbox [(ngModel)]="isCommercial" [disabled]="disableIsCommercialCheckbox()"
                            (onChange)="onChangeCheckbox()" [binary]="true" inputId="binary" />
                        <span>
                            <i class="pi pi-briefcase"></i>
                        </span>
                        <span>Commercial</span>
                    </div>
                    <div class="flex gap-3">
                        <p-checkbox [(ngModel)]="isAdmin" [disabled]="disableIsAdminCheckbox()"
                            (onChange)="onChangeCheckbox()" [binary]="true" inputId="binary" />
                        <span>
                            <i class="pi pi-crown"></i>
                        </span>
                        <span>Administrateur</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>