<div class="block">
    <div>
        <div class="text-lg font-bold text-900 mb-3 p-2">COLLABORATEURS</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="employees.length === 0">
            <div class="text-xl font-medium text-900">Aucun employé trouvé...</div>
            <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewEmployeeDialog()" [text]="true" />
        </div>

        <p-table #dt1 [value]="employees" selectionMode="single" (onRowSelect)="onRowSelectEmployee($event)"
            sortField="lastName" [sortOrder]="1" [globalFilterFields]="['lastName', 'firstName', 'email', 'phone']"
            [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true"
            [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="employees.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewEmployeeDialog()" [text]="true" />
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                    <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                    <th>Role(s)</th>
                    <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                    <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-employee>
                <tr [pSelectableRow]="employee">
                    <td>
                        <span class="md:hidden font-medium">Nom:&nbsp;</span>
                        <span>{{ employee.lastName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                        <span>{{ employee.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Rôles:&nbsp;</span>
                        <div class="flex flex-row flex-wrap gap-4">
                            <div class="flex" *ngFor="let role of employee.roles; trackBy:identifyRole">
                                <i [class]="role.primengIco" [pTooltip]="role.label" tooltipPosition="top"></i>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Email:&nbsp;</span>
                        <span>{{ employee.email }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                        <span>{{ employee.phone }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Ajouter un employé" [(visible)]="newEmployeeDialogIsVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">

    <p-messages key="message-key-dialog-new-employee" />

    <form [formGroup]="newEmployeeForm" (ngSubmit)="onSubmitNewEmployee()">
        <ul class="list-none p-0 m-0 mb-3">
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Email" pInputText formControlName="email"
                            aria-describedby="email-edit" (focusout)="focusOutEmployeeEmail()">
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!newEmployeeForm.controls.email.errors?.['pattern']">
                            Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                        </small>
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!badRequestEmployeeEmail.length">
                            {{ badRequestEmployeeEmail }}
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Nom" pInputText formControlName="lastName"
                            aria-describedby="lastName-edit">
                        <small class="text-red-600" id="lastName-edit"
                            [hidden]="!newEmployeeForm.controls.lastName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Prénom</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Prénom" pInputText formControlName="firstName"
                            aria-describedby="firstName-edit">
                        <small class="text-red-600" id="firstName-edit"
                            [hidden]="!newEmployeeForm.controls.firstName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Téléphone" pInputText formControlName="phone"
                            aria-describedby="phone-edit">
                        <small class="text-red-600" id="phone-edit"
                            [hidden]="!newEmployeeForm.controls.phone.errors?.['pattern']">
                            Règles: 10 chiffres.
                        </small>
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!badRequestEmployeePhone.length">
                            {{ badRequestEmployeePhone }}
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Rôle(s)</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <div class="field-checkbox">
                            <p-checkbox formControlName="isCommercial" [binary]="true" inputId="commercial-checkbox"
                                label="Commercial" />
                        </div>

                        <div class="field-checkbox">
                            <p-checkbox formControlName="isAdmin" [binary]="true" inputId="admin-checkbox"
                                label="Administrateur" />
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="flex flex-row gap-3">
            <div class="flex">
                <p-checkbox formControlName="legalNoticesAccepted" [binary]="true" />
            </div>
            <div class="flex">
                <p-scrollPanel [style]="{ height: '100px' }">
                    « Les informations recueillies par <span class="font-medium font-italic">LINKUPP</span> font l'objet
                    d'un
                    traitement informatique ayant pour finalité la gestion de notre fichier clients / prospects,
                    <span class="font-medium font-italic">LINKUPP</span> ayant un intérêt légitime à développer et
                    entretenir une
                    relation commerciale avec ses clients / prospects. Nous vous assurons qu'aucune donnée
                    ne sera communiquée, cédée ni revendue à des tiers. Les données seront conservées pour
                    une durée maximale de 3 ans après la fin de la relation commerciale, ou après votre
                    dernière demande. Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
                    modifiée et au Règlement Européen 2016/679 du 27 avril 2016 relatif à la protection des
                    personnes physiques à l'égard du traitement des données à caractère personnel, vous
                    disposez d'un droit d'accès et de rectification aux informations qui vous concernent ainsi que
                    d'un droit d'opposition, du droit à la limitation du traitement et à l'effacement dans le cadre
                    de la réglementation en vigueur. Vous pouvez exercer ces droits, il vous suffit d'envoyer un
                    email à cette adresse : <span class="font-medium font-italic">contact&#64;linkupp.fr</span>. Si vous
                    estimez, après nous avoir contactés, que vos
                    droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation
                    à la CNIL. »
                </p-scrollPanel>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" severity="danger" (onClick)="closeNewEmployeeDialog()" />
            <p-button label="Enregistrer" icon="pi pi-save" [loading]="getLoading()" type="submit"
                [disabled]="!newEmployeeForm.valid || !atLeastOneRole() || getLoading()" />
        </div>
    </form>
</p-dialog>