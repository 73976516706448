import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from '../../../../../services/admin.service';
import { MessageService } from 'primeng/api';
import { LeadsComponent as ReusableLeads } from '../../../../../reusable-components/leads/leads.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../../services/loading.service';
import { CheckCustomerRequest } from '../../../../../payloads/requests/checkCustomerRequest';
import { NewLeadRequest } from '../../../../../payloads/requests/newLeadRequest';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-leads-admin',
  standalone: true,
  imports: [
    ReusableLeads,
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    ScrollPanelModule,
    CheckboxModule,
    MessagesModule,
    DropdownModule
  ],
  templateUrl: './leads.component.html',
  styleUrl: './leads.component.sass'
})
export class LeadsComponent implements OnInit, OnDestroy {

  leads: any[] = [];

  commercials: any[] = [];

  newLeadDialogIsVisible: boolean = false;

  badRequestCustomerEmail: string = "";
  badRequestCustomerPhone: string = "";

  newLeadForm = this.formBuilder.group({
    commercialId: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    lastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    source: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,30}$')])],
    city: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,30}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private adminService: AdminService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.adminService.getAllLeads()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leads = response;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  showNewLeadDialog() {
    this.newLeadForm.reset();
    this.newLeadDialogIsVisible = true;

    this.adminService.getAllCommercials()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.commercials = response;
        }, error: error => {
          this.messageService.clear();
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-lead' });
        }
      });
  }
  
  focusOutCustomerEmail() {
    this.badRequestCustomerEmail = "";
    const emailControl = this.newLeadForm.get('email')!;
    if (emailControl.value && emailControl.valid) {
      const checkCustomerRequest: CheckCustomerRequest = new CheckCustomerRequest(null, emailControl.value!.toLowerCase());
      this.adminService.checkCustomer(checkCustomerRequest, false)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            if (response) {
              this.newLeadForm.patchValue({
                lastName: response.lastName,
                firstName: response.firstName,
                phone: response.phone
              });

              this.messageService.add({ severity: 'info', detail: 'Utilisateur trouvé. Si la correspondance n\'est pas bonne, vérifiez l\'email.' });
            }
          }, error: error => {
            this.newLeadForm.get('email')?.setValue('');
            this.newLeadForm.get('legalNoticesAccepted')?.setValue(false);

            this.messageService.clear();
            error.status === 400 && error.field === "customerEmail" ? this.badRequestCustomerEmail = error.description : this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-lead' });
          }
        })
    }
  }

  onSubmitNewLead() {
    this.badRequestCustomerPhone = "";
    const request: NewLeadRequest = new NewLeadRequest(
      +this.newLeadForm.get('commercialId')?.value!,
      this.newLeadForm.get('firstName')?.value!,
      this.newLeadForm.get('lastName')?.value!,
      this.newLeadForm.get('email')?.value!,
      this.newLeadForm.get('phone')?.value!,
      this.newLeadForm.get('source')?.value!,
      this.newLeadForm.get('city')?.value!);
    this.adminService.createLead(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.router.navigate([`portal/pro/admin/lead-details/${response.reference}`]);
        }, error: error => {
          this.messageService.clear();
          error.status === 400 && error.field === "userPhone" ? this.badRequestCustomerPhone = error.description : this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-lead' });
        }
      });
  }

  closeNewLeadDialog() {
    this.newLeadDialogIsVisible = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
