import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { ActivatedRoute } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { SkeletonModule } from 'primeng/skeleton';
import { CommonModule } from '@angular/common';
import { LoadingService } from '../../../../services/loading.service';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AvatarModule,
    SkeletonModule,
    InputTextModule,
    InputSwitchModule,
    ButtonModule
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.sass'
})
export class UserDetailsComponent implements OnInit, OnDestroy {

  userDetails: any = {};

  initialIsEnabled: boolean = false;
  initialIsAccountNonLocked: boolean = false;

  isEnabled: boolean = false;
  isAccountNonLocked: boolean = false;

  private _destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private superAdminService: SuperAdminService,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.superAdminService.getUserDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.userDetails = response;
          this.setSwitch(response.enabled, response.accountNonLocked);
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  setSwitch(isEnabled: boolean, isAccountNonLocked: boolean) {
    this.initialIsEnabled = false;
    this.initialIsAccountNonLocked = false;
    this.isEnabled = false;
    this.isAccountNonLocked = false;

    if (isEnabled) {
      this.initialIsEnabled = true;
      this.isEnabled = true;
    }
    if (isAccountNonLocked) {
      this.initialIsAccountNonLocked = true;
      this.isAccountNonLocked = true;
    }
  }

  accountSettingsChanged(): boolean {
    return this.isEnabled != this.initialIsEnabled || this.isAccountNonLocked != this.initialIsAccountNonLocked ? true : false;
  }

  onSubmitUpdateAccountSettings() {
    this.superAdminService.updateAccountSettings(this.activatedRoute.snapshot.paramMap.get('reference')!, this.isEnabled, this.isAccountNonLocked)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.userDetails = response;
        this.setSwitch(response.enabled, response.accountNonLocked);
        this.messageService.add({ severity: 'success', detail: 'Profil mis à jour', life: 5000 });
      }
    })
  }

  userDetailsIsSet(): boolean {
    if (!Object.keys(this.userDetails).length) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
