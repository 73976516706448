import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SponsorshipsComponent as ReusableSponsorships } from '../../../../reusable-components/sponsorships/sponsorships.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../services/loading.service';
import { CheckCustomerRequest } from '../../../../payloads/requests/checkCustomerRequest';
import { NewSponsorshipRequest } from '../../../../payloads/requests/newSponsorshipRequest';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-sponsorships-customer',
  standalone: true,
  imports: [
    ReusableSponsorships,
    ReactiveFormsModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    ScrollPanelModule,
    DropdownModule,
    CheckboxModule,
    MessagesModule,
    InputTextModule,
    InputTextareaModule
  ],
  templateUrl: './sponsorships.component.html',
  styleUrl: './sponsorships.component.sass'
})
export class SponsorshipsComponent implements OnInit, OnDestroy {

  sponsorships: any[] = [];

  newSponsorshipDialogIsVisible: boolean = false;
  customerCompanies: any[] = [];
  commercialFullName: string = "";

  badRequestCustomerEmail: string = "";
  badRequestCustomerPhone: string = "";

  newSponsorshipForm = this.formBuilder.group({
    company: ['', Validators.required],
    godChildEmail: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    godChildLastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    godChildFirstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    godChildPhone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    description: [null, Validators.pattern('^.{10,255}$')],
    city: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,30}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.customerService.getAllSponsorships()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorships = response;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  showNewSponsorshipDialog() {
    this.newSponsorshipForm.reset();
    this.commercialFullName = "";
    this.newSponsorshipDialogIsVisible = true;

    this.customerService.getAllCompanies(true)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.customerCompanies = response;
        }, error: error => {
          this.messageService.clear(); // Prevent x2 msg, clear msg from interceptor to display msg in dialog
          this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-sponsorship' });
        }
      });
  }

  focusOutGodChildEmail() {
    this.badRequestCustomerEmail = "";
    const godChildEmailControl = this.newSponsorshipForm.get('godChildEmail')!;
    if (godChildEmailControl.value && godChildEmailControl.valid) {
      const company: any = this.newSponsorshipForm.get('company')?.value;
      const checkCustomerRequest: CheckCustomerRequest = new CheckCustomerRequest(+company.company.id, godChildEmailControl.value!.toLowerCase());
      this.customerService.checkCustomer(checkCustomerRequest, true)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            if (response) {
              this.newSponsorshipForm.patchValue({
                godChildLastName: response.lastName,
                godChildFirstName: response.firstName,
                godChildPhone: response.phone
              });

              this.messageService.add({ severity: 'info', detail: "Utilisateur trouvé. Si la correspondance n'est pas bonne, vérifiez l'email.", key: 'message-key-dialog-new-sponsorship' });
            }
          }, error: error => {
            this.newSponsorshipForm.get('godChildEmail')?.setValue('');
            this.newSponsorshipForm.get('legalNoticesAccepted')?.setValue(false);

            this.messageService.clear();
            error.status === 400 && error.field === "customerEmail" ? this.badRequestCustomerEmail = error.description : this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-sponsorship', life: 5000 });
          }
        });
    }
  }

  onChangeCompany(event: any) {
    this.commercialFullName = event.value.commercial.firstName + ' ' + event.value.commercial.lastName;
  }

  onSubmitNewSponsorship() {
    const company: any = this.newSponsorshipForm.get('company')?.value;
    const request: NewSponsorshipRequest = new NewSponsorshipRequest(
      +company.company.id,
      this.newSponsorshipForm.get('godChildFirstName')?.value!,
      this.newSponsorshipForm.get('godChildLastName')?.value!,
      this.newSponsorshipForm.get('godChildEmail')?.value!,
      this.newSponsorshipForm.get('godChildPhone')?.value!,
      this.newSponsorshipForm.get('description')?.value!,
      this.newSponsorshipForm.get('city')?.value!);
    this.customerService.createSponsorship(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.router.navigate([`portal/customer/sponsorship-details/${response.reference}`]);
        }, error: error => {
          this.messageService.clear();
          error.status === 400 && error.field === "userPhone" ? this.badRequestCustomerPhone = error.description : this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-new-sponsorship', life: 5000 });
        }
      });
  }

  closeNewSponsorshipDialog() {
    this.newSponsorshipDialogIsVisible = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
