<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="md:flex justify-content-between">
            <div class="p-2 mb-2 md:mb-5 text-sm text-500 font-medium">
                Référence: {{ sponsorshipDetails.reference}}
            </div>
            <div class="p-2 mb-5">
                <p-tag [style]="{ 'background-color': getTagBackgroundColor(sponsorshipDetails.status?.name), cursor: getTagCursor() }" [value]="sponsorshipDetails.status?.label" [rounded]="true" (click)="onChangeStatus()" /> 
            </div>
        </div>

        <div class="flex justify-content-between p-2 mb-8">
            <div class="flex">
                <div class="flex flex-column">
                    <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                        <img src="assets/images/web/no_pic.png" class="w-full"
                            *ngIf="sponsorshipDetails.company?.logoFilePath === null" [alt]="sponsorshipDetails.company?.name" />
                        <img [src]="getLogoFullUrl(sponsorshipDetails.company?.logoFilePath)" class="w-full border-round"
                            *ngIf="sponsorshipDetails.company?.logoFilePath != null" [alt]="sponsorshipDetails.company?.name" />
                    </div>
                    <div class="flex justify-content-center font-bold">
                        {{ sponsorshipDetails.company?.name | uppercase}}
                    </div>
                </div>
            </div>
            <div class="flex" *ngIf="sponsorshipDetails.amount > 0">
                <div class="flex font-bold mb-2">
                    GAINS : {{ sponsorshipDetails.amount }},00 €
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5 gap-2">
            <div class="flex font-bold mb-2">
                PARRAIN
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-user"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godFather?.firstName }} {{ sponsorshipDetails.godFather?.lastName }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-phone"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godFather?.phone }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-at"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godFather?.email }}
                </span>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5 gap-2">
            <div class="flex font-bold mb-2">
                FILLEUL
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-user"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godChild?.firstName }} {{ sponsorshipDetails.godChild?.lastName }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-phone"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godChild?.phone }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-at"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.godChild?.email }}
                </span>
            </div>
            <div class="flex gap-2">
                <span>
                    <i class="pi pi-map-marker"></i>
                </span>
                <span>
                    {{ sponsorshipDetails.city }}
                </span>
            </div>
        </div>

        <div class="md:flex justify-content-between mb-5">
            <div class="flex flex-column p-2 md:mb-0 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    COMMERCIAL
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-user"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.commercial?.firstName }} {{ sponsorshipDetails.commercial?.lastName }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.commercial?.phone }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.commercial?.email }}
                    </span>
                </div>
            </div>
            <div class="flex flex-column p-2 md:p-5 gap-2">
                <div class="flex md:justify-content-end font-bold mb-2">
                    SAV
                </div>
                <div class="flex md:justify-content-end gap-2">
                    <span class="block md:hidden">
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.company?.phone }}
                    </span>
                    <span class="hidden md:block">
                        <i class="pi pi-phone"></i>
                    </span>
                </div>
                <div class="flex md:justify-content-end gap-2">
                    <span class="block md:hidden">
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.company?.email }}
                    </span>
                    <span class="hidden md:block">
                        <i class="pi pi-at"></i>
                    </span>
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5" *ngIf="sponsorshipDetails.description">
            <div class="flex font-bold mb-2">
                DESCRIPTION DU BESOIN
            </div>
            <div class="flex">
                {{ sponsorshipDetails.description }}
            </div>
        </div>

        <p-accordion [multiple]="true">
            <p-accordionTab header="Suivi d'activité" *ngIf="sponsorshipDetails.events?.length > 0">
                <div class="flex justify-content-center">
                    <div class="flex flex-column w-12 md:w-8">
                        <div class="flex flex-column p-2 w-12 mt-3" *ngIf="(activeUser === 'customer' || activeUser === 'commercial') && sponsorshipDetails.updatable">
                            <span class="p-float-label">
                                <textarea pInputTextarea [(ngModel)]="sponsorshipMessageEvent"
                                    maxlength="255" class="w-12"></textarea>
                                <label for="float-input">Note (255 caractères max).</label>
                            </span>
                            <p-button class="text-right"
                                icon="pi pi-save" [loading]="getLoading()" label="Publier"
                                [rounded]="true" [text]="true" (onClick)="onClickAddNote()"
                                [disabled]="sponsorshipMessageEvent.length === 0 || getLoading()" />
                        </div>

                        <div *ngFor="let event of sponsorshipDetails.events; trackBy:identifyEvent" class="p-2 w-12">
                            <p-fieldset [legend]="getEventFieldsetHeader(event)">
                                <p class="m-0">
                                    {{ event.eventMessage }}
                                </p>
                            </p-fieldset>
                        </div>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab header="Suivi paiement" *ngIf="sponsorshipDetails.bridgeTransactionDetails">
                <div class="flex flex-column p-2 mb-3 text-sm text-500 font-medium">
                    Bridge transaction ID: {{ sponsorshipDetails.bridgeTransactionDetails.id }}
                </div>

                <div class="flex flex-column p-2 mb-5 gap-2">
                    <div class="flex font-bold mb-2">
                        STATUT
                    </div>
                    <div class="flex">
                        <div class="flex gap-1">
                            <p-tag [style]="{ 'background-color': getTagBackgroundColor(sponsorshipDetails.bridgeTransactionDetails.status) }" [value]="sponsorshipDetails.bridgeTransactionDetails.status" [rounded]="true" [pTooltip]="setStatusTagTooltipDesc(sponsorshipDetails.bridgeTransactionDetails.status)" />
                            <p-tag severity="danger" [value]="sponsorshipDetails.bridgeTransactionDetails.status_reason" [rounded]="true" *ngIf="sponsorshipDetails.bridgeTransactionDetails.status_reason" [pTooltip]="setStatusReasonTagTooltipDesc(sponsorshipDetails.bridgeTransactionDetails.status_reason)" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-column p-2 mb-5 gap-2">
                    <div class="flex font-bold mb-2">
                        BENEFICIAIRE
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-user"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.beneficiary?.last_name }} {{ sponsorshipDetails.bridgeTransactionDetails.beneficiary?.first_name }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span pTooltip="Référence client">
                            <i class="pi pi-link"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.client_reference }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-credit-card"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.beneficiary?.iban }}
                        </span>
                    </div>
                </div>
                <div class="flex flex-column p-2 mb-5 gap-2">
                    <div class="flex font-bold mb-2">
                        DETAILS
                    </div>
                    <div class="flex gap-2">
                        <span pTooltip="Date d'exécution">
                            <i class="pi pi-calendar"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.execution_date | date: 'medium' }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span pTooltip="End to end ID">
                            <i class="pi pi-link"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.end_to_end_id }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-wallet"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.amount }},00€
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span pTooltip="Libellé">
                            <i class="pi pi-tag"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.bridgeTransactionDetails.label }}
                        </span>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
