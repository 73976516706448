<div class="block h-screen">
    <div class="flex h-full align-items-center justify-content-center surface-50">
        <div class="surface-100 border-round p-4 xl:w-10 xl:max-w-30rem lg:w-6 md:w-8 w-full mx-5">
            <div class="align-content-center text-center mb-6">
                <span class="font-medium">Réinitialisation du mot de passe</span>
            </div>
            <div>
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPassword()" [hidden]="hideResetForm">

                    <div class="flex flex-column gap-1 mb-3">
                        <label for="newPwd" class="text-sm font-medium">Nouveau mot de passe</label>
                        <p-password [toggleMask]="true" [strongRegex]="passwordRegex" formControlName="newPwd"
                            promptLabel="Nouveau mot de passe" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
                            <ng-template pTemplate="footer">
                                <p-divider></p-divider>
                                <p class="mt-2">Règles</p>
                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                    <li [ngClass]="pwdLowerCase() ? 'text-green-600' : 'text-red-600'">
                                        Au moins 1 minuscule</li>
                                    <li [ngClass]="pwdUpperCase() ? 'text-green-600' : 'text-red-600'">
                                        au moins 1 majuscule</li>
                                    <li [ngClass]="pwdDigit() ? 'text-green-600' : 'text-red-600'">
                                        Au
                                        moins 1 chiffre</li>
                                    <li [ngClass]="pwdSpecialChar() ? 'text-green-600' : 'text-red-600'">
                                        Au moins 1 caractère spécial: !&#64;#$%^&*()_+</li>
                                    <li [ngClass]="pwdLength() ? 'text-green-600' : 'text-red-600'">
                                        Entre 12 et 20 caractères</li>
                                </ul>
                            </ng-template>
                        </p-password>
                    </div>

                    <div class="flex flex-column gap-1">
                        <label for="confirmPwd" class="text-sm font-medium">Confirmer le mot de passe</label>
                        <p-password [toggleMask]="true" [feedback]="false" formControlName="confirmPwd"
                            promptLabel="Confirmer le mot de passe" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" />
                        <small class="pt-1 text-red-600" id="confirmPwd"
                            [hidden]="!resetPasswordForm.errors?.['passwordMismatch']">
                            Les mots de passe ne correspondent pas.
                        </small>
                    </div>

                    <div class="flex mt-4">
                        <p-button type="submit" label="Réinitialiser" icon="pi pi-send" class="w-full" iconPos="right"
                            [loading]="getLoading()" [style]="{ 'font-weight': '500', width: '100%' }" [disabled]="!resetPasswordForm.valid || getLoading()" />
                    </div>
                </form>

                <div [hidden]="!hideResetForm" class="text-center">
                    Le lien est invalide ou expiré. Merci de rejouer la procédure de réinitialisation du mot de passe <a class="text-blue-500 cursor-pointer" [routerLink]="['/forgot-password']">ici</a>.
                </div>
            </div>
        </div>
    </div>
</div>