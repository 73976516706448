<div class="mt-5 m-auto align-content-center p-4 surface-card shadow-2 border-round max-w-30rem"
    *ngIf="!myProfileIsSet() || getLoading()">
    <div class="p-2 surface-card">
        <div class="flex mb-3">
            <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
            <div>
                <p-skeleton width="10rem" styleClass="mb-2" />
                <p-skeleton width="5rem" styleClass="mb-2" />
                <p-skeleton height=".5rem" />
            </div>
        </div>
        <p-skeleton width="100%" height="150px" />
        <div class="flex justify-content-between mt-3">
            <p-skeleton width="4rem" height="2rem" />
            <p-skeleton width="4rem" height="2rem" />
        </div>
    </div>
</div>

<div *ngIf="myProfileIsSet()" class="mb-5">
    <div class="mt-5 m-auto align-content-center p-4 surface-card shadow-2 border-round max-w-30rem">
        <div class="flex justify-content-center my-5">
            <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle" />
        </div>
        <form [formGroup]="updateProfileForm" (ngSubmit)="onSubmitUpdateProfile()">
            <div class="flex flex-column gap-2">
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="username">Nom d'utilisateur</label>
                    <input pInputText id="username" aria-describedby="username-help" [disabled]="true"
                        [placeholder]="myProfile.username" />
                </div>
                <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                    <div class="flex flex-column gap-1 flex-grow-1">
                        <label class="text-xs font-medium" for="firstName">Prénom</label>
                        <input pInputText id="firstName" aria-describedby="firstName-help"
                            [placeholder]="myProfile.firstName" formControlName="firstName" />
                        <small class="pt-1 text-red-600"
                            [hidden]="!updateProfileForm.controls.firstName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                    <div class="flex flex-column gap-1 flex-grow-1">
                        <label class="text-xs font-medium" for="lastName">Nom</label>
                        <input pInputText id="lastName" aria-describedby="lastName-help"
                            [placeholder]="myProfile.lastName" formControlName="lastName" />
                        <small class="pt-1 text-red-600"
                            [hidden]="!updateProfileForm.controls.lastName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="email">Email</label>
                    <input pInputText id="email" aria-describedby="email-help" [placeholder]="myProfile.email"
                        formControlName="email" />
                    <small class="pt-1 text-red-600" [hidden]="!updateProfileForm.controls.email.errors?.['pattern']">
                        Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                    </small>
                    <small class="pt-1 text-red-600" [hidden]="!badRequestUserEmail.length">
                        {{ badRequestUserEmail }}
                    </small>
                </div>
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="phone">Téléphone</label>
                    <input pInputText id="phone" aria-describedby="phone-help" [placeholder]="myProfile.phone"
                        formControlName="phone" />
                    <small class="pt-1 text-red-600" [hidden]="!updateProfileForm.controls.phone.errors?.['pattern']">
                        Règles: 10 chiffres.
                    </small>
                    <small class="pt-1 text-red-600" [hidden]="!badRequestUserPhone.length">
                        {{ badRequestUserPhone }}
                    </small>
                </div>
                <div class="flex flex-column gap-1" *ngIf="isCustomer">
                    <label class="text-xs font-medium" for="iban">Iban</label>
                    <input pInputText id="iban" aria-describedby="iban-help" [placeholder]="myProfile.iban ? myProfile.iban : 'Saisir un IBAN'"
                        formControlName="iban" />
                    <small class="pt-1 text-red-600" [hidden]="!updateProfileForm.controls.iban.errors?.['pattern']">
                        Règles: 27 caractères au format IBAN français.
                    </small>
                    <small class="text-xs text-gray-600 mt-2">
                        <i class="pi pi-info-circle" style="font-size: 0.7rem"></i>
                        Votre IBAN est utilisé dans le cadre de la rémunération de vos récompenses de parrainage. Sans
                        cette information, aucun professionnel ne pourra faire de virement en votre faveur.
                    </small>
                </div>
                <div class="flex mt-3">
                    <p-button type="submit" label="Enregistrer" icon="pi pi-save" class="w-full"
                        [style]="{ 'font-weight': '500', width: '100%' }" [loading]="getLoading()"
                        [disabled]="!updateProfileForm.valid || getLoading()" />
                </div>
            </div>
        </form>
    </div>

    <div class="mt-5 m-auto align-content-center p-4 surface-card shadow-2 border-round max-w-30rem">
        <form [formGroup]="updatePasswordForm" (ngSubmit)="onSubmitUpdatePassword()">
            <div class="flex flex-column gap-2">
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="currentPwd">Mot de passe actuel</label>
                    <p-password [toggleMask]="true" [feedback]="false" formControlName="currentPwd"
                        promptLabel="Mot de passe actuel" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" />
                    <small class="pt-1 text-red-600" [hidden]="!badRequestCurrentPwd.length">
                        {{ badRequestCurrentPwd }}
                    </small>
                </div>
                <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                    <div class="flex flex-column gap-1 flex-grow-1">
                        <label class="text-xs font-medium" for="newPwd">Nouveau mot de passe</label>
                        <p-password [toggleMask]="true" [strongRegex]="passwordRegex" formControlName="newPwd"
                            promptLabel="Nouveau mot de passe" [style]="{'width':'100%'}"
                            [inputStyle]="{'width':'100%'}">
                            <ng-template pTemplate="footer">
                                <p-divider></p-divider>
                                <p class="mt-2">Règles</p>
                                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                    <li [ngClass]="pwdLowerCase() ? 'text-green-600' : 'text-red-600'">
                                        Au moins 1 minuscule</li>
                                    <li [ngClass]="pwdUpperCase() ? 'text-green-600' : 'text-red-600'">
                                        au moins 1 majuscule</li>
                                    <li [ngClass]="pwdDigit() ? 'text-green-600' : 'text-red-600'">
                                        Au
                                        moins 1 chiffre</li>
                                    <li [ngClass]="pwdSpecialChar() ? 'text-green-600' : 'text-red-600'">
                                        Au moins 1 caractère spécial: !&#64;#$%^&*()_+</li>
                                    <li [ngClass]="pwdLength() ? 'text-green-600' : 'text-red-600'">
                                        Entre 12 et 20 caractères</li>
                                </ul>
                            </ng-template>
                        </p-password>
                    </div>
                    <div class="flex flex-column gap-1 flex-grow-1">
                        <label class="text-xs font-medium" for="confirmPwd">Confirmer le mot de passe</label>
                        <p-password [toggleMask]="true" [feedback]="false" formControlName="confirmPwd"
                            promptLabel="Confirmer le mot de passe" [style]="{'width':'100%'}"
                            [inputStyle]="{'width':'100%'}" />
                        <small class="pt-1 text-red-600" id="confirmPwd"
                            *ngIf="updatePasswordForm.errors?.['passwordMismatch']">
                            Les mots de passe ne correspondent pas.
                        </small>
                    </div>
                </div>
                <div class="flex mt-3">
                    <p-button type="submit" label="Enregistrer" icon="pi pi-save" class="w-full"
                        [style]="{ 'font-weight': '500', width: '100%' }" [loading]="getLoading()"
                        [disabled]="!updatePasswordForm.valid || getLoading()" />
                </div>
            </div>
        </form>
    </div>
</div>