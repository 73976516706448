import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from '../../../../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../../reusable-components/sponsorship-details/sponsorship-details.component';

@Component({
  selector: 'app-sponsorship-details-admin',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};
  transactionDetails: any = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    const reference = this.route.snapshot.paramMap.get('reference')!;
    this.adminService.getSponsorshipDetails(reference)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.sponsorshipDetails = response;
        this.sponsorshipDetails.bridgeTransactionDetails = JSON.parse(this.sponsorshipDetails.bridgeTransactionDetails);
      }, error: error => {
        this.messageService.add({ severity: 'error', detail: error.description });
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
