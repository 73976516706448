<div class="block">
    <div>
        <div class="text-2xl font-medium text-900 mb-3 p-2">Historique des paiements</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="payments.length === 0">
            <div class="text-xl font-medium text-900">Aucun paiement trouvé...</div>
        </div>

        <p-table #dt1 [value]="payments" [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack"
            [breakpoint]="'767px'" sortField="createdAt" [sortOrder]="-1" selectionMode="single" (onRowSelect)="onRowSelectPayment($event)"
            [globalFilterFields]="['createdAt', 'technicalId', 'totalTransactions', 'totalAmount', 'status', 'statusReason']"
            [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="payments.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Date de création<p-sortIcon field="createdAt" /></th>
                    <th pSortableColumn="technicalId">Bridge paiement ID<p-sortIcon field="technicalId" /></th>
                    <th pSortableColumn="totalTransactions">Transactions<p-sortIcon field="totalTransactions" /></th>
                    <th pSortableColumn="totalAmount">Montant total<p-sortIcon field="totalAmount" /></th>
                    <th pSortableColumn="status">Statut<p-sortIcon field="status" /></th>
                    <th>Facture</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-payment>
                <tr [pSelectableRow]="payment">
                    <td>
                        <span class="md:hidden font-medium">Date:&nbsp;</span>
                        <span>{{ payment.createdAt | date: 'medium' }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Bridge paiement ID:&nbsp;</span>
                        <span>{{ payment.technicalId }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Transactions:&nbsp;</span>
                        <span>{{ payment.totalTransactions }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Montant total:&nbsp;</span>
                        <span>{{ payment.totalAmount }},00€</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Statut:&nbsp;</span>
                        <span class="flex gap-1">
                            <p-tag [style]="{ 'background-color': getTagBackgroundColor(payment.status) }" [value]="payment.status" [rounded]="true" *ngIf="payment.status" />
                            <p-tag severity="danger" [value]="payment.statusReason" [rounded]="true" *ngIf="payment.statusReason"/>
                        </span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Facture:&nbsp;</span>
                        <p-button icon="pi pi-file-pdf" [rounded]="true" [text]="true" (onClick)="onClickDownloadInvoice(payment.technicalId)" [disabled]="getLoading()" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
        
    </div>
</div>