import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NewCompanyRequest } from '../payloads/requests/newCompanyRequest';
import { Observable } from 'rxjs';
import { UpdateCompanySubscriptionPlanRequest } from '../payloads/requests/updateCompanySubscriptionPlanRequest';

const API = `${environment.rootApiUrl}/api/super-admin`;

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  constructor(
    private http: HttpClient
  ) { }

  createCompany(request: NewCompanyRequest): Observable<any> {
    return this.http.post(`${API}/companies`, request);
  }

  getAllCompanies(): Observable<any> {
    return this.http.get(`${API}/companies`);
  }

  getCompanyDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/companies/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  updateSubscriptionPlan(request: UpdateCompanySubscriptionPlanRequest): Observable<any> {
    return this.http.post(`${API}/companies/update-plan`, request);
  }

  getAllPlans(): Observable<any> {
    return this.http.get(`${API}/plans`);
  }

  getAllUsers(): Observable<any> {
    return this.http.get(`${API}/users`);
  }

  getUserDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/users/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  updateAccountSettings(reference: string, isEnabled: boolean, isAccountNonLocked: boolean): Observable<any> {
    return this.http.put(`${API}/users/update-account-settings`, {},
      {
        params: {
          reference: reference,
          isEnabled: isEnabled,
          isAccountNonLocked: isAccountNonLocked
        }
      }
    );
  }

  getMetrics(): Observable<any> {
    return this.http.get(`${API}/metrics`);
  }

}
