<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="p-2 surface-card" *ngIf="!companyDetailsIsSet() || getLoading()">
            <div class="flex mb-3">
                <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                <div>
                    <p-skeleton width="10rem" styleClass="mb-2" />
                    <p-skeleton width="5rem" styleClass="mb-2" />
                    <p-skeleton height=".5rem" />
                </div>
            </div>
            <p-skeleton width="100%" height="150px" />
            <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
            </div>
        </div>

        <div *ngIf="companyDetailsIsSet()">
            <form [formGroup]="updateCompanyDetailsForm">
                <div class="md:flex justify-content-between">
                    <div class="p-2 mb-2 md:mb-5 text-sm text-500 font-medium">
                        Référence: {{ companyDetails.reference}}
                    </div>
                    <div class="p-2 mb-5">
                        <p-tag class="cursor-pointer"
                            severity="success"
                            icon="pi pi-sparkles"
                            [value]="companyDetails.subscription?.label | uppercase" [rounded]="true" (click)="clickShowSubscriptionDetails()" />
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-8">
                    <div class="flex font-bold mb-2">
                        LOGO
                    </div>
                    <div class="flex">
                        <div class="flex flex-column">
                            <div class="relative mx-auto">
                                <div class="flex">
                                    <p-fileUpload #fileUpload accept="image/png, image/jpeg" maxFileSize="150000"
                                        previewWidth="100" showCancelButton="false" customUpload="true"
                                        (uploadHandler)="uploadFile($event)">
                                        <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback"
                                            let-uploadCallback="uploadCallback">
                                            <p-button (onClick)="choose($event, chooseCallback)" icon="pi pi-images"
                                                [rounded]="true" [outlined]="true" />
                                            <p-button (onClick)="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload"
                                                [loading]="getLoading()" [rounded]="true" [outlined]="true"
                                                severity="success"
                                                [disabled]="!files || files.length === 0 || getLoading()" />
                                        </ng-template>
                                    </p-fileUpload>
                                    <div *ngIf="companyDetails.logoFilePath != null" class="ml-3">
                                        <p-image [src]="getLogoFullUrl(companyDetails.logoFilePath)" alt="Image"
                                            width="100" [preview]="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        <span class="flex align-items-center mr-1">NOM</span>
                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('name')" [hidden]="hideName" />
                        <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="onSubmitUpdateCompany()" [hidden]="!hideName" [disabled]="getLoading()" />
                        <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="onClickEnableFieldInput('name')" [hidden]="!hideName" />
                    </div>
                    <div class="flex">
                        <span [hidden]="hideName">{{ companyDetails.name }}</span>

                        <div [hidden]="!hideName">
                            <div class="flex flex-column gap-2">
                                <input type="text" [placeholder]="companyDetails.name" pInputText formControlName="name"
                                    aria-describedby="name-edit">
                                <small class="text-red-600" id="name-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.name.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                                </small>
                                <small class="text-red-600" id="name-edit"
                                    [hidden]="!badRequestCompanyName.length">
                                    {{ badRequestCompanyName }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        SIREN
                    </div>
                    <div class="flex">
                        {{ companyDetails.siren }}
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        <span class="flex align-items-center mr-1">COORDONNÉES</span>
                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('contactDetails')" [hidden]="hideContactDetails" />
                        <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="onSubmitUpdateCompany()" [hidden]="!hideContactDetails" [disabled]="getLoading()" />
                        <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="onClickEnableFieldInput('contactDetails')" [hidden]="!hideContactDetails" />
                    </div>
                    <div class="flex mb-2">
                        <span [hidden]="hideContactDetails">{{ companyDetails.email }}</span>

                        <div [hidden]="!hideContactDetails">
                            <div class="flex flex-column gap-2">
                                <input type="text" [placeholder]="companyDetails.email" pInputText
                                    formControlName="email" aria-describedby="email-edit">
                                <small class="text-red-600" id="email-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.email.errors?.['pattern']">
                                    Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                                </small>
                                <small class="text-red-600" id="email-edit"
                                    [hidden]="!badRequestCompanyEmail.length">
                                    {{ badRequestCompanyEmail }}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideContactDetails">{{ companyDetails.phone }}</span>

                        <div [hidden]="!hideContactDetails">
                            <div class="flex flex-column gap-2">
                                <input type="text" [placeholder]="companyDetails.phone" pInputText
                                    formControlName="phone" aria-describedby="phone-edit">
                                <small class="text-red-600" id="phone-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.phone.errors?.['pattern']">
                                    Règles: 10 chiffres.
                                </small>
                                <small class="text-red-600" id="phone-edit"
                                    [hidden]="!badRequestCompanyPhone.length">
                                    {{ badRequestCompanyPhone }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        <span class="flex align-items-center mr-1">ADRESSE</span>
                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('addressDetails')" [hidden]="hideAddressDetails" />
                        <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="onSubmitUpdateCompany()" [hidden]="!hideAddressDetails" [disabled]="getLoading()" />
                        <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="onClickEnableFieldInput('addressDetails')" [hidden]="!hideAddressDetails" />
                    </div>
                    <div class="flex mb-2">
                        <span [hidden]="hideAddressDetails">{{ companyDetails.address }}</span>

                        <div [hidden]="!hideAddressDetails">
                            <div class="flex flex-column gap-2">
                                <input type="text"
                                    [placeholder]="companyDetails.address ? companyDetails.address : 'Adresse'"
                                    pInputText formControlName="address" aria-describedby="address-edit">
                                <small class="text-red-600" id="address-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.address.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex mb-2">
                        <span [hidden]="hideAddressDetails">{{ companyDetails.postalCode }}</span>

                        <div [hidden]="!hideAddressDetails">
                            <div class="flex flex-column gap-2">
                                <input type="text"
                                    [placeholder]="companyDetails.postalCode ? companyDetails.postalCode : 'Code postal'"
                                    pInputText formControlName="postalCode" aria-describedby="postalCode-edit">
                                <small class="text-red-600" id="postalCode-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.postalCode.errors?.['pattern']">
                                    Règles: 5 chiffres.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <span [hidden]="hideAddressDetails">{{ companyDetails.city }}</span>

                        <div [hidden]="!hideAddressDetails">
                            <div class="flex flex-column gap-2">
                                <input type="text" [placeholder]="companyDetails.city ? companyDetails.city : 'Ville'"
                                    pInputText formControlName="city" aria-describedby="city-edit">
                                <small class="text-red-600" id="city-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.city.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 30 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        <span class="flex align-items-center mr-1">MA BANQUE</span>
                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('bank')" [hidden]="hideBank" />
                        <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="onSubmitUpdateCompany()" [hidden]="!hideBank" [disabled]="getLoading()" />
                        <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="onClickEnableFieldInput('bank')" [hidden]="!hideBank" />
                    </div>
                    <div class="flex">
                        <div [hidden]="hideBank">
                            <div class="flex align-items-center gap-1 mb-1">
                                <span>{{ companyDetails.bridgeBank?.name }}</span>
                                <p-image [src]="companyDetails.bridgeBank?.logoUrl"
                                    [alt]="companyDetails.bridgeBank?.name" [preview]="true" width="50" />
                            </div>
                            <p-tag severity="warning" [value]="companyDetails.bridgeBank?.capability"
                                [pTooltip]="setCapabilityTagTooltipDesc(companyDetails.bridgeBank?.capability)"
                                *ngIf="companyDetails.bridgeBank?.capability" />
                        </div>

                        <div [hidden]="!hideBank">
                            <p-listbox [options]="bridgeBanks" formControlName="bank" optionLabel="name"
                                [filter]="true">
                                <ng-template let-bank pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <img [src]="bank.logo_url" style="width: 20px" />
                                        <div>{{ bank.name }}</div>
                                    </div>
                                </ng-template>
                            </p-listbox>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        <span class="flex align-items-center mr-1">MONTANT MINIMUM DE RÉCOMPENSE</span>
                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('minimumRewardAmount')" [hidden]="hideMinimumRewardAmount" />
                        <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="onSubmitUpdateCompany()" [hidden]="!hideMinimumRewardAmount" [disabled]="getLoading()" />
                        <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="onClickEnableFieldInput('minimumRewardAmount')" [hidden]="!hideMinimumRewardAmount" />
                    </div>
                    <div class="flex">
                        <span [hidden]="hideMinimumRewardAmount">{{ companyDetails.minimumRewardAmount }} €</span>

                        <div [hidden]="!hideMinimumRewardAmount">
                            <p-inputNumber formControlName="minimumRewardAmount"
                                    [placeholder]="companyDetails.minimumRewardAmount" mode="currency" currency="EUR"
                                    locale="fr-FR" aria-describedby="minimumRewardAmount-edit" />
                        </div>
                    </div>
                </div>

                <div class="flex flex-column p-2 mb-5">
                    <div class="flex font-bold mb-2">
                        <span class="flex align-items-center mr-1">DESCRIPTION</span>
                        <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('description')" [hidden]="hideDescription" />
                        <p-button icon="pi pi-save" [rounded]="true" [text]="true" severity="success" (onClick)="onSubmitUpdateCompany()" [hidden]="!hideDescription" [disabled]="getLoading()" />
                        <p-button icon="pi pi-undo" [rounded]="true" [text]="true" severity="danger" (onClick)="onClickEnableFieldInput('description')" [hidden]="!hideDescription" />
                    </div>
                    <div class="flex">
                        <span [hidden]="hideDescription">{{ companyDetails.description }}</span>

                        <div [hidden]="!hideDescription">
                            <div class="flex flex-column gap-2">
                                <textarea [placeholder]="companyDetails.description" pInputTextarea
                                    formControlName="description" aria-describedby="description-edit" maxlength="255"
                                    rows="5" cols="30"></textarea>
                                <small class="text-red-600" id="description-edit"
                                    [hidden]="!updateCompanyDetailsForm.controls.description.errors?.['pattern']">
                                    Règles: entre 30 et 255 caractères.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="md:flex justify-content-between">
                    <div class="flex flex-column p-2 md:mb-0 mb-5">
                        <div class="flex font-bold mb-2">
                            PARRAINAGES LINKUPP
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                Tous:&nbsp;
                            </span>
                            {{ companyDetails.sponsorshipsNumber }}
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                En cours:&nbsp;
                            </span>
                            {{ companyDetails.runningSponsorshipsNumber }}
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                Validé(s):&nbsp;
                            </span>
                            {{ companyDetails.completedSponsorshipsNumber }}
                        </div>
                        <div class="flex">
                            <span class="font-medium">
                                Annulé(s):&nbsp;
                            </span>
                            {{ companyDetails.canceledSponsorshipsNumber }}
                        </div>
                    </div>
                    <div class="flex flex-column p-2 md:p-5">
                        <div class="flex font-bold mb-2">
                            LEADS LINKUPP
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                Tous:&nbsp;
                            </span>
                            {{ companyDetails.leadsNumber }}
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                En cours:&nbsp;
                            </span>
                            {{ companyDetails.runningLeadsNumber }}
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                Validé(s):&nbsp;
                            </span>
                            {{ companyDetails.completedLeadsNumber }}
                        </div>
                        <div class="flex md:justify-content-end">
                            <span class="font-medium">
                                Annulé(s):&nbsp;
                            </span>
                            {{ companyDetails.canceledLeadsNumber }}
                        </div>
                    </div>
                </div>
            </form>

            <p-accordion class="mt-5" *ngIf="companyDetails.events?.length > 0">
                <p-accordionTab header="Historique">
                    <p-timeline [value]="companyDetails.events" class="m-1">
                        <ng-template pTemplate="content" let-event>
                            <small>{{ event.createdBy }}, {{ event.createdAt | date:'medium' }}</small>
                            <p class="p-text-secondary">{{ event.eventMessage }}</p>
                        </ng-template>
                    </p-timeline>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>

<p-dialog header="Détails de l'offre" [modal]="true" [(visible)]="showSubscriptionDetails" [style]="{ width: '25rem' }">
    <div class="flex flex-column">
        <div class="font-medium mb-2">
            <span class="underline">Offre :</span>
            <span>&nbsp;{{ companyDetails.subscription?.label }}</span>
        </div>
        <div class="font-medium" *ngIf="companyDetails.subscription?.name === 'PLAN_PREMIUM'">
            <span class="underline">Quotas :</span>
            <ul>
                <li>{{ companyDetails.subscription?.maxUsers }} utilisateur(s)</li>
                <li>{{ companyDetails.subscription?.maxBridgeTransastionPerMonth }} transactions/mois</li>
            </ul>
        </div>
        <div class="font-medium mb-2">
            <span class="underline">Prix :</span>
            <span>&nbsp;{{ companyDetails.subscription?.price }} €/mois</span>
        </div>
        <div class="font-medium">
            <span class="underline">Description :</span>
            <div [innerHtml]="companyDetails.subscription?.description"></div>
        </div>
        <div class="font-medium">
            <span class="underline">Limites :</span>
            <div class="flex flex-column gap-1 mt-2">
                <span>Utilisateur(s)</span>
                <p-progressBar [value]="getUserPercentage()">
                    <ng-template pTemplate="content" let-value> 
                        <span>{{ companyDetails.countUsers }}/{{ companyDetails.subscription?.maxUsers }}</span>    
                    </ng-template>
                </p-progressBar>
            </div>
            <div class="flex flex-column gap-1 mt-2">
                <span>Transaction(s)/mois</span>
                <p-progressBar [value]="getTransactionsPercentage()">
                    <ng-template pTemplate="content" let-value> 
                        <span>{{ companyDetails.countMonthlyTransactions }}/{{ companyDetails.subscription?.maxBridgeTransastionPerMonth }}</span>    
                    </ng-template>
                </p-progressBar>
            </div>
        </div>
    </div>
</p-dialog>