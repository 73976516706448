import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-companies',
  standalone: true,
  imports: [
    DataViewModule,
    TagModule,
    CommonModule
  ],
  templateUrl: './companies.component.html',
  styleUrl: './companies.component.sass'
})
export class CompaniesComponent implements OnInit, OnDestroy {

  companies: any[] = [];

  s3ImageUrl: string = environment.s3ImageUrl;

  private _destroy$ = new Subject<void>();

  constructor(
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.customerService.getAllCompanies(false)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companies = response;
        }
      });
  }

  identifyCompany(index: number) {
    return index;
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
