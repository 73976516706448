<div class="text-lg font-bold text-900 mb-3 p-2">MÉTRIQUES D'ABONNEMENTS</div>
<div class="flex gap-3 justify-content-between flex-column lg:flex-row">
    <div class="flex flex-column shadow-2 border-round p-3 flex-grow-1">
        <p class="text-sm text-center font-medium">
            Répartition par abonnement
        </p>
        <div class="flex justify-content-center h-full align-items-center">
            <p-chart type="doughnut" [data]="doughnutSubscriptionsData" />
        </div>
    </div>
    <div class="flex flex-column shadow-2 border-round p-3 flex-grow-1">
        <p class="text-sm text-center font-medium">
            Abonnements par mois (6 derniers mois)
        </p>
        <div class="flex justify-content-center h-full align-items-center">
            <p-chart type="bar" [data]="barSubscriptionsData" [options]="barSubscriptionsOptions" />
        </div>
    </div>
    <div class="flex flex-column shadow-2 border-round p-3 flex-grow-1">
        <p class="text-sm text-center font-medium">
            Montant total des abonnements
        </p>
        <div
            class="flex justify-content-center h-full align-items-center text-5xl xl:text-6xl font-medium text-gray-600">
            {{ metrics.totalPrice }} €
        </div>
    </div>
    <div class="flex flex-column sm:flex-row lg:flex-column gap-2">
        <div class="flex flex-column shadow-2 border-round p-3 flex-grow-1">
            <p class="text-sm text-center font-medium">
                Comptes "Employé"
            </p>
            <div class="flex justify-content-center h-full align-items-center">
                <p-knob [(ngModel)]="metrics.totalEmployees" [valueTemplate]="getKnobEmployeesValue()" [min]="0"
                    [max]="metrics.totalEmployeeLimits ? metrics.totalEmployeeLimits : 100" [readonly]="true"
                    valueColor="#3f51b5" [size]="150" />
            </div>
        </div>
        <div class="flex flex-column shadow-2 border-round p-3 flex-grow-1">
            <p class="text-sm text-center font-medium">
                Transactions Bridge ({{ date | date:'MMMM' }})
            </p>
            <div class="flex justify-content-center h-full align-items-center">
                <p-knob [(ngModel)]="metrics.totalTransactions" [valueTemplate]="getKnobTransactionsValue()" [min]="0"
                    [max]="metrics.totalTransactionsLimits ? metrics.totalTransactionsLimits : 100" [readonly]="true"
                    valueColor="#3f51b5" [size]="150" />
            </div>
        </div>
    </div>
</div>