import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from '../../../../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../../reusable-components/sponsorship-details/sponsorship-details.component';

@Component({
  selector: 'app-sponsorship-details-admin',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};
  transactionDetails: any = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.adminService.getSponsorshipDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.sponsorshipDetails = response;
        this.sponsorshipDetails.bridgeTransactionDetailsOrStatus = JSON.parse(this.sponsorshipDetails.bridgeTransactionDetailsOrStatus);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
