<div class="block">
    <div>
        <div class="text-lg font-bold text-900 mb-3 p-2">UTILISATEURS</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="users.length === 0">
            <div class="text-xl font-medium text-900">Aucun utilisateur trouvé...</div>
            <!-- <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewCompanyDialog()" [text]="true" /> -->
        </div>

        <p-table #dt1 [value]="users" sortField="lastName" [sortOrder]="1" selectionMode="single"
            (onRowSelect)="onRowSelectUser($event)" [globalFilterFields]="['lastName', 'firstName', 'username', 'email', 'phone']"
            [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true"
            [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="users.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <!-- <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewCompanyDialog()" [text]="true" /> -->
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                    <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                    <th pSortableColumn="username">Nom d'utilisateur<p-sortIcon field="username" /></th>
                    <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                    <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr [pSelectableRow]="user">
                    <td>
                        <span class="md:hidden font-medium">Nom:&nbsp;</span>
                        <span>{{ user.lastName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                        <span>{{ user.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Nom d'utilisateur:&nbsp;</span>
                        <span>{{ user.username }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Email:&nbsp;</span>
                        <span>{{ user.email }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                        <span>{{ user.phone }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>