<div class="block">
    <app-leads [leads]="leads" [activeUser]="'admin'" (newLeadEmitter)="showNewLeadDialog()"></app-leads>
</div>

<p-dialog header="Créer un lead" [(visible)]="newLeadDialogIsVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">

    <p-messages key="message-key-dialog-new-lead" />

    <form [formGroup]="newLeadForm" (ngSubmit)="onSubmitNewLead()">
        <ul class="list-none p-0 m-0 mb-3">
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Commercial</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <p-dropdown formControlName="commercialId" [options]="commercials" optionLabel="firstName"
                        optionValue="id" placeholder="Choisir un commercial"></p-dropdown>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Email" pInputText formControlName="email"
                            aria-describedby="email-edit" (focusout)="focusOutCustomerEmail()">
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!newLeadForm.controls.email.errors?.['pattern']">
                            Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                        </small>
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!badRequestCustomerEmail.length">
                            {{ badRequestCustomerEmail }}
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Nom" pInputText formControlName="lastName"
                            aria-describedby="lastName-edit">
                        <small class="text-red-600" id="lastName-edit"
                            [hidden]="!newLeadForm.controls.lastName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Prénom</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Prénom" pInputText formControlName="firstName"
                            aria-describedby="firstName-edit">
                        <small class="text-red-600" id="firstName-edit"
                            [hidden]="!newLeadForm.controls.firstName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Téléphone" pInputText formControlName="phone"
                            aria-describedby="phone-edit">
                        <small class="text-red-600" id="phone-edit"
                            [hidden]="!newLeadForm.controls.phone.errors?.['pattern']">
                            Règles: 10 chiffres.
                        </small>
                        <small class="text-red-600" id="phone-edit"
                            [hidden]="!badRequestCustomerPhone.length">
                            {{ badRequestCustomerPhone }}
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Source</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Source" pInputText formControlName="source"
                            aria-describedby="source-edit">
                        <small class="text-red-600" id="source-edit"
                            [hidden]="!newLeadForm.controls.source.errors?.['pattern']">
                            Règles: que des caractères alphanumériques, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Ville</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Ville" pInputText formControlName="city"
                            aria-describedby="city-edit">
                        <small class="text-red-600" id="city-edit"
                            [hidden]="!newLeadForm.controls.city.errors?.['pattern']">
                            Règles: que des caractères alphanumériques, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
        </ul>

        <div class="flex flex-row gap-3">
            <div class="flex">
                <p-checkbox formControlName="legalNoticesAccepted" [binary]="true" />
            </div>
            <div class="flex">
                <p-scrollPanel [style]="{ width: '100%', height: '100px' }">
                    « Les informations recueillies par <span class="font-medium font-italic">LINKUPP</span> font l'objet
                    d'un
                    traitement informatique ayant pour finalité la gestion de notre fichier clients / prospects,
                    <span class="font-medium font-italic">LINKUPP</span> ayant un intérêt légitime à développer et
                    entretenir une
                    relation commerciale avec ses clients / prospects. Nous vous assurons qu'aucune donnée
                    ne sera communiquée, cédée ni revendue à des tiers. Les données seront conservées pour
                    une durée maximale de 3 ans après la fin de la relation commerciale, ou après votre
                    dernière demande. Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
                    modifiée et au Règlement Européen 2016/679 du 27 avril 2016 relatif à la protection des
                    personnes physiques à l'égard du traitement des données à caractère personnel, vous
                    disposez d'un droit d'accès et de rectification aux informations qui vous concernent ainsi que
                    d'un droit d'opposition, du droit à la limitation du traitement et à l'effacement dans le cadre
                    de la réglementation en vigueur. Vous pouvez exercer ces droits, il vous suffit d'envoyer un
                    email à cette adresse : <span class="font-medium font-italic">contact&#64;linkupp.fr</span>. Si vous
                    estimez, après nous avoir contactés, que vos
                    droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation
                    à la CNIL. »
                </p-scrollPanel>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" severity="danger" (onClick)="closeNewLeadDialog()" />
            <p-button label="Enregistrer" icon="pi pi-save" type="submit" [loading]="getLoading()"
                [disabled]="!newLeadForm.valid || getLoading()" />
        </div>
    </form>
</p-dialog>