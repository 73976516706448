import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../services/loading.service';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { InputTextModule } from 'primeng/inputtext';
import { PublicService } from '../../services/public.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    MessagesModule,
    InputTextModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.sass'
})
export class ForgotPasswordComponent implements OnDestroy {

  forceDisableSubmit: boolean = false;

  forgotPasswordForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')]],
  })

  private _destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private publicService: PublicService
  ) { }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  onSubmitForgotPassword() {
    const email: string = this.forgotPasswordForm.get("email")?.value!;
    this.publicService.forgotPassword(email)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.forceDisableSubmit = true;
          this.messageService.add({ severity: 'success', detail: 'Le lien de réinitialisation de votre mot de passe vous a été envoyé par mail' });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
