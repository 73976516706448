import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { TimelineModule } from 'primeng/timeline';
import { environment } from '../../../../../environments/environment';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { LoadingService } from '../../../../services/loading.service';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessagesModule } from 'primeng/messages';
import { RadioButtonClickEvent, RadioButtonModule } from 'primeng/radiobutton';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { UpdateCompanySubscriptionPlanRequest } from '../../../../payloads/requests/updateCompanySubscriptionPlanRequest';

@Component({
  selector: 'app-company-details',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    InputTextModule,
    AccordionModule,
    TimelineModule,
    TooltipModule,
    SkeletonModule,
    TagModule,
    DialogModule,
    ButtonModule,
    ProgressBarModule,
    MessagesModule,
    FormsModule,
    RadioButtonModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputGroupModule,
    InputGroupAddonModule
  ],
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.sass'
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {

  companyDetails: any = {};
  
  plans: any[] = [];
  hidePremiumPlanSettings: boolean = true;
  hidePlanPrice: boolean = false;
  selectedPlanDescritpion: string;
  selectedPlanPrice: string;

  s3ImageUrl: string = environment.s3ImageUrl;

  showSubscriptionDetails: boolean = false;

  updatePlanForm = this.formBuilder.group({
    planId: [0, Validators.required],
    premiumCustomPrice: 0,
    premiumCustomMaxUsers: 0,
    premiumCustomMaxBridgeTransastionPerMonth: 0
  })

  private _destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private superAdminService: SuperAdminService,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.superAdminService.getCompanyDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companyDetails = response;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  getLogoFullUrl(logoFilePath: string): string {
    return this.s3ImageUrl + '/' + logoFilePath;
  }

  clickShowSubscriptionDetails() {
    this.updatePlanForm.reset();
    this.hidePremiumPlanSettings = true;
    this.hidePlanPrice = false;
    this.showSubscriptionDetails = true;

    this.superAdminService.getAllPlans()
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.plans = response;
        const selectedPlan = this.plans.find(p => p.id === this.companyDetails.subscription.planId);
        this.selectedPlanDescritpion = selectedPlan.description;
        this.selectedPlanPrice = selectedPlan.price;

        this.updatePlanForm.get("planId")?.setValue(this.companyDetails.subscription.planId);

        if (this.companyDetails.subscription.planId == 3) {
          this.hidePremiumPlanSettings = false;
          this.hidePlanPrice = true;

          this.updatePlanForm.patchValue({
            premiumCustomPrice: this.companyDetails.subscription.price,
            premiumCustomMaxUsers: this.companyDetails.subscription.maxUsers,
            premiumCustomMaxBridgeTransastionPerMonth: this.companyDetails.subscription.maxBridgeTransastionPerMonth
          });
        }
      }, error: error => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-plan-details' });
      }
    });
  }

  closeSubscriptionDetails() {
    this.showSubscriptionDetails = false;
  }

  onClickRadioButton(event : RadioButtonClickEvent) {
    const selectedPlan = this.plans.find(p => p.id === event.value);
    this.selectedPlanDescritpion = selectedPlan.description;
    this.selectedPlanPrice = selectedPlan.price;

    if (event.value == 3) { // Premium plan
      this.hidePremiumPlanSettings = false;
      this.hidePlanPrice = true;
      this.updatePlanForm.get('premiumCustomPrice')?.setValidators([Validators.required]);
      this.updatePlanForm.get('premiumCustomPrice')?.updateValueAndValidity();
      this.updatePlanForm.get('premiumCustomMaxUsers')?.setValidators([Validators.required]);
      this.updatePlanForm.get('premiumCustomMaxUsers')?.updateValueAndValidity();
      this.updatePlanForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.setValidators([Validators.required]);
      this.updatePlanForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.updateValueAndValidity();
    } else {
      this.hidePremiumPlanSettings = true;
      this.hidePlanPrice = false;
      this.updatePlanForm.get('premiumCustomPrice')?.clearValidators();
      this.updatePlanForm.get('premiumCustomPrice')?.updateValueAndValidity();
      this.updatePlanForm.get('premiumCustomMaxUsers')?.clearValidators();
      this.updatePlanForm.get('premiumCustomMaxUsers')?.updateValueAndValidity();
      this.updatePlanForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.clearValidators();
      this.updatePlanForm.get('premiumCustomMaxBridgeTransastionPerMonth')?.updateValueAndValidity();
    }
  }

  identifyRole(index: number) {
    return index
  }

  identifyPlan(index: number) {
    return index
  }

  getUserPercentage(): number {
    return (this.companyDetails.countUsers * 100) / this.companyDetails.subscription?.maxUsers;
  }

  getTransactionsPercentage(): number {
    return (this.companyDetails.countMonthlyTransactions * 100) / this.companyDetails.subscription?.maxBridgeTransastionPerMonth;
  }

  onSubmitUpdatePlan() {
    const request: UpdateCompanySubscriptionPlanRequest = new UpdateCompanySubscriptionPlanRequest(
      this.companyDetails.id,
      this.updatePlanForm.get("planId")?.value!,
      this.updatePlanForm.get("premiumCustomPrice")?.value!,
      this.updatePlanForm.get("premiumCustomMaxUsers")?.value!,
      this.updatePlanForm.get("premiumCustomMaxBridgeTransastionPerMonth")?.value!
    );
    this.superAdminService.updateSubscriptionPlan(request)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.showSubscriptionDetails = false;
        this.companyDetails = response;
      }, error: error => {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-plan-details' });
      }
    });
  }

  companyDetailsIsSet(): boolean {
    if (!Object.keys(this.companyDetails).length) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
