import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommercialService } from '../../../../../services/commercial.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../../reusable-components/sponsorship-details/sponsorship-details.component';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../../../../services/loading.service';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { RadioButtonClickEvent, RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-sponsorship-details-commercial',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    FormsModule,
    CommonModule,
    InputNumberModule,
    FloatLabelModule,
    MessagesModule
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};
  transactionDetails: any = {};

  showChangeStatusDialog: boolean = false;
  hideCustomRewardAmount: boolean = true;
  customRewardAmount: number;

  processingStatus: any[] = [];
  initialSelectedStatus: number = 0;
  selectedStatus: number = 0;
  
  badRequestCustomReward: string = "";

  private _destroy$ = new Subject<void>();

  constructor(
    private loadingService: LoadingService,
    private commercialService: CommercialService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.commercialService.getSponsorshipDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails = response;
          this.sponsorshipDetails.bridgeTransactionDetailsOrStatus = JSON.parse(this.sponsorshipDetails.bridgeTransactionDetailsOrStatus);
          // To prevent submit same status
          this.initialSelectedStatus = response.status.id;
          this.selectedStatus = this.initialSelectedStatus;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  getCustomRewardAmountPlaceHolder(): string {
    return this.sponsorshipDetails.company?.minimumRewardAmount + " €";
  }

  addNote(event: any) {
    this.commercialService.addNoteToSponsorship(this.sponsorshipDetails.id, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails.events.unshift(response);
        }
      });
  }

  updateStatusDialog() {
    this.commercialService.getProcessingStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.selectedStatus = this.sponsorshipDetails.status.id;
          this.processingStatus = response;
          this.showChangeStatusDialog = true;
        }
      });
  }

  onClickStatus(event: RadioButtonClickEvent) {
    if (event.value == 4) {
      this.hideCustomRewardAmount = false;
    } else {
      this.hideCustomRewardAmount = true;
    }
  }

  rejectUpdateStatus() {
    this.showChangeStatusDialog = false;
  }

  acceptUpdateStatus() {
    this.badRequestCustomReward = "";
    this.commercialService.updateSponsorshipStatus(this.sponsorshipDetails.id, this.customRewardAmount, this.selectedStatus)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.showChangeStatusDialog = false;
          this.sponsorshipDetails = response;
          // To prevent submit same status
          this.initialSelectedStatus = response.status.id;
          this.selectedStatus = this.initialSelectedStatus;

          this.messageService.add({ severity: 'success', detail: 'Statut mis à jour', life: 5000 });
        }, error: error => {
          this.messageService.clear();
          error.status === 400 && error.field === "sponsorshipCustomReward" ? this.badRequestCustomReward = error.description : this.messageService.add({ severity: 'error', detail: error.description, key: 'message-key-dialog-update-status' });
        }
      });
  }

  identifyStatus(index: number) {
    return index
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
