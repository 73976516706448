<div id="block-navbar" class="bg-white sticky top-0 shadow-2 z-5">
    <div class="flex justify-content-between align-items-center m-auto w-10 sm:w-9 md:w-9 lg:w-7 xl:w-7">
        <h3 class="text-indigo-500">
            Linkupp
        </h3>
        <p-button [label]="getLoginOrPortalLabel()" icon="pi pi-sign-in" [text]="true" size="small" (onClick)="goToLoginOrPortal()" />
    </div>
</div>

<div class="flex flex-column">
    <div id="block-header" class="bg-white">
        <div class="grid grid-nogutter mt-6">
            <div
                class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 p-5 sm:p-5 md:p-5 lg:p-5 xl:p-5 flex justify-content-center sm:justify-content-center md:justify-content-end">
                <div class="md:w-9 lg:w-9 xl:w-9 max-w-30rem text-center md:text-left">
                    <h1 class="flex flex-column text-5xl">
                        <span>
                            Récompensez vos
                        </span>
                        <span class="text-indigo-500">
                            meilleurs ambassadeurs
                        </span>
                    </h1>

                    <p class="mb-5 text-gray-600 font-medium">
                        Transformez vos clients satisfaits en véritables ambassadeurs grâce à notre solution de
                        parrainage
                        innovante.
                    </p>

                    <div class="flex justify-content-center md:justify-content-start">
                        <p-button label="Réserver une démo" icon="pi pi-arrow-right" iconPos="right"
                            (onClick)="scrollIntoView('#block-demo')" [style]="{ 'font-weight': '500' }" />
                    </div>

                </div>
            </div>
            <div
                class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 p-5 sm:p-5 md:p-5 lg:p-5 xl:p-5 md:align-content-center flex sm:flex md:block justify-content-center sm:justify-content-center">
                <img class="w-8 sm:w-7 md:w-8 lg:w-8 xl:w-8 max-w-30rem border-round-sm shadow-7"
                    src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                    alt="linkupp" />
            </div>
        </div>
    </div>

    <div id="block-2" class="surface-50">
        <div class="mt-6 p-4">
            <p class="text-center text-sm font-medium text-indigo-500">POURQUOI LINKUPP ?</p>
            <h1 class="text-center">La solution de parrainage nouvelle génération</h1>
            <p class="mt-5 m-auto w-10 lg:w-9 xl:w-7 text-center text-sm font-medium text-gray-600">
                Linkupp propose une solution simple et efficace de parrainage client pour les entreprises de tous secteurs d'activité. Grâce à notre plateforme, vos clients peuvent recommander vos services et être récompensés
            en retour, stimulant ainsi l'acquisition de nouveaux clients et l'augmentation de votre chiffre d'affaires. Accessible, flexible et adapté à vos besoins, Linkupp transforme chaque recommandation
            en opportunité de développement pour votre entreprise.
            </p>
        </div>

        <div class="grid grid-nogutter mt-5 mb-8 m-auto w-10 lg:w-9 xl:w-7">
            <div class="col-12 md:col-6 lg:col-3 p-2" *ngFor="let benefit of benefits; trackBy:identifyBenefit">
                <div class="p-3 border-round-sm bg-white">
                    <div class="flex flex-column">
                        <div class="p-2 max-w-max border-round-sm bg-indigo-500 text-white">
                            <i [class]="benefit.icon" style="font-size: 1.5rem"></i>
                        </div>
                        <p class="flex flex-column gap-2">
                            <span class="font-medium">
                                {{ benefit.title }}
                            </span>
                            <span>
                                {{ benefit.description }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="block-team" class="bg-white">
        <div class="mt-6 p-4">
            <h1 class="text-center">Notre équipe</h1>
            <p class="text-center text-sm font-medium text-gray-600">Fondée en 2024, Linkupp réunit des experts
                passionnés par l'innovation et la satisfaction client.</p>
        </div>

        <div class="grid grid-nogutter mt-5 mb-8 m-auto w-10 lg:w-9 xl:w-7">
            <div class="col-12 md:col-4 p-2" *ngFor="let teammate of teams; trackBy:identifyTeammate">
                <div class="p-4 border-round-sm shadow-1 h-full">
                    <div class="flex flex-column gap-4">
                        <div class="flex justify-content-center">
                            <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle" />
                        </div>
                        <div class="flex flex-column gap-2">
                            <span class="font-medium">{{ teammate.name }}</span>
                            <span class="text-indigo-500 font-medium">{{ teammate.function }}</span>
                            <span>{{ teammate.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="block-demo" class="surface-50">
        <div class="mt-6 p-4">
            <h1 class="text-center">Réservez votre démo</h1>
            <p class="text-center text-sm font-medium text-gray-600">Dévouvrez comment Linkupp peut transformer vos
                clients en ambassadeurs.</p>
        </div>

        <div class="mt-5 mb-8 m-auto w-9 sm:w-12 max-w-30rem">
            <p-messages />
            <form [formGroup]="demoForm" (ngSubmit)="onSubmitDemoForm()">
                <div class="flex flex-column gap-2">
                    <div class="flex flex-column gap-1">
                        <label class="text-xs font-medium" for="companyName">Entreprise</label>
                        <input pInputText id="companyName" aria-describedby="companyName-help"
                            formControlName="companyName" />
                    </div>
                    <div class="flex flex-column sm:flex-row md:justify-content-between gap-2 md:gap-5">
                        <div class="flex flex-column gap-1 flex-grow-1">
                            <label class="text-xs font-medium" for="firstName">Prénom</label>
                            <input pInputText id="firstName" aria-describedby="firstName-help"
                                formControlName="firstName" />
                        </div>
                        <div class="flex flex-column gap-1 flex-grow-1">
                            <label class="text-xs font-medium" for="lastName">Nom</label>
                            <input pInputText id="lastName" aria-describedby="lastName-help"
                                formControlName="lastName" />
                        </div>
                    </div>
                    <div class="flex flex-column gap-1">
                        <label class="text-xs font-medium" for="email">Email</label>
                        <input pInputText id="email" aria-describedby="email-help" formControlName="email" />
                    </div>
                    <div class="flex flex-column gap-1">
                        <label class="text-xs font-medium" for="phone">Téléphone</label>
                        <input pInputText id="phone" aria-describedby="phone-help" formControlName="phone" />
                    </div>
                    <div class="flex mt-3">
                        <p-button type="submit" label="Réserver ma démo" icon="pi pi-calendar" class="w-full"
                            [loading]="getLoading()" iconPos="right"
                            [style]="{ 'font-weight': '500', width: '100%' }" [disabled]="!demoForm.valid || getLoading()" />
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div id="block-faq" class="bg-white">
        <div class="mt-6 p-4">
            <h1 class="text-center">Questions fréquentes</h1>
        </div>

        <div class="mt-5 mb-8 m-auto w-10 xl:w-6">
            <p class="font-medium">
                Comment fonctionne le système de parrainage ?
            </p>
            <p class="text-gray-600 text-sm">
                Vos clients peuvent recommander vos services via leur espace personnel. Une fois la recommandation
                convertie en client, le parrain reçoit automatiquement sa récompense en cash.
            </p>
            <p-divider />
            <p class="font-medium">
                Quels sont les avantages pour mon entreprise ?
            </p>
            <p class="text-gray-600 text-sm">
                Linkupp permet d'augmenter vos acquisitions clients, de réduire vos coûts d'acquisition et de fidéliser
                vos clients existants grâce à un programme de récompenses attractif.
            </p>
            <p-divider />
            <p class="font-medium">
                Comment sont versées les récompenses ?
            </p>
            <p class="text-gray-600 text-sm">
                Les récompenses sont versées automatiquement sur le compte bancaire du parrain dès que le filleul
                devient client, en toute sécurité.
            </p>
            <p-divider />
            <p class="font-medium">
                Puis-je personnaliser le programme de parrainage ?
            </p>
            <p class="text-gray-600 text-sm">
                Oui, vous pouvez définir vos propres règles de parrainage, montants de récompense et conditions
                d'attribution selon vos objectifs.
            </p>
        </div>
    </div>

    <div id="block-footer" class="surface-50">
        <div class="mt-5 m-auto w-10 lg:w-9 xl:w-6 text-gray-600 text-center md:text-left">
            <div class="grid grid-nogutter">
                <div class="col-12 md:col-4 px-5">
                    <div class="h-full">
                        <p class="text-sm font-medium">
                            A PROPOS
                        </p>
                        <div class="text-xs">
                            <p>
                                LinkUpp a été fondée en 2024 par trois associés.
                                Nous sommes spécialisés dans le parrainage digital et
                                la mise en relation entre particuliers.
                            </p>
                            <p>
                                LinkUpp est une start up en pleine expansion qui se
                                développe au niveau national, ainsi nous collaborons exclusivement
                                avec des entreprises de confiance.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 px-5">
                    <div class="h-ful">
                        <p class="text-sm font-medium">
                            INFORMATIONS LÉGALES
                        </p>
                        <div class="text-xs">
                            <p>
                                <a (click)="showLegalNotices()" class="no-underline hover:text-gray-800 cursor-pointer">Mentions légales</a>
                            </p>
                            <p>
                                <a (click)="showLicenseAgreement()" class="no-underline hover:text-gray-800 cursor-pointer">Contrat de licence</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-4 px-5">
                    <div class="h-full">
                        <p class="text-sm font-medium">
                            SIUVEZ-NOUS
                        </p>
                        <div class="flex flex-column gap-3 text-xs">
                            <div class="flex gap-2 justify-content-center md:justify-content-start">
                                <a href="https://www.instagram.com/linkupp_pro?igsh=MXVlam51ZjhlcmJhcQ=="
                                    target="_blank">
                                    <i class="pi pi-instagram"></i>
                                </a>
                                <a href="https://linkedin.com/company/linkupp-b29bbb32b/?viewAsMember=true"
                                    target="_blank">
                                    <i class="pi pi-linkedin"></i>
                                </a>
                            </div>

                            <div class="flex align-items-center gap-2 justify-content-center md:justify-content-start">
                                <i class="pi pi-envelope"></i>
                                <a href="mailto:contact@linkupp.fr" class="no-underline hover:text-gray-800">contact&#64;linkupp.fr</a>
                            </div>

                            <div class="flex align-items-center gap-2 justify-content-center md:justify-content-start">
                                <i class="pi pi-phone"></i>
                                <span>07 68 85 11 05</span>
                            </div>

                            <div class="flex align-items-center gap-2 justify-content-center md:justify-content-start">
                                <i class="pi pi-phone"></i>
                                <span>06 13 21 16 01</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p-divider [style]="{ margin: '1rem 0' }" />
            <p class="text-center text-sm">
                &copy; 2024, Linkupp - version {{ version }}, tous droits réservés.
            </p>
        </div>
    </div>
</div>

<p-dialog header="Mentions légales" [modal]="true" [(visible)]="visibleLegalNotices" [style]="{ width: '80rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [draggable]="false" [maximizable]="true">
    <div class="mb-5">
        <p class="text-xl font-medium">
            EDITEUR
        </p>
        <p>
            Le site <span class="font-medium font-italic">linkupp.fr</span> est édité par <span
                class="font-medium font-italic">LINKUPP</span>, SAS au capital de 6000 euros, immatriculée au RCS de
            CHAMBERY sous le numéro 932 034 846,
        </p>
        <p class="flex flex-column gap-1">
            <span>
                Siège social: 340 Route de la Gare, 73390 Chamousset
            </span>
            <span>
                Numéro de TVA: Non disponible
            </span>
            <span>
                Téléphone: 07 68 85 11 05 / 06 13 21 16 01
            </span>
            <span>
                E-mail: contact&#64;linkupp.fr
            </span>
            <span>
                Directeur de publication: Alrik SCHLAUDER
            </span>
        <p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            HÉBERGEUR
        </p>
        <p>
            AWS (Amazon Web Services)
        </p>
        <p>
            Téléphone: Support en ligne
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            PROPRIÉTÉ INTELLECTUELLE
        </p>
        <p>
            L'ensemble du site, y compris sa structure et son contenu (textes, tableaux,
            graphiques, images, photographies, vidéos, sons, bases de données, applications et
            logiciels), est protégé par le droit d'auteur et de propriété intellectuelle de
            <span class="font-medium font-italic">LINKUPP</span> ou de ses fournisseurs et prestataires.
        </p>
        <p>
            Toute représentation, reproduction, adaptation, modification ou exploitation non
            autorisée est interdite et susceptible de constituer une contrefaçon au sens des
            articles L. 335-2 et suivants du Code de la propriété intellectuelle.
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            DONNÉES PERSONNELLES
        </p>
        <p>
            <span class="font-medium font-italic">LINKUPP</span> s'engage à mettre en œuvre un traitement de vos
            données
            personnelles respectueux de votre vie privée et conforme à la législation française et
            européenne en vigueur.
        </p>
        <div>
            <div>
                <p class="text-lg font-medium">
                    Qui est le responsable de traitement ?
                </p>
                <p>
                    Le responsable de traitement est <span class="font-medium font-italic">LINKUPP</span>, SAS,
                    immatriculée au registre du commerce et des sociétés de CHAMBERY sous le numéro 932 034 846,
                    dont le siège social est situé: <span class="font-medium font-italic">340 Route de la Gare, 73390
                        Chamousset</span>.
                </p>
            </div>
            <div>
                <p class="text-lg font-medium">
                    Quelles sont vos données collectées ?
                </p>
                <p>
                    <span class="font-medium font-italic">LINKUPP</span> collecte les informations que vous acceptez de
                    fournir lorsque vous
                    passez commande, ou lorsque vous remplissez un formulaire sur le site. Le
                    caractère obligatoire des données est indiqué lors de la collecte par un astérisque.
                </p>
                <p>
                    <span class="font-medium font-italic">LINKUPP</span> collecte également les données relatives au
                    suivi
                    de la relation
                    commerciale, notamment votre historique d'achats et les demandes et
                    correspondances adressées au service client.
                </p>
            </div>
            <div>
                <p class="text-lg font-medium">
                    Pourquoi vos données sont-elles collectées ?
                </p>
                <p>
                    Vos données sont traitées sur le fondement juridique de la conclusion et de
                    l'exécution de votre contrat:
                </p>
                <ul>
                    <li>
                        Pour vous demandes d'informations sur nos services.
                    </li>
                    <li>
                        Pour la gestion et le suivi de votre commande.
                    </li>
                    <li>
                        Pour la facturation et l'encaissement des paiements.
                    </li>
                    <li>
                        Pour la gestion des réclamations et le suivi après-vente.
                    </li>
                </ul>
                <p>
                    Vos données sont traitées sur le fondement juridique des obligations légales de <span
                        class="font-medium font-italic">LINKUPP</span>:
                </p>
                <ul>
                    <li>
                        Pour le respect de ses obligations comptables et fiscales.
                    </li>
                </ul>
                <p>
                    Vos données sont traitées sur le fondement juridique de l'intérêt légitime de <span
                        class="font-medium font-italic">LINKUPP</span>:
                </p>
                <ul>
                    <li>
                        Pour la promotion et la vente des prestations de service.
                    </li>
                    <li>
                        Pour la gestion et l'amélioration des performances et fonctionnalités du site.
                    </li>
                    <li>
                        Pour l'analyse statistique et de l'activité du site.
                    </li>
                    <li>
                        Pour les études de qualité et les statistiques de vente des produits et services proposés.
                    </li>
                    <li>
                        Pour la lutte contre la fraude et l'amélioration de la sécurité du site.
                    </li>
                </ul>
            </div>
            <div>
                <p class="text-lg font-medium">
                    Pendant quelle durée vos données sont-elles conservées ?
                </p>
                <p>
                    Vos données sont conservées pendant toute la durée de la relation contractuelle qui vous lie à
                    <span class="font-medium font-italic">LINKUPP</span>.
                </p>
                <p>
                    Les données nécessaires à la facturation et aux obligations comptables et fiscales sont archivées
                    pendant 10 ans.
                </p>
                <p>
                    Les données liées aux demandes d'informations sont conservées 3 ans.
                </p>
            </div>
            <div>
                <p class="text-lg font-medium">
                    À qui vos données sont-elles transmises ?
                </p>
                <p>
                    Vos données sont susceptibles d'être transmises aux collaborateurs de <span
                        class="font-medium font-italic">LINKUPP</span>,
                    ainsi qu'aux sous-traitants appelés à intervenir pour le bon
                    fonctionnement du site: prestataires de services informatiques (hébergement,
                    maintenance, sécurité...), prestataires de paiements, sous-traitant techniques et
                    logistiques, conseils en analyse statistique et communication.
                </p>
                <p>
                    Vos données sont susceptibles de faire l'objet d'un transfert vers des pays situés
                    hors de l'Union européenne. Dans ce cas, <span class="font-medium font-italic">LINKUPP</span> devra
                    garantir que ces
                    données font l'objet d'une protection substantiellement équivalente à celle garantie
                    dans l'Union européenne, soit en vertu d'une décision d'adéquation rendue par la
                    Commission européenne, soit par la mise en place de toutes les mesures adaptées,
                    notamment par le recours aux clauses contractuelles types de la Commission
                    européenne. Vous pouvez demander plus d'informations sur ces garanties
                    appropriées aux coordonnées indiquées ci-après.
                </p>
            </div>
            <div>
                <p class="text-lg font-medium">
                    Quels sont vos droits ?
                </p>
                <p>
                    Vous pouvez accéder aux données vous concernant, les rectifier, demander leur
                    effacement ou exercer votre droit à la limitation du traitement de vos données.
                </p>
                <p>
                    Vous pouvez également vous opposer au traitement de vos données, pour des
                    raisons tenant à votre situation particulière, sauf en cas de prospection commerciale,
                    à laquelle vous pouvez vous opposer sans motif.
                </p>
                <p>
                    Vous pouvez retirer à tout moment votre consentement, sans porter atteinte à la
                    licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci.
                </p>
                <p>
                    Vous disposez d'un droit à la portabilité de vos données.
                </p>
                <p>
                    Vous pouvez émettre des directives concernant la conservation, la suppression ou la
                    communication de vos données personnelles après votre décès.
                </p>
                <p>
                    Enfin, vous pouvez introduire une réclamation auprès de la CNIL, sur son site
                    internet (www.cnil.fr) ou par voie postale (CNIL - Service des Plaintes - 3 Place de
                    Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07).
                </p>
                <p>
                    Pour exercer vos droits, il convient d'adresser votre demande aux coordonnées suivantes: <span
                        class="font-medium font-italic">contact&#64;linkupp.fr</span>.
                </p>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="CONTRAT DE LICENCE D'UTILISATION ET DE MAINTENANCE DU SITE LINKUPP" [modal]="true"
    [(visible)]="visibleLicenseAgreement" [style]="{ width: '80rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [draggable]="false" [maximizable]="true">
    <div class="mb-5">
        <p class="text-xl font-medium">
            1. Objet du contrat
        </p>
        <p>
            Le présent contrat a pour objet de déterminer les termes et conditions de l'utilisation par le
            client et de la maintenance du site <span class="font-medium font-italic">linkupp.fr</span> développée par
            <span class="font-medium font-italic">LINKUPP</span>. La signature par le client du bon de commande accepté
            par lui emporte également
            acceptation par lui sans réserve du présent contrat d'utilisation dont les stipulations ont pu être
            librement négociées entre les parties. Ledit bon de commande est régi par le présent contrat.
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            2. Durée du contrat
        </p>
        <p>
            Le présent contrat est conclu pour une durée initiale de à définir ( dont tacite reconduction) et
            conditions de résiliation à compter de la date d'installation ou mise en service du logiciel
            prévue au bon de commande.
            A l'issue de cette période initiale, le contrat sera tacitement reconduit entre les parties, par
            période d'une durée égale à la période initiale. Il en ira de même le cas échéant à l'issue de
            chaque période renouvelée. Chaque reconduction s'opérera selon les termes du présent
            contrat et ceux des avenants conclus entre temps.
            Chacune des parties pourra toutefois librement et sans indemnité de rupture mettre fin au
            contrat à chaque échéance initiale ou renouvelée. Il conviendra en ce cas de l'indiquer à l'autre
            partie au moins 3(mois) mois avant par lettre recommandée avec avis de réception.
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            3. Droits d'utilisation du logiciel
        </p>
        <p>
            La licence d'utilisation, <span class="font-medium font-italic">linkupp.fr</span>
            accordée en vertu du présent contrat et pour sa durée, permet au client d'utiliser
            <span class="font-medium font-italic">linkupp.fr</span>
            conformément à sa destination et pour ses besoins propres sur la configuration que le client
            aura défini.
        </p>
        <p>
            En dehors des droits concédés au présent article ci-dessus et sans préjudice de ceux-ci, le
            client n'est pas autorisé au titre des présentes à:
        </p>
        <ul>
            <li>
                copier, imprimer, transférer, modifier, adapter, maintenir, transmettre ou afficher tout ou
                partie de <span class="font-medium font-italic">linkupp.fr</span>;
            </li>
            <li>
                vendre, louer, sous-licencier ou distribuer de quelque façon que ce soit le site <span
                    class="font-medium font-italic">linkupp.fr</span>;
            </li>
            <li>
                d'utiliser <span class="font-medium font-italic">linkupp.fr</span> pour créer un produit concurrent ou
                un produit reprenant ou imitant les idées, les caractéristiques, les fonctions ou les
                graphismes de <span class="font-medium font-italic">linkupp.fr</span>;
            </li>
            <li>
                modifier le site <span class="font-medium font-italic">linkupp.fr</span> et/ou fusionner tout ou partie
                du
                site <span class="font-medium font-italic">linkupp.fr</span> dans d'autres programmes informatiques;
            </li>
            <li>
                compiler le site <span class="font-medium font-italic">linkupp.fr</span>, le décompiler, de
                désassembler, le
                traduire, l'analyser, procéder au reverse engineering ou tenter d'y procéder, sauf dans les
                limites autorisées par la loi;
            </li>
            <li>
                divulguer <span class="font-medium font-italic">linkupp.fr</span>, à quelque titre que ce soit, par
                quelque moyen que ce soit, et sous quelque forme que ce soit, à des tiers.
            </li>
        </ul>
        <p>
            Le client déclare avoir vérifié la parfaite adéquation de <span
                class="font-medium font-italic">linkupp.fr</span>
            à ses besoins et ses attentes, ainsi qu'à son système informatique, et avoir reçu de,
            <span class="font-medium font-italic">LINKUPP</span> préalablement à la conclusion du présent contrat, tous
            les conseils et toutes les
            informations utiles concernant <span class="font-medium font-italic">linkupp.fr</span>, notamment, mais
            sans que cette liste ne puisse être considérée comme limitative, en terme de fonctionnalités,
            de fonctionnement, d'utilisation, de compatibilité matérielle, logiciel, système ou donnée, de
            performance, de sécurité et de confidentialité des données clients (en particulier données à
            caractère personnel), d'obligations fiscales des parties prenantes . Le client déclare dès lors
            parfaitement connaître l'offre souscrite par lui.<br />
            Il est précisé, et expressément convenu entre les parties, qu'en l'état actuel de la technique
            informatique et de son évolution très rapide, il est impossible pour LINKUP de garantir que
            <span class="font-medium font-italic">linkupp.fr</span> fonctionnera sans discontinuité et sans anomalie.
            Ces aléas de fonctionnement sont indépendants de la volonté de LINKUP et des moyens
            techniques mis en œuvre par elle. Le client déclare en être parfaitement informé et conscient, et
            l'accepter.<br />
            <span class="font-medium font-italic">LINKUPP</span> intervient dans le cadre des présentes uniquement en
            qualité d'éditeur de solution
            logicielle. Le client est d'une manière générale seul responsable de l'utilisation par lui de
            <span class="font-medium font-italic">linkupp.fr</span>. Le client s'assurera, et le cas échéant fera en
            sorte, que cette utilisation soit conforme à la législation française et/ou étrangère applicable,
            notamment celle relative : (1) au traitement des données à caractère personnel, lequel
            notamment régi en France par la loi n° 78-17 du 6 janvier 1978 modifiée dite « Loi Informatique
            et Liberté » et par le règlement européen n° 2016/679 « relatif à la protection des personnes
            physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de
            ces données » dit RGPD du 27 avril 2016 ; (2) ou plus généralement à l'ensemble de ses
            activités. <span class="font-medium font-italic">LINKUPP</span> ne sera pour sa part nullement tenue de le
            vérifier nonobstant sa qualité de
            professionnel.<br />
            Le client demeure également seul responsable des informations, éléments, documents ou
            données, en particulier celles à caractère personnel au sens de la Loi Informatique et Libertés
            n° 78-17 du 6 janvier 1978 modifiée et par le règlement européen n° 2016/679 « relatif à la
            protection des personnes physiques à l'égard du traitement des données à caractère
            personnel et à la libre circulation de ces données » dit RGPD du 27 avril 2016, qui pourraient,
            dans le cadre de l'utilisation de <span class="font-medium font-italic">linkupp.fr</span> par le client,
            être
            utilisés, créés collectés, importés, exportés, fournis, diffusés, publiés, partagés ou traités, par
            le client et/ou, pour les opérations de traitement effectuées pour le compte de ce dernier, par
            <span class="font-medium font-italic">LINKUPP</span>. En particulier, le client s'assurera à cet égard qu'il
            dispose de l'ensemble des droits
            et autorisations si besoin nécessaires à la fourniture, à la collecte, à l'utilisation, à la création, à
            l'importation, à l'exportation, à la diffusion, à la divulgation, au partage et de manière générale
            à tout traitement de ces informations, éléments, documents et données, en particulier celles à
            caractère personnel au sens de la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978
            modifiée et du RGPD, dans le cadre de l'utilisation de <span
                class="font-medium font-italic">linkupp.fr</span> par lui.
            <span class="font-medium font-italic">LINKUPP</span> ne saurait être à cet égard considérée, dans le cadre
            du présent
            contrat, comme Responsable du traitement au sens de la Loi Informatique et Liberté n° 78-17
            du 6 janvier 1978 modifiée, mais uniquement comme Sous-traitant, au sens de cette même
            Loi, des traitements effectués au titre de la maintenance objet du présent contrat. En qualité de
            responsable du traitement, le client s'assurera de la légalité des traitements de données à
            caractère personnel effectués à l'aide de <span class="font-medium font-italic">linkupp.fr</span>. Il s'agit
            d'une condition substantielle du présent contrat. En conséquence, le client s'engage,
            notamment, à informer les personnes physiques concernées de l'utilisation faite desdites
            données. A ce titre, le client garantit <span class="font-medium font-italic">LINKUPP</span> contre tout
            recours, plainte ou encore
            réclamation émanant d'une personne physique dont les données à caractère personnel
            seraient reproduites, sauvegardées et de manière générale traitées via <span
                class="font-medium font-italic">linkupp.fr</span>.
            <span class="font-medium font-italic">LINKUPP</span> pour sa part, en qualité de Sous-traitant des
            traitements liés à la maintenance objet du présent contrat, s'engage à respecter les lois et
            réglementation en matière de protection des données personnelles. En particulier <span
                class="font-medium font-italic">LINKUPP</span>
            s'engage, pour lui son personnel permanent ou temporaire, ses sociétés affiliées et chacun de
            ses propres sous-traitants, à:
        </p>
        <ul>
            <li>
                n'agir sur les données que dans le cadre de la maintenance du logiciel prévue au présent
                contrat, sur instruction ou autorisation du client, pour le compte de ce dernier, et
                uniquement pour le temps nécessaire à la maintenance,
            </li>
            <li>
                à mettre tout en œuvre, notamment en adoptant toute mesure organisationnelle,
                physique et technique appropriée de protection, pour assurer, de manière adaptée, la
                confidentialité et la sécurité des données personnelles communiquées par le client ou
                directement par les personnes concernées via <span class="font-medium font-italic">linkupp.fr</span>,
                de manière permanente ou documentée, contre la destruction accidentelle ou
                illégale, la perte accidentelle, l'altération, la diffusion ou l'accès non autorisé, y compris
                dans le cadre de la communication de données sur un réseau, tout comme toute autre
                forme de traitement illicite ou non compris dans les traitement confiés au sens du présent contrat,
            </li>
            <li>
                mettre en place des restrictions d'accès logique et physique et des protections réseau
                nécessaires et conformes à l'exposé des dispositifs de sécurité déployés, ainsi que tout
                dispositif nécessaire de traçabilité des actions,
            </li>
            <li>
                en cas de cessation du présent contrat, pour tout motif, à retourner ou détruire les
                données personnelles en sa possession ou sous son contrôle en exécution du présent contrat,
            </li>
            <li>
                à s'abstenir d'exploiter ou utiliser, faire des copies ou créer des fichiers des données
                personnelles en dehors des besoins de l'exécution du présent contrat, à ses propres fins
                ou pour le compte de tiers,
            </li>
            <li>
                à modifier ou supprimer, à la demande du client, toute données personnelles en sa
                possession ou sous son contrôle en exécution du présent contrat, notamment en cas
                d'exercice par un individu de ses droits d'accès, de rectification et de suppression, de
                sorte que les données soient et demeurent exactes,
            </li>
            <li>
                à n'effectuer de transfert de données personnelles en dehors du territoire de l'Union
                Européenne qu'avec l'autorisation préalable du client et dans un cadre sécurisé
                conformément aux exigences de la loi applicable, c'est à dire vers des pays présentant
                un niveau de protection dit « adéquat » au sens des autorités européennes de protection
                des données personnelles (CNIL), soit vers des entités (sociétés affiliées, sous traitants)
                ayant signé des clauses contractuelles types telles qu édictées par les autorités
                européennes,
            </li>
            <li>
                fournir une solution structurellement respectueuse des principes de proportionnalité, de
                minimisation, de portabilité et de limitation des données personnelles, assurant que
                seules les données pertinentes, telles que définies par le client, sont traitées, pour la
                seule exécution du présent contrat et sous le contrôle des personnes ayant à en connaître,
            </li>
            <li>
                rendre compte et faire la preuve à première demande du client de l'ensemble des
                dispositifs et procédures de protection des données personnelles, de minimisation de leur
                utilisation et de conformité des dits dispositifs et procédures aux exigences légales.
            </li>
        </ul>
        <p>
            D'une manière générale, le client garantira <span class="font-medium font-italic">LINKUPP</span> contre
            toutes réclamations, recours,
            règlements, actions et procédures judiciaires ou non qui pourraient être formés contre
            <span class="font-medium font-italic">LINKUPP</span>, par quiconque, sur quelque fondement que ce soit, en
            lien direct ou indirect avec
            l'utilisation faite de <span class="font-medium font-italic">linkupp.fr</span> par le client. Le client en
            fera
            son affaire personnelle. Le cas échéant, le client : (1) fournira à <span
                class="font-medium font-italic">LINKUPP</span> toutes informations
            que <span class="font-medium font-italic">LINKUPP</span> jugera utile à sa défense et ce sans délai à
            première demande de <span class="font-medium font-italic">LINKUPP</span>, (2)
            substituera immédiatement <span class="font-medium font-italic">LINKUPP</span>, (3) et le cas échéant la
            relèvera intégralement et sans
            délai de toute condamnation. Le client prendra également à sa charge l'ensemble des frais et
            honoraires liés à la défense de <span class="font-medium font-italic">LINKUPP</span>, et ce sans délai à
            première demande de <span class="font-medium font-italic">LINKUPP</span>.
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            4. Maintenance du logiciel
        </p>
        <div>
            <p class="text-lg font-medium">
                Définitions
            </p>
            <p>
                « Anomalies » : désigne tout dysfonctionnement ou non-conformité de <span
                    class="font-medium font-italic">linkupp.fr</span>
                qui empêche le fonctionnement normal de tout ou partie du site <span
                    class="font-medium font-italic">linkupp.fr</span>
                ou qui provoque un résultat incorrect ou un
                traitement inadapté alors que <span class="font-medium font-italic">linkupp.fr</span> est utilisé
                correctement conformément à sa documentation et à sa destination.<br />
                « Anomalie bloquante » : désigne toute anomalie rendant impossible l'utilisation de tout ou
                partie des fonctionnalités de <span class="font-medium font-italic">linkupp.fr</span>.<br />
                « Anomalie non bloquante » : désigne toute anomalie permettant de poursuivre l'exploitation
                complète de <span class="font-medium font-italic">linkupp.fr</span> dans l'ensemble de ses
                fonctionnalités, même si cela se fait au moyen d'une procédure inhabituelle, mise en oeuvre
                par le client lui-même.<br />
                « Anomalie semi-bloquante » : désigne toute anomalie ne permettant l'utilisation des
                fonctionnalités de <span class="font-medium font-italic">linkupp.fr</span> que pour partie.<br />
                « Incident » : désigne un dysfonctionnement de <span class="font-medium font-italic">linkupp.fr</span>
                provenant soit d'une erreur de manipulation de l'utilisateur soit d'une anomalie affectant le
                code.
                La teneur de l'incident est identifiée pendant la phase d'assistance.<br />
                « Site d'utilisation » : désigne le lieu géographique précis où <span
                    class="font-medium font-italic">linkupp.fr</span>
                est utilisé par l'utilisateur et maintenu par <span class="font-medium font-italic">LINKUPP</span>, à
                l'exclusion de tout autre lieu.<br />
                « Registre de maintenance » : désigne l'outil informatique mise à disposition par <span
                    class="font-medium font-italic">LINKUPP</span> sur
                lequel l'interlocuteur privilégié saisit l'ensemble des anomalies rencontrées et pour lesquelles
                <span class="font-medium font-italic">LINKUPP</span> apporte une réponse et une solution.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Collaboration
            </p>
            <p>
                Les parties reconnaissant que les prestations de services en informatique nécessitent une
                collaboration active et régulière entre elles et s'y engagent.<br />
                Le client s'engage à fournir au personnel de <span class="font-medium font-italic">LINKUPP</span>,
                chargé de la réalisation des travaux de
                maintenance, tous les documents, renseignements et éléments nécessaires à la bonne
                compréhension du problème posé.<br />
                Dans l'intérêt commun des parties, le client s'oblige au moyen du registre de maintenance à
                rendre compte à <span class="font-medium font-italic">LINKUPP</span> des défauts et erreurs de <span
                    class="font-medium font-italic">linkupp.fr</span>
                ainsi que de tout problème constaté ou suggestion d'amélioration.<br />
                <span class="font-medium font-italic">LINKUPP</span> s'engage à mettre à disposition de l'utilisateur un
                outil adapté d'enregistrement et de
                traçabilité de la prise en compte et de résolutions des problèmes constatés.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Prestations de maintenance
            </p>
            <p>
                Dans le cadre du présent contrat, <span class="font-medium font-italic">LINKUPP</span> s'engage à
                fournir au client les prestations suivantes :
            </p>
            <ul>
                <li>
                    Assistance téléphonique
                    <p>
                        <span class="font-medium font-italic">LINKUPP</span> s'engage à fournir au client une assistance
                        téléphonique et de télémaintenance à
                        l'utilisation de <span class="font-medium font-italic">linkupp.fr</span> conformément à sa
                        documentation.
                        L'assistance téléphonique se fera au moyen de renseignements et conseils fournis dans le
                        cadre de la permanence téléphonique par un technicien de <span
                            class="font-medium font-italic">LINKUPP</span>, sur appel de « l'
                        interlocuteur privilégié » désigné par le client, les jours ouvrés du lundi au vendredi pendant
                        les
                        heures ouvrables de <span class="font-medium font-italic">LINKUPP</span>, c'est-à-dire de 9H à
                        12h00 et de 13h30 à 17h30.<br />
                        Les délais de prise en compte des problèmes et de résolution sont les suivants :<br />
                        1. délai de prise en compte : en jours ouvrés une demi-journée, hors jours ouvrés la demi-
                        journée suivante<br />
                        2. délai de résolution :<br />
                        - problèmes bloquants : 24 heures maximum<br />
                        - semi-bloquants : 1 semaine<br />
                        - non bloquants : 1 mois<br />
                        Les problèmes rencontrés, les délais de résolution et les solutions apportées feront l'objet
                        d'un
                        point trimestriel.
                    </p>
                </li>
                <li>
                    Télémaintenance
                    <p>
                        La correction des anomalies signalées par le client pourra se faire par l'intervention à
                        distance
                        du technicien de <span class="font-medium font-italic">linkupp.fr</span> au moyen d'une
                        connexion électronique.<br />
                        Ces opérations pourront s'effectuer tous les jours ouvrés du lundi au vendredi pendant les
                        heures ouvrables de <span class="font-medium font-italic">LINKUPP</span>, après concertation
                        préalable pour exclure les moments
                        inopportuns.<br />
                        Pour permettre l'exécution de ce service, le client s'engage à installer à ses frais sur le site
                        d'utilisation tous les éléments requis pour la connexion à distance entre le centre de
                        maintenance de <span class="font-medium font-italic">LINKUPP</span> et son site d'utilisation
                        (logiciel utilisé Log Me In Rescue mis à
                        disposition)
                    </p>
                </li>
                <li>
                    Intervention sur site
                    <p>
                        L'intervention consiste dans le déplacement sur le site d'utilisation, d'un technicien de
                        <span class="font-medium font-italic">LINKUPP</span> pour la prise en compte, l'analyse et la
                        correction d'éventuelles anomalies dûment
                        documentées au préalable par le client et reportées par écrit dans le registre de maintenance.
                        L'intervention du technicien <span class="font-medium font-italic">LINKUPP</span> est assurée
                        tous les jours ouvrés du lundi au vendredi
                        pendant les heures ouvrables de <span class="font-medium font-italic">LINKUPP</span>, et
                        s'effectuera dans les heures ouvrées du client,
                        après concertation préalable pour exclure les moments inopportuns.<br />
                        De son côté, l'utilisateur s'engage à mettre à la disposition du technicien de <span
                            class="font-medium font-italic">LINKUPP</span> tous les
                        moyens et ressources nécessaires à son intervention sur le site.<br />
                        Préalablement à l'exécution du service, le client rencontrant une anomalie de <span
                            class="font-medium font-italic">linkupp.fr</span>
                        devra prévenir <span class="font-medium font-italic">LINKUPP</span>, par l'intermédiaire de son
                        interlocuteur privilégié, par tout moyen de communication. Il devra en outre, avoir
                        préalablement envoyé à <span class="font-medium font-italic">LINKUPP</span> tout document
                        caractérisant l'anomalie, pouvant lui faciliter
                        la compréhension du problème rencontré et préciser les conditions d'utilisation du logiciel lors
                        de l'apparition de l'anomalie.<br />
                        Tout dépannage ou résolution de problème nécessitant un déplacement sur site sera facturé à
                        raison de XX HT/jour (+Frais de déplacement et hébergement sur justificatif) par <span
                            class="font-medium font-italic">LINKUPP</span>.
                    </p>
                </li>
                <li>
                    Garanties
                    <p>
                        La mise en conformité du logiciel, c'est-à-dire la résolution totale des anomalies avérées
                        rencontrées, tout en respectant les délais ci avant mentionnés, constitue la seule obligation du
                        <span class="font-medium font-italic">LINKUPP</span> au titre de la présente garantie quel que
                        soit le fondement sur lequel reposent les
                        réclamations du client. <span class="font-medium font-italic">LINKUPP</span> ne garantit pas que
                        l'intervention permettra de régler
                        immédiatement la difficulté rencontrée, ni qu'après l'intervention l'anomalie n'apparaîtra pas
                        de
                        nouveau, ni qu'aucune difficulté ne sera générée du fait de l'intervention du service de
                        maintenance. Mais en cas d'anomalie bloquante <span
                            class="font-medium font-italic">LINKUPP</span> fera tout son possible pour la régler
                        rapidement ou mettre en place une solution de contournement provisoire, dans le respect des
                        délais définis ci avant.
                    </p>
                </li>
                <li>
                    Suivi
                    <p>
                        Au titre des prestations de suivi, <span class="font-medium font-italic">LINKUPP</span> s'engage
                        à fournir à l'utilisateur les mises à jour et
                        les éventuelles nouvelles versions de <span class="font-medium font-italic">linkupp.fr</span>,
                        en
                        fournissant la documentation nécessaire et en indiquant par écrit les points modifiés, les
                        modalités d'utilisation, les points de contrôle ou de recette à effectuer.<br />
                        Les mises à jour de <span class="font-medium font-italic">linkupp.fr</span> seront fournies au
                        client par
                        <span class="font-medium font-italic">LINKUPP</span> en fonction de la périodicité dont ce
                        dernier reste seul juge.
                    </p>
                </li>
                <li>
                    Prestations exclues
                    <p>
                        Sont exclues des prestations fournies par <span class="font-medium font-italic">LINKUPP</span>
                        au titre du présent contrat, les prestations suivantes :
                    </p>
                    <ul>
                        <li>
                            les prestations liées à la correction des dysfonctionnements causés par une utilisation
                            incorrecte de <span class="font-medium font-italic">linkupp.fr</span> par le client ou par
                            les
                            modifications apportées par le client au logiciel sans autorisation <span
                                class="font-medium font-italic">LINKUPP</span>.
                        </li>
                        <li>
                            les prestations qui se révèleraient ne pas être liées directement à <span
                                class="font-medium font-italic">linkupp.fr</span>
                        </li>
                        <li>
                            la reconstitution des fichiers de données en cas de destruction accidentelle.
                        </li>
                        <li>
                            les prestations liées au non-respect des instructions écrites, telles que spécifications,
                            procédures, mesures de sécurité et de prudence, avertissements divers, figurant dans la
                            documentation associée de <span class="font-medium font-italic">linkupp.fr</span>.
                        </li>
                        <li>
                            modification de l'infrastructure informatique (serveur) du client nécessitant une
                            réinstallation de l'application.
                        </li>
                        <li>
                            le développement de nouvelles fonctionnalités ou modules.
                        </li>
                    </ul>
                    <p>
                        Ces prestations, si le client et le demande et si <span
                            class="font-medium font-italic">LINKUPP</span> accepte de les fournir, feront l'objet
                        d'une facturation complémentaire, non comprise dans celle prévue au titre du présent contrat.
                    </p>
                </li>
            </ul>
        </div>
        <div>
            <p class="text-lg font-medium">
                Modalités techniques
            </p>
            <p>
                Pour permettre la réalisation des opérations de maintenance sur le site d'utilisation, le client
                s'engage à :
            </p>
            <ul>
                <li>
                    laisser pénétrer dans ses locaux toute personne mandatée par <span
                        class="font-medium font-italic">LINKUPP</span> afin de surveiller
                    le fonctionnement des programmes utilisés par le client.
                </li>
                <li>
                    respecter les conditions normales d'utilisation du logiciel.
                </li>
                <li>
                    appliquer précisément les instructions écrites données par la société <span
                        class="font-medium font-italic">LINKUPP</span> et à
                    respecter toutes les dispositions du présent contrat.
                </li>
                <li>
                    informer la société <span class="font-medium font-italic">LINKUPP</span> de toutes anomalies dans le
                    fonctionnement du logiciel.
                </li>
                <li>
                    fournir tous les détails (explications, imprimes écrans, résultat obtenu erroné, résultat
                    attendu…etc) et répondre à toutes les questions permettant de faciliter l'exécution de la
                    prestation de maintenance.
                </li>
                <li>
                    maintenir ou acquérir à tout moment le nombre de licences utilisateurs correspondant au
                    nombre d'exemplaires du logiciel installés et utilisés sur son site d'utilisation.
                </li>
            </ul>
            <p>
                Le client sera en outre tenu d'accepter toute révision de logiciel proposée par <span
                    class="font-medium font-italic">LINKUPP</span>
                gratuitement, et de procéder ou de faire procéder à son installation dans un délai maximum de
                30 jours après sa livraison par <span class="font-medium font-italic">LINKUPP</span>.<br />
                Chaque installation fera l'objet d'une procédure écrite d'installation : sauvegarde préalable,
                modalités, tests à effectuer, procédure éventuelle de retour en arrière, contenu (nouveautés - problèmes
                réglés).
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Formation des utilisateurs
            </p>
            <p>
                Le client est tenu d'assurer à ses collaborateurs, utilisant <span
                    class="font-medium font-italic">linkupp.fr</span>,
                un niveau de connaissance et de compétences suffisante pour une utilisation
                conforme, notamment en leur faisant suivre les formations spécifiques correspondantes, telles
                que définies dans le plan de formation type, les fiches exercices et la documentation
                consultable en ligne.<br />
                Toute formation spécifique ou complémentaire sera facturée sur devis.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Registre de maintenance
            </p>
            <p>
                Le client s'engage à tenir à jour le registre de maintenance.<br />
                Ledit registre de maintenance sera tenu à jour par l'interlocuteur désigné par le client qui y
                notera les particularités d'utilisation au moment de chaque anomalie, et la description
                documentée de celle-ci. Ce registre de maintenance contiendra également l'ensemble des
                comptes rendus d'intervention.<br />
                Le <span class="font-medium font-italic">LINKUPP</span> s'engage à répondre aux demandes de
                l'interlocuteur privilégié dans les délais
                indiqués ci avant. Le registre de maintenance sera porteur de la date de prise en compte, le
                délai prévisionnel de réalisation, le délai prévisionnel d'installation.
            </p>
        </div>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            5. Garanties
        </p>
        <p>
            <span class="font-medium font-italic">LINKUPP</span> ne saurait être, de quelque manière et sur quelque
            fondement que ce soit, tenue
            pour responsable de difficultés d'exécution du présent contrat liées, en tout ou partie : (1) à la
            force majeure, et notamment, outre les événements habituellement retenus par la
            jurisprudence des juridictions françaises relative aux dispositions de l'article 1218 du Code
            civil, les cas suivants, sans que cela ne puisse toutefois être considéré comme valant liste
            limitative : grèves totales ou partielles, extérieures à <span
                class="font-medium font-italic">LINKUPP</span>, intempéries, épidémies,
            blocages des moyens de transport pour quelque raison que ce soit, mouvements populaires,
            attentats, guerres, tremblements de terre, incendies, tempêtes, restrictions gouvernementales
            ou légales, défaillances ou pertes de connectivité imputables aux opérateurs publics ou privés
            notamment de télécommunication dont elle dépend et tous les autres cas indépendants de sa
            volonté empêchant l'exécution normale de la présente contrat ; (2) au fait d'un tiers sur lequel
            <span class="font-medium font-italic">LINKUPP</span> n'a aucun pouvoir de contrôle et ou de surveillance ;
            (3) ou encore, au client, ou à
            toutes personnes qui lui seraient directement ou indirectement liées. Par ailleurs, par
            application des disposition de l'article 1231-3 du Code civil, il est expressément convenu entre
            les parties que si la responsabilité de <span class="font-medium font-italic">LINKUPP</span> était retenue
            relativement à l'exécution de la
            contrat, le montant total des indemnités réclamées par le client ne pourrait dépasser un
            montant égal au montant hors taxes dû par le client au titre de la présente contrat pour l'année
            contractuelle au cours de laquelle s'est produit l'événement générateur de responsabilité, ce
            montant constituant un plafond d'indemnisation et non une indemnité forfaitaire. Sont exclus de
            toute indemnisation, la perte de chiffre d'affaires, les préjudices non immédiats et/ou indirects
            et/ou éventuels subis par le client ou les conséquences de prétentions ou réclamations,
            formulées par un tiers quel qu'il soit à l'encontre du client. De la même manière, <span
                class="font-medium font-italic">LINKUPP</span> ne
            pourra être engagée solidairement ou in solidum avec les tiers ayant concouru au dommage.
            <span class="font-medium font-italic">LINKUPP</span> garantit le client qu'il dispose de l'ensemble des
            droits et autorisations lui permettant
            de s'engager au titre du présent contrat. Notamment, <span class="font-medium font-italic">LINKUPP</span>
            garantit qu'il dispose de tous
            les droits relatifs à <span class="font-medium font-italic">linkupp.fr</span>. <span
                class="font-medium font-italic">LINKUPP</span> garantit le client
            contre toutes réclamations, recours, règlements, actions et procédures judiciaires ou non qui
            pourraient être formés contre le client, par quiconque, sur quelque fondement que ce soit
            notamment sur celui de la propriété intellectuelle ou industrielle, en lien direct avec l'un ou
            l'ensemble des éléments du présent contrat.
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            6. Conditions financières
        </p>
        <p>
            Pour prix du présent contrat, le client versera à <span class="font-medium font-italic">LINKUPP</span> le
            prix indiqué au bon de commande
            signé par le client, selon les conditions de facturation et de règlement figurant au même bon de
            commande. Il est précisé que le premier mois est facturé intégralement quelque soit le moment
            au cour de ce mois de l'installation de <span class="font-medium font-italic">linkupp.fr</span>.<br />
            Ce prix comprend:
        </p>
        <ul class="list-decimal">
            <li>
                une redevance mensuelle par utilisateur permettant l'utilisation et la maintenance
                de <span class="font-medium font-italic">linkupp.fr</span>,
            </li>
            <li>
                le prix des services ou fonctionnalités optionnelles facturés à l'unité.
            </li>
        </ul>
        <p>
            A l'expiration de la durée initiale du contrat, le prix est révisable annuellement, à la date du 1 er
            janvier de chaque année, en fonction de la variation de l'indice Syntec connu à cette date.<br />
            Au cas où le client souhaiterait en cours de contrat compléter l'offre initialement souscrite
            (notamment pour augmenter le nombre maximal d'utilisateurs ou souscrire à des services ou
            fonctionnalités optionnelles non initialement retenus), un nouveau bon de commande sera
            établi par <span class="font-medium font-italic">LINKUPP</span>. Le cas échéant, chaque bon de commande
            complémentaire signé par le
            client sera, comme le bon de commande initial signé par le client, soumis au contrat.<br />
            Conformément aux dispositions du Code de commerce, toute somme non payée à échéance
            convenue sera automatiquement assortie d'un intérêt de retard correspondant à trois (3) fois le
            taux d'intérêt légal en vigueur. Le client sera en outre, toujours par application des dispositions
            du même Code, redevable de plein droit d'une indemnité forfaitaire pour frais de recouvrement
            d'un montant de 40 euros. Lorsque les frais de recouvrement exposés seront supérieurs au
            montant de cette indemnité forfaitaire, <span class="font-medium font-italic">LINKUPP</span> pourra demander
            une indemnisation
            complémentaire, sur justification. En outre, à défaut de régularisation dans un délai de dix (10)
            jours après mise en demeure adressée au client par lettre recommandée avec accusé de
            réception mentionnant expressément la présente clause résolutoire, tout retard dans le
            paiement des sommes dues à <span class="font-medium font-italic">LINKUPP</span> au titre de la présente
            contrat, pourra donner lieu de
            plein droit à l'initiative de <span class="font-medium font-italic">LINKUPP</span>, sans intervention du
            juge : (a) à interruption immédiate du
            droit d'utilisation et de la maintenance de <span class="font-medium font-italic">linkupp.fr</span> jusqu'au
            complet paiement des sommes dues conformément aux dispositions de l'article 1219 du Code
            civil, sans que cela n'emporte interruption de la redevance mensuelle qui continuera à être due
            et facturée pendant la période d'interruption ; (b) voire, au choix de <span
                class="font-medium font-italic">LINKUPP</span>, à résolution du
            présent contrat à compter soit de la fin de la période d'exception d'inexécution stipulée au point
            (a) précédent, soit à défaut, à compter de la date d'envoi de la mise en demeure susvisée. En
            cas de résolution, les rémunérations prévues au présent article, préalablement réglées par le
            client, resteront quel que soit le moment de la résolution, définitivement acquises à <span
                class="font-medium font-italic">LINKUPP</span>,
            sans possibilité de remboursement. La résolution interviendra par ailleurs sans préjudice de
            toute action en justice que <span class="font-medium font-italic">LINKUPP</span> pourrait dans le même temps
            ou ultérieurement initier afin
            d'obtention de dommages et intérêts. <span class="font-medium font-italic">LINKUPP</span> pourra toutefois
            renoncer à cette résolution de
            plein droit pour poursuivre en justice l'exécution forcée du contrat ou toute autre fin. Le fait par
            <span class="font-medium font-italic">LINKUPP</span> de ne pas se prévaloir d'un manquement du client à son
            obligation de paiement ne
            saurait être interprété comme une renonciation au paiement.
        </p>
    </div>

    <div class="mb-5">
        <p class="text-xl font-medium">
            7. Dispositions générales
        </p>
        <div>
            <p class="text-lg font-medium">
                Propriété
            </p>
            <p>
                La présente contrat ne donne lieu à aucun transfert de droits de propriété, notamment
                intellectuelle, sur <span class="font-medium font-italic">linkupp.fr</span>. <span
                    class="font-medium font-italic">LINKUPP</span> en conserve seule
                l'entière propriété matérielle et intellectuelle. L'usage fait par le client de <span
                    class="font-medium font-italic">linkupp.fr</span>
                ne saurait être considéré à cet égard comme constitutif de
                droits, si ce n'est la concession accordée.<br />
                Le client demeure seul propriétaire de sa base de données, notamment à caractère personnel
                au sens de la loi sur la protection des données personnelle.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Assurance
            </p>
            <p>
                <span class="font-medium font-italic">LINKUPP</span> est titulaire d'une police d'assurance garantissant
                les risques découlant des
                obligations mises à sa charge en application du contrat. Pendant toute la durée des obligations
                énoncées au présent contrat, <span class="font-medium font-italic">LINKUPP</span> s'engage à souscrire
                une telle police d'assurance
                permettant de prendre en charge les conséquences pécuniaires de sa responsabilité civile au
                cas où elle serait engagée.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Assurance
            </p>
            <p>
                Chaque partie conservera confidentiel, pendant et après la présente contrat, l'ensemble des
                informations, notamment tarifaires, et documents fournis par l'autre ou dont elles auraient eu
                connaissance à l'occasion de la formation ou de l'exécution du présent contrat. Le client
                s'interdit en particulier de communiquer les dispositions du présent contrat à des tiers sans
                l'accord préalable et écrit de <span class="font-medium font-italic">LINKUPP</span>. <span
                    class="font-medium font-italic">LINKUPP</span> pourra toutefois afin de promouvoir son
                activité faire référence au client notamment en utilisant sa dénomination sociale, son nom
                commercial et son logo sur ses propres outils de communication. Chaque partie s'engage à
                faire respecter cette obligation par ses employés, société mère et filiales et sous traitants
                éventuels.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Intégralité du contrat
            </p>
            <p>
                Les dispositions du présent contrat constituent l'intégralité de l'accord intervenu entre les
                parties. Le bon de commande initial signé par le client et ses annexes, et ceux
                complémentaires le cas échéant également signés par le client, font en toutes leurs
                dispositions parties intégrante du présent contrat, en sont indissociables, et y sont soumis.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Version du contrat
            </p>
            <p>
                Au cas où les présentes feraient l'objet d'une traduction en langue étrangère, seule la version
                française fera foi.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Modification du contrat
            </p>
            <p>
                Les modifications relatives au présent contrat devront faire l'objet d'un avenant conclu par écrit
                entre les parties, daté et signé. Le cas échéant, les bons de commande complémentaires
                signés par le client, en cours de contrat, vaudront avenants au présent contrat.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Cession du contrat
            </p>
            <p>
                Le présent contrat reste valable en cas de modification de la forme sociale ou de la répartition
                du capital social de <span class="font-medium font-italic">LINKUPP</span> ou de changement dans sa
                direction, de même qu'en cas de
                cession du présent contrat par <span class="font-medium font-italic">LINKUPP</span> à un tiers. Le
                présent contrat ne pourra en revanche
                faire l'objet d'une cession totale ou partielle, à titre onéreux ou gracieux, du fait du client sans
                l'accord préalable et écrit de <span class="font-medium font-italic">LINKUPP</span>.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Cession du contrat
            </p>
            <p>
                Sous réserve des dispositions de l'article 1218 du Code civil relatives à la force majeure, le
                non-respect par l'une des parties de l'une quelconque des obligations mises à sa charge dans
                le cadre du présent contrat, pourra donner lieu à résiliation de plein droit de la présente contrat
                sans intervention du juge. Sauf pour ce qui est de l'obligation de paiement précisée à l'article 6
                ci-avant, la résiliation sera acquise à défaut de régularisation dans un délai de quinze (15)
                jours après mise en demeure adressée par lettre recommandée avec accusé de réception
                mentionnant expressément la présente clause résolutoire. Il est à cet égard expressément
                convenu qu'en ce cas, les rémunérations prévues à l'article 6 du présent contrat,
                préalablement réglées par le client, resteront, quel que soit le moment et l'imputabilité de la
                résolution, définitivement acquises à <span class="font-medium font-italic">LINKUPP</span>, sans
                possibilité de remboursement. Chaque
                partie pourra toutefois renoncer à cette résolution de plein droit pour poursuivre en justice
                l'exécution forcée du contrat ou toute autre fin.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Effets de la cessation du contrat
            </p>
            <p>
                En cas de cessation des présentes relations contractuelles et ce, pour quelque raison que
                ce soit, le client s'engage soit à restituer à <span class="font-medium font-italic">LINKUPP</span> dans
                les huit (8) jours de la fin des
                relations contractuelles, l'ensemble des éléments constitutifs de <span
                    class="font-medium font-italic">linkupp.fr</span>,
                y compris les supports et toutes les copies qui en auront été faites, en
                garantissant par écrit l'intégralité de cette remise, soit à fournir par écrit, une attestation
                certifiant la destruction de <span class="font-medium font-italic">linkupp.fr</span>, de ses supports et
                de toutes les copies qui auraient pu être faites.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Loi applicable
            </p>
            <p>
                Le présent contrat est régie et sera interprété selon le droit français.
            </p>
        </div>
        <div>
            <p class="text-lg font-medium">
                Attribution de compétence
            </p>
            <p>
                Tous les différends relatifs à la présente contrat, quels qu'ils soient, seront soumis, de la
                volonté des parties, à la compétence du tribunal de commerce du siège sociale de <span
                    class="font-medium font-italic">LINKUPP</span>, y
                compris en cas de procédure de référé ou de pluralités de défendeurs, sauf compétence
                dérogatoire.
            </p>
        </div>
    </div>
</p-dialog>