import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { JwtService } from '../../services/jwt.service';

@Component({
  selector: 'app-forbidden',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './forbidden.component.html',
  styleUrl: './forbidden.component.sass'
})
export class ForbiddenComponent {

  constructor(
    private router: Router,
    private jwtService: JwtService
  ) {}

  goToHomePage() {
    if(this.jwtService.isValidAuthentication() && this.jwtService.defaultUserRoute) {
      this.router.navigate([this.jwtService.defaultUserRoute]);
    } else {
      this.router.navigate(['home']);
    }
  }

}
