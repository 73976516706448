import { Component, OnDestroy, OnInit } from '@angular/core';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    InputTextModule
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.sass'
})
export class UsersComponent implements OnInit, OnDestroy {

  users: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private superAdminService: SuperAdminService
  ) { }

  ngOnInit(): void {
    this.superAdminService.getAllUsers()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.users = response;
        }
      });
  }

  onRowSelectUser(event: TableRowSelectEvent) {
    this.router.navigate([`portal/super-admin/user-details/${event.data.reference}`]);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
