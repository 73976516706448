import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateCompanyDetailsRequest } from '../payloads/requests/updateCompanyDetailsRequest';
import { NewEmployeeRequest } from '../payloads/requests/newEmployeeRequest';
import { environment } from '../../environments/environment';
import { NewCustomerRequest } from '../payloads/requests/newCustomerRequest';
import { CheckCustomerRequest } from '../payloads/requests/checkCustomerRequest';
import { NewLeadRequest } from '../payloads/requests/newLeadRequest';

const API = `${environment.rootApiUrl}/api/admin`;

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient
  ) { }

  getCompanyDetails(): Observable<any> {
    return this.http.get(`${API}/company/details`);
  }

  updateCompanyDetails(request: UpdateCompanyDetailsRequest): Observable<any> {
    return this.http.put(`${API}/company/details`, request);
  }

  uploadLogo(file: FormData): Observable<any> {
    return this.http.post(`${API}/company/upload-logo`, file);
  }

  getAllPayments(): Observable<any> {
    return this.http.get(`${API}/company/payments`);
  }

  getPaymentRequestDetails(technicalId: string): Observable<any> {
    return this.http.get(`${API}/company/payments/details`,
      {
        params: {
          technicalId: technicalId
        }
      }
    );
  }

  getPaymentRequestInvoice(technicalId: string) {
    return this.http.get(`${API}/company/payments/download-invoice`,
      {
        params: {
          technicalId: technicalId
        },
        observe: 'response',
        responseType: 'blob'
      }
    );
  }

  getBridgeBanks(): Observable<any> {
    return this.http.get(`${API}/banks`);
  }

  getAllEmployees(): Observable<any> {
    return this.http.get(`${API}/employees`);
  }

  addEmployee(request: NewEmployeeRequest): Observable<any> {
    return this.http.post(`${API}/employees`, request);
  }

  checkEmployee(email: string): Observable<any> {
    return this.http.get(`${API}/employees/check-employee`,
      {
        params: {
          email: email
        }
      }
    );
  }

  updateRoles(employeeId: number, isAdmin: boolean, isCommercial: boolean): Observable<any> {
    return this.http.put(`${API}/employees/update-roles`, {},
      {
        params: {
          employeeId: employeeId,
          isAdmin: isAdmin,
          isCommercial: isCommercial
        }
      }
    );
  }

  getEmployeeDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/employees/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  getAllCustomers(): Observable<any> {
    return this.http.get(`${API}/customers`);
  }

  addCustomer(request: NewCustomerRequest): Observable<any> {
    return this.http.post(`${API}/customers`, request);
  }

  checkCustomer(request: CheckCustomerRequest, sponsorshipCreationMode: boolean): Observable<any> {
    return this.http.post(`${API}/customers/check-customer`, request,
      {
        params: {
          sponsorshipCreationMode: sponsorshipCreationMode
        }
      }
    );
  }

  getAllCommercials(): Observable<any> {
    return this.http.get(`${API}/commercials`);
  }

  getAllLeads(): Observable<any> {
    return this.http.get(`${API}/leads`);
  }

  createLead(request: NewLeadRequest): Observable<any> {
    return this.http.post(`${API}/leads`, request);
  }

  getLeadDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/leads/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  getAllSponsorships(): Observable<any> {
    return this.http.get(`${API}/sponsorships`);
  }

  getSponsorshipDetails(reference: string): Observable<any> {
    return this.http.get(`${API}/sponsorships/details`,
      {
        params: {
          reference: reference
        }
      }
    );
  }

  getSponsorshipsPendingPayment(): Observable<any> {
    return this.http.get(`${API}/sponsorships/pending-payments`);
  }

  createPayment(sponsorshipsId: number[]): Observable<any> {
    return this.http.post(`${API}/sponsorships/create-payment`, sponsorshipsId);
  }

}
