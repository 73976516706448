import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateProfileRequest } from '../payloads/requests/updateProfileRequest';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UpdatePasswordeRequest } from '../payloads/requests/updatePasswordRequest';
import { ServiceMessageRequest } from '../payloads/requests/serviceMessageRequest';

const API = `${environment.rootApiUrl}/api/user`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  getProfile(): Observable<any> {
    return this.http.get(`${API}/my-profile`);
  }

  updateProfile(request: UpdateProfileRequest): Observable<any> {
    return this.http.put(`${API}/my-profile`, request);
  }

  updatePassword(request: UpdatePasswordeRequest): Observable<any> {
    return this.http.put(`${API}/update-password`, request);
  }

  getAllPatchNote(): Observable<any> {
    return this.http.get(`${API}/patch-notes`);
  }

  agreeVersion(version: string): Observable<any> {
    return this.http.get(`${API}/patch-note`,
      {
        params: {
          version: version
        }
      }
    );
  }

  getAllServiceMessageCategories(): Observable<any> {
    return this.http.get(`${API}/service-message/categories`);
  }

  sendServiceMessage(request: ServiceMessageRequest): Observable<any> {
    return this.http.post(`${API}/service-message`, request);
  }

}
