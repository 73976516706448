import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  private jwtToken: string;
  private decodedToken: { [key: string]: string };

  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isCommercial: boolean = false;
  isCustomer: boolean = false;
  isUser: boolean = false;

  defaultUserRoute: string = "";

  constructor() {
    this.setToken(localStorage.getItem("linkupp_jwtToken") || '')
  }

  setRoles() {
    this.isSuperAdmin = false;
    this.isAdmin = false;
    this.isCommercial = false;
    this.isCustomer = false;
    this.isUser = false;

    if(this.getRoles().includes('ROLE_SUPER_ADMIN')) {
      this.isSuperAdmin = true;
    }
    if(this.getRoles().includes('ROLE_COMPANY_ADMIN')) {
      this.isAdmin = true;
    }
    if(this.getRoles().includes('ROLE_COMPANY_COMMERCIAL')) {
      this.isCommercial = true;
    }
    if(this.getRoles().includes('ROLE_CUSTOMER')) {
      this.isCustomer = true;
    }
    if(this.getRoles().includes('ROLE_USER')) {
      this.isUser = true;
    }
  }

  setDefaultRoute() {
    if(this.isSuperAdmin) {
      this.defaultUserRoute = "portal/super-admin";
    } else if(this.isAdmin) {
      this.defaultUserRoute = "portal/pro/admin";
    } else if(this.isCommercial) {
      this.defaultUserRoute = "portal/pro/commercial";
    } else if(this.isCustomer) {
      this.defaultUserRoute = "portal/customer";
    } else if(this.isUser) {
      this.defaultUserRoute = "portal/my-profile";
    }
  }

  setToken(token: string) {
    if (token) {
      localStorage.setItem("linkupp_jwtToken", token);
      this.jwtToken = token;
      this.setRoles();
      this.setDefaultRoute();
    }
  }

  getToken() {
    return this.jwtToken;
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwtDecode(this.jwtToken);
    }
  }

  getDecodeToken(): any {
    return jwtDecode(this.jwtToken);
  }

  getUser(): string {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['sub'] : '';
  }

  getRoles(): string {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['roles'] : '';
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken['exp'] : null as any;
  }

  isTokenExpired(): boolean {
    const expiryTime: number = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }

  isValidAuthentication(): boolean {
    if (this.getUser() && !this.isTokenExpired()) {
      return true;
    }
    return false;
  }

  clear() {
    this.jwtToken = '';
    this.decodedToken = {};
    this.isSuperAdmin = false;
    this.isAdmin = false;
    this.isCommercial = false;
    this.isCustomer = false;
    this.isUser = false;
    this.defaultUserRoute = '';
    localStorage.removeItem("linkupp_jwtToken");
  }
}
