<div class="block h-screen">
    <div class="flex h-full align-items-center justify-content-center surface-50">
        <div class="surface-100 border-round p-4 xl:w-10 xl:max-w-30rem lg:w-6 md:w-8 w-full mx-5">
            <div class="align-content-center text-center mb-6">
                <span class="font-medium">Mot de passe oublié</span>
            </div>
            <div>
                <p-messages />
                <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPassword()">

                    <div class="flex flex-column gap-1">
                        <label class="text-sm font-medium" for="email">Email</label>
                        <input id="email" type="text" placeholder="Email" pInputText class="w-full mb-3" formControlName="email">
                    </div>

                    <div class="flex mt-4">
                        <p-button type="submit" label="Envoyer" icon="pi pi-send" class="w-full" iconPos="right"
                            [loading]="getLoading()"
                            [style]="{ 'font-weight': '500', width: '100%' }"
                            [disabled]="!forgotPasswordForm.valid || forceDisableSubmit || getLoading()" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>