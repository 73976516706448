import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { UpdateProfileRequest } from '../../../payloads/requests/updateProfileRequest';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { JwtService } from '../../../services/jwt.service';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { AvatarModule } from 'primeng/avatar';
import { UpdatePasswordeRequest } from '../../../payloads/requests/updatePasswordRequest';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    PasswordModule,
    TooltipModule,
    SkeletonModule,
    AvatarModule,
    DividerModule
  ],
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.sass'
})
export class MyProfileComponent implements OnInit, OnDestroy {

  myProfile: any = {};
  isCustomer: boolean = false;

  badRequestUserEmail: string = "";
  badRequestUserPhone: string = "";
  badRequestCurrentPwd: string = "";

  passwordRegex: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+])[A-Za-z\\d!@#$%^&*()_+]{12,20}$';

  updateProfileForm = this.formBuilder.group({
    firstName: [null, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')],
    lastName: [null, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')],
    email: [null, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')],
    phone: [null, Validators.pattern('^[0-9]{10}$')],
    iban: [null, Validators.pattern('^FR\\d{12}[0-9A-Z]{11}\\d{2}$')]
  }, { validators: [this.requireAtLeastOneFilledValidator()] });

  updatePasswordForm: FormGroup;

  private _destroy$ = new Subject<void>();

  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.isCustomer = this.jwtService.isCustomer;
    this.userService.getProfile()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myProfile = response;
        }
      });

    this.updatePasswordForm = this.formBuilder.group({
      currentPwd: [null, Validators.required],
      newPwd: [null, [Validators.required, Validators.pattern(this.passwordRegex)]],
      confirmPwd: [null, [Validators.required, Validators.pattern(this.passwordRegex)]],
    },
      {
        validators: this.confirmPasswordValidator()
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  onSubmitUpdateProfile() {
    this.badRequestUserEmail = "";
    this.badRequestUserPhone = "";
    const request: UpdateProfileRequest = new UpdateProfileRequest(
      // Make sur to return null value if string null or empty
      this.updateProfileForm.get('firstName')?.value ? this.updateProfileForm.get('firstName')?.value! : null,
      this.updateProfileForm.get('lastName')?.value ? this.updateProfileForm.get('lastName')?.value! : null,
      this.updateProfileForm.get('email')?.value ? this.updateProfileForm.get('email')?.value! : null,
      this.updateProfileForm.get('phone')?.value ? this.updateProfileForm.get('phone')?.value! : null,
      this.updateProfileForm.get('iban')?.value ? this.updateProfileForm.get('iban')?.value! : null
    );
    this.userService.updateProfile(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.updateProfileForm.reset();
          this.myProfile = response;
          this.messageService.add({ severity: 'success', detail: 'Profil mis à jour', life: 5000 });
        }, error: error => {
          if (error.status === 400) {
            error.field === "userEmail" ? this.badRequestUserEmail = error.description : this.badRequestUserEmail = "";
            error.field === "userPhone" ? this.badRequestUserPhone = error.description : this.badRequestUserPhone = "";
          } else {
            this.messageService.clear();
            this.messageService.add({ severity: 'error', detail: error.description });
          }
        }
      });
  }

  onSubmitUpdatePassword() {
    this.badRequestCurrentPwd = "";
    const request: UpdatePasswordeRequest = new UpdatePasswordeRequest(
      this.updatePasswordForm.get('currentPwd')?.value!,
      this.updatePasswordForm.get('newPwd')?.value!
    );
    this.userService.updatePassword(request)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.updatePasswordForm.reset();
        this.myProfile = response;
        this.messageService.add({ severity: 'success', detail: 'Mot de passe mis à jour', life: 5000 });
      }, error: error => {
        error.status === 400 && error.field === "currentPwd" ? this.badRequestCurrentPwd = error.description : this.badRequestCurrentPwd = "";
      }
    })
  }

  requireAtLeastOneFilledValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasFilledControls = Object.values(control.value).some(
        (value: any) => value !== null && value !== undefined && value !== ''
      );

      return hasFilledControls ? null : { requireAtLeastOneFilled: true };
    };
  }

  confirmPasswordValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const password = formGroup.get('newPwd')?.value;
      const confirmPassword = formGroup.get('confirmPwd')?.value;

      if (password !== confirmPassword) {
        return { passwordMismatch: true };
      }

      return null;
    };
  }

  pwdLowerCase() {
    const password = this.updatePasswordForm.controls['newPwd'].value;
    const lower = /[a-z]/.test(String(password));
    if (password && lower) {
      return true;
    }
    return false;
  }

  pwdUpperCase() {
    const password = this.updatePasswordForm.controls['newPwd'].value;
    const upper = /[A-Z]/.test(String(password));
    if (password && upper) {
      return true;
    }
    return false;
  }

  pwdDigit() {
    const password = this.updatePasswordForm.controls['newPwd'].value;
    const digit = /[\d]/.test(String(password));
    if (password && digit) {
      return true;
    }
    return false;
  }

  pwdSpecialChar() {
    const password = this.updatePasswordForm.controls['newPwd'].value;
    const special = /[!@#$%^&*()_+]/.test(String(password));
    if (password && special) {
      return true;
    }
    return false;
  }

  pwdLength() {
    const password = this.updatePasswordForm.controls['newPwd'].value;
    const length = /^.{12,20}$/.test(String(password));
    if (password && length) {
      return true;
    }
    return false;
  }

  myProfileIsSet(): boolean {
    if (!Object.keys(this.myProfile).length) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
