import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { Subject, takeUntil } from 'rxjs';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { Router, RouterModule } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { HttpResponse } from '@angular/common/http';
import { LoadingService } from '../../../../../services/loading.service';

@Component({
  selector: 'app-payments-history',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    InputTextModule,
    RouterModule,
    TagModule,
    ButtonModule
  ],
  templateUrl: './payments-history.component.html',
  styleUrl: './payments-history.component.sass'
})
export class PaymentsHistoryComponent implements OnInit, OnDestroy {

  payments: any[] = [];

  private _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.adminService.getAllPayments()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.payments = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      })
  }

  getLoading() {
    return this.loadingService.get();
  }


  onRowSelectPayment(event: TableRowSelectEvent) {
    const paymentId = event.data.technicalId;
    this.router.navigate([`portal/pro/admin/payment-details/${paymentId}`]);
  }

  getTagBackgroundColor(status: string): string {
    switch (status) {
      case 'CREA':
        return "#2196F3";
      case 'ACTC':
        return "#2196F3";
      case 'PDNG':
        return "#fbc02d";
      case 'ACSC':
        return "#689f38";
      case 'RJCT':
        return "#d32f2f";
      default:
        return "#2196F3";
    }
  }

  onClickDownloadInvoice(technicalId: string) {
    this.adminService.getPaymentRequestInvoice(technicalId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          if(response.body && response.headers) {
            var fileName: string = response.headers.get('Content-Disposition')!;
            fileName = fileName.split("=")[1];

            const url = window.URL.createObjectURL(response.body);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        }, error: error => {
          if (error.description) {
            this.messageService.add({ severity: 'error', detail: error.description });
          } else {
            this.messageService.add({ severity: 'error', detail: 'Une erreur est survenue lors du téléchargement du fichier' });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
