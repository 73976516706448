export class ServiceMessageRequest {

  categoryId: number | null;
  subject: string | null;
  message: string | null;

  constructor(categoryId: number | null, subject: string | null, message: string | null) {
    this.categoryId = categoryId;
    this.subject = subject;
    this.message = message;
  }

}