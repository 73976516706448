<div class="block h-screen">
    <div class="flex h-full align-items-center justify-content-center surface-50">
        <div class="surface-100 border-round p-4 xl:w-10 xl:max-w-30rem lg:w-6 md:w-8 w-full mx-5">
            <div class="align-content-center text-center mb-6">
                <span>Bienvenue sur</span>
                <span class="font-bold text-indigo-500">&nbsp;Linkupp</span>
            </div>
            <div>
                <p-messages />
                <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">

                    <div class="flex flex-column gap-1">
                        <label class="text-sm font-medium" for="username">Nom d'utilisateur ou email</label>
                        <input id="username" type="text" placeholder="Nom d'utilisateur ou email" pInputText class="w-full mb-3" formControlName="usernameOrEmail">
                    </div>

                    <div class="flex flex-column gap-1">
                        <label class="text-sm font-medium" for="password">Mot de passe</label>
                        <p-password id="password" formControlName="password" class="w-full" [feedback]="false"
                        placeholder="Mot de passe" styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                        [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [toggleMask]="true"></p-password>
                    </div>

                    <div class="flex align-items-center justify-content-end mt-2">
                        <a [routerLink]="['/forgot-password']" class="no-underline text-sm text-gray-600 text-right cursor-pointer">Mot de passe oublié ?</a>
                    </div>

                    <div class="flex mt-4">
                        <p-button type="submit" label="Connexion" icon="pi pi-sign-in" class="w-full" iconPos="right"
                            [loading]="getLoading()"
                            [style]="{ 'font-weight': '500', width: '100%' }"
                            [disabled]="!loginForm.valid || getLoading()" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>