<div class="text-lg font-bold text-900 mb-3 p-2">LEADS</div>

<div class="flex justify-content-between mb-3 p-2" *ngIf="leads.length === 0">
    <div class="text-xl font-medium text-900">Aucun lead trouvé...</div>
    <p-button label="Créer" icon="pi pi-plus" *ngIf="activeUser === 'admin'" [text]="true" (onClick)="showNewLeadDialog()" />
</div>

<p-table #dt1 [value]="leads" selectionMode="single" (onRowSelect)="onRowSelectLead($event)"
    [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'" sortField="createdAt"
    [sortOrder]="-1"
    [globalFilterFields]="['createdAt', 'reference', 'leadCustomer.lastName', 'leadCustomer.firstName', 'source', 'city', 'status.label']"
    [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="leads.length > 0">

    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-end gap-2">
            <p-button label="Créer" icon="pi pi-plus" *ngIf="activeUser === 'admin'" [text]="true" (onClick)="showNewLeadDialog()" />
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                    placeholder="Chercher..." />
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="createdAt">Date de création<p-sortIcon field="createdAt" /></th>
            <th pSortableColumn="reference">Référence<p-sortIcon field="reference" /></th>
            <th pSortableColumn="leadCustomer.lastName">Lead<p-sortIcon field="leadCustomer.lastName" /></th>
            <th pSortableColumn="city">Ville<p-sortIcon field="city" /></th>
            <th pSortableColumn="source">Source<p-sortIcon field="source" /></th>
            <th pSortableColumn="status.label">Statut<p-sortIcon field="status.label" /></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-lead>
        <tr [pSelectableRow]="lead">
            <td>
                <span class="md:hidden font-medium">Date:&nbsp;</span>
                <span>{{ lead.createdAt | date: 'medium' }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Référence:&nbsp;</span>
                <span>{{ lead.reference }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Lead:&nbsp;</span>
                <span>{{ lead.leadCustomer.lastName }} {{ lead.leadCustomer.firstName }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Ville:&nbsp;</span>
                <span>{{ lead.city }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Source:&nbsp;</span>
                <span>{{ lead.source }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Statut:&nbsp;</span>
                <span>
                    <p-tag [style]="{ 'background-color': getTagBackgroundColor(lead.status.name) }" [value]="lead.status.label" [rounded]="true" />
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>