import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { JwtService } from '../../services/jwt.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Subject, takeUntil } from 'rxjs';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { StyleClassModule } from 'primeng/styleclass';
import { MessagesModule } from 'primeng/messages';
import { version } from '../../../../package.json';
import { UserService } from '../../services/user.service';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { LoadingService } from '../../services/loading.service';
import { TooltipModule } from 'primeng/tooltip';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ServiceMessageRequest } from '../../payloads/requests/serviceMessageRequest';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-portal',
  standalone: true,
  imports: [
    StyleClassModule,
    CommonModule,
    SidebarModule,
    ButtonModule,
    AvatarModule,
    RouterModule,
    MessagesModule,
    DialogModule,
    DividerModule,
    TooltipModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    InputTextareaModule
  ],
  templateUrl: './portal.component.html',
  styleUrl: './portal.component.sass'
})
export class PortalComponent implements OnInit, OnDestroy {

  version: string = version;

  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  sidebarVisible: boolean = false;

  expandAdminMenuPayments: boolean = false;
  expandAdminMenuHumanResources: boolean = false;
  expandAdminMenuBusinessResources: boolean = false;

  interval: any;

  authenticatedUsername: string = '';
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isCommercial: boolean = false;
  isCustomer: boolean = false;

  patchNotes: any[] = [];
  showPatchNotes: boolean = false;
  dialogPatchNotesHeader: string = "";

  serviceMessageCategories: any[] = [];
  showServiceMessage: boolean = false;

  serviceMessageForm = this.formBuilder.group({
    categoryId: [null, Validators.required],
    subject: [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')])],
    message: [null, Validators.compose([Validators.required, Validators.pattern('^.{15,255}$')])]
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private jwtService: JwtService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.timerCheckJwtValidity();

    this.authenticatedUsername = this.getAuthenticatedUserUsername();

    this.isSuperAdmin = this.jwtService.isSuperAdmin;
    this.isAdmin = this.jwtService.isAdmin;
    this.isCommercial = this.jwtService.isCommercial;
    this.isCustomer = this.jwtService.isCustomer;

    this.userService.agreeVersion(version)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          if (response) {
            this.dialogPatchNotesHeader = "Nouvelle version";
            this.patchNotes.push(response);
            this.showPatchNotes = true;
          }
        }
      })
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  closeCallback(e: any): void {
    this.sidebarRef.close(e);
  }

  onClickPatchNote() {
    this.userService.getAllPatchNote()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.dialogPatchNotesHeader = "Historique des versions";
          this.patchNotes = response;
          this.showPatchNotes = true;
        }
      });
  }

  getAuthenticatedUserRoles(): string {
    if (this.jwtService.isValidAuthentication()) {
      return this.jwtService.getRoles();
    }
    return '';
  }

  getAuthenticatedUserUsername(): string {
    if (this.jwtService.isValidAuthentication()) {
      return this.jwtService.getUser();
    }
    return '';
  }

  logOut() {
    this.authenticationService.logout(this.jwtService.getToken())
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.jwtService.clear();
          this.router.navigate(['home']);
        }
      });
  }

  myProfile() {
    this.router.navigate(['portal/my-profile']);
  }

  showSidebarEvent(elem?: string) {
    this.expandAdminMenuPayments = false;
    this.expandAdminMenuHumanResources = false;
    this.expandAdminMenuBusinessResources = false;

    this.sidebarVisible = true;

    switch (elem) {
      case "expandAdminMenuPayments":
        this.expandAdminMenuPayments = true;
        break;
      case "expandAdminMenuHumanResources":
        this.expandAdminMenuHumanResources = true;
        break;
      case "expandAdminMenuBusinessResources":
        this.expandAdminMenuBusinessResources = true;
        break;
    }
  }

  timerCheckJwtValidity() {
    let timeLeft = 10;
    this.interval = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
      } else {
        this.clearTimer();
        if (this.jwtService.isTokenExpired()) {
          this.jwtService.clear();
          this.router.navigate(['login'], { queryParams: { error: "The JWT token has expired" } });
        } else {
          this.timerCheckJwtValidity();
        }
      }
    }, 1000);
  }

  clearTimer(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  goToHomePage() {
    this.router.navigate(['home']);
  }

  onClickServiceMessage() {
    this.userService.getAllServiceMessageCategories()
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.serviceMessageCategories = response;
        this.serviceMessageForm.reset();
        this.showServiceMessage = true;
      }
    });
  }

  onSubmitServiceMessage() {
    const request: ServiceMessageRequest = new ServiceMessageRequest(
      +this.serviceMessageForm.get('categoryId')?.value!,
      this.serviceMessageForm.get('subject')?.value!,
      this.serviceMessageForm.get('message')?.value!
    );
    this.userService.sendServiceMessage(request)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.showServiceMessage = false;
        this.messageService.add({ severity: 'success', detail: 'Votre demande sollicitation a bien été envoyée à notre service', life: 5000 });
      }
    })
  }

  closeServiceMessageDialog() {
    this.showServiceMessage = false;
  }

  ngOnDestroy(): void {
    this.clearTimer();
    this._destroy$.next();
  }

}
