<div class="mt-5 m-auto align-content-center p-4 surface-card shadow-2 border-round max-w-30rem"
    *ngIf="!userDetailsIsSet() || getLoading()">
    <div class="p-2 surface-card">
        <div class="flex mb-3">
            <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
            <div>
                <p-skeleton width="10rem" styleClass="mb-2" />
                <p-skeleton width="5rem" styleClass="mb-2" />
                <p-skeleton height=".5rem" />
            </div>
        </div>
        <p-skeleton width="100%" height="150px" />
        <div class="flex justify-content-between mt-3">
            <p-skeleton width="4rem" height="2rem" />
            <p-skeleton width="4rem" height="2rem" />
        </div>
    </div>
</div>

<div *ngIf="userDetailsIsSet()" class="mb-5">
    <div class="mt-5 m-auto align-content-center p-4 surface-card shadow-2 border-round max-w-30rem">
        <div class="flex justify-content-center my-5">
            <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle" />
        </div>
        <form>
            <div class="flex flex-column gap-2">
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="username">Nom d'utilisateur</label>
                    <input pInputText id="username" aria-describedby="username-help" [disabled]="true"
                        [placeholder]="userDetails.username" />
                </div>
                <div class="flex flex-column sm:flex-row md:justify-content-between gap-2">
                    <div class="flex flex-column gap-1 flex-grow-1">
                        <label class="text-xs font-medium" for="firstName">Prénom</label>
                        <input pInputText id="firstName" aria-describedby="firstName-help"
                            [placeholder]="userDetails.firstName" [disabled]="true" />
                    </div>
                    <div class="flex flex-column gap-1 flex-grow-1">
                        <label class="text-xs font-medium" for="lastName">Nom</label>
                        <input pInputText id="lastName" aria-describedby="lastName-help"
                            [placeholder]="userDetails.lastName" [disabled]="true" />
                    </div>
                </div>
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="email">Email</label>
                    <input pInputText id="email" aria-describedby="email-help" [placeholder]="userDetails.email" [disabled]="true" />
                </div>
                <div class="flex flex-column gap-1">
                    <label class="text-xs font-medium" for="phone">Téléphone</label>
                    <input pInputText id="phone" aria-describedby="phone-help" [placeholder]="userDetails.phone" [disabled]="true" />
                </div>
                <div class="flex flex-column gap-1" *ngIf="userDetails.iban">
                    <label class="text-xs font-medium" for="iban">Iban</label>
                    <input pInputText id="iban" aria-describedby="iban-help" [placeholder]="userDetails.iban" [disabled]="true" />
                </div>
                <div class="flex flex-column gap-1 mt-5">
                    <label class="text-xs font-medium">Rôles</label>
                    <ul class="mt-0">
                        <li *ngFor="let role of userDetails.roles">
                            {{ role.label }}
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between mt-5">
                    <div class="flex flex-column gap-1 flex-1 align-items-center justify-content-center">
                        <label class="text-xs font-medium" for="isEnabled">Compte activé</label>
                        <p-inputSwitch id="isEnabled" name="isEnabled" [(ngModel)]="isEnabled" />
                    </div>
    
                    <div class="flex flex-column gap-1 flex-1 align-items-center justify-content-center ">
                        <label class="text-xs font-medium" for="isAccountNonLocked">Compte débloqué</label>
                        <p-inputSwitch id="isAccountNonLocked" name="isAccountNonLocked" [(ngModel)]="isAccountNonLocked" />
                    </div>
                </div>
                <div class="flex mt-3">
                    <p-button label="Enregistrer" icon="pi pi-save" class="w-full" (onClick)="onSubmitUpdateAccountSettings()"
                        [style]="{ 'font-weight': '500', width: '100%' }" [loading]="getLoading()"
                        [disabled]="!accountSettingsChanged() || getLoading()" />
                </div>
            </div>
        </form>
    </div>
</div>