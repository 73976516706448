import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { CommercialService } from '../../../../../services/commercial.service';
import { LeadDetailsComponent as ReusableLeadDetails } from '../../../../../reusable-components/lead-details/lead-details.component';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../../../../services/loading.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-lead-details-commercial',
  standalone: true,
  imports: [
    ReusableLeadDetails,
    ButtonModule,
    RadioButtonModule,
    FormsModule,
    DialogModule,
    CommonModule
  ],
  templateUrl: './lead-details.component.html',
  styleUrl: './lead-details.component.sass'
})
export class LeadDetailsComponent implements OnInit, OnDestroy {

  leadDetails: any = {};

  showChangeStatusDialog: boolean = false;

  processingStatus: any[] = [];
  initialSelectedStatus: number = 0;
  selectedStatus: number = 0;

  private _destroy$ = new Subject<void>();

  constructor(
    private loadingService: LoadingService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private commercialService: CommercialService
  ) { }

  ngOnInit(): void {
    this.commercialService.getLeadDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadDetails = response;
          // To prevent submit same status
          this.initialSelectedStatus = response.status.id;
          this.selectedStatus = this.initialSelectedStatus;
        }
      });

    this.commercialService.getProcessingStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.processingStatus = response;
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  addNote(event: any) {
    this.commercialService.addNoteToLead(this.leadDetails.id, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadDetails.events.unshift(response);
        }
      });
  }

  updateStatusDialog() {
    this.showChangeStatusDialog = true;
  }

  rejectUpdateStatus() {
    this.showChangeStatusDialog = false;
  }

  acceptUpdateStatus() {
    this.commercialService.updateLeadStatus(this.leadDetails.id, this.selectedStatus)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.showChangeStatusDialog = false;
          this.leadDetails = response;
          // To prevent submit same status
          this.initialSelectedStatus = response.status.id;
          this.selectedStatus = this.initialSelectedStatus;

          this.messageService.add({ severity: 'success', detail: 'Statut mis à jour', life: 5000 });
        }
      });
  }

  identifyStatus(index: number) {
    return index
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
