<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="p-2 surface-card" *ngIf="!paymentDetailsIsSet() || getLoading()">
            <div class="flex mb-3">
                <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                <div>
                    <p-skeleton width="10rem" styleClass="mb-2" />
                    <p-skeleton width="5rem" styleClass="mb-2" />
                    <p-skeleton height=".5rem" />
                </div>
            </div>
            <p-skeleton width="100%" height="150px" />
            <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
            </div>
        </div>

        <div *ngIf="paymentDetailsIsSet()">
            <div class="flex flex-column p-2 mb-3 text-sm text-500 font-medium">
                Bridge paiement ID: {{ paymentDetails.id }}
            </div>

            <div class="flex flex-column p-2 mb-5 text-sm text-500 font-medium gap-1">
                <span>Création: {{ paymentDetails.created_at | date: 'medium' }}</span>
                <span>Mise à jour: {{ paymentDetails.updated_at | date: 'medium' }}</span>
            </div>

            <div class="flex flex-column p-2 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    STATUT
                </div>
                <div class="flex gap-1">
                    <p-tag [style]="{ 'background-color': getTagBackgroundColor(paymentDetails.status) }"
                        [value]="paymentDetails.status" [rounded]="true"
                        [pTooltip]="setStatusTagTooltipDesc(paymentDetails.status)" />
                    <p-tag severity="danger" [value]="paymentDetails.status_reason" [rounded]="true"
                        *ngIf="paymentDetails.status_reason"
                        [pTooltip]="setStatusReasonTagTooltipDesc(paymentDetails.status_reason)" />
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    EMETTEUR
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-user"></i>
                    </span>
                    <span>
                        {{ paymentDetails.user?.company_name }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span pTooltip="Référence externe">
                        <i class="pi pi-link"></i>
                    </span>
                    <span>
                        {{ paymentDetails.user?.external_reference }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span pTooltip="Débiteur">
                        <i class="pi pi-credit-card"></i>
                    </span>
                    <span>
                        {{ paymentDetails.sender?.name }} {{ paymentDetails.sender?.iban }}
                    </span>
                </div>
            </div>

            <div class="flex flex-column p-2 gap-2">
                <div class="flex font-bold mb-2">
                    TRANSACTIONS
                </div>
                <p-accordion [multiple]="true">
                    <p-accordionTab
                        *ngFor="let transaction of paymentDetails.transactions; trackBy:identifyTransaction">
                        <ng-template pTemplate="header">
                            <span class="flex align-items-center w-full">
                                <span class="font-medium">
                                    {{ transaction.id }}
                                </span>
                                <p-badge value="!" class="ml-auto mr-2" severity="danger"
                                    [hidden]="hideErrorBadge(transaction.status)" pTooltip="Erreur de paiement"
                                    tooltipPosition="left" />
                            </span>
                        </ng-template>

                        <div class="flex flex-column p-2 mb-3 text-sm text-500 font-medium">
                            Bridge transaction ID: {{ transaction.id }}
                        </div>

                        <div class="flex flex-column p-2 mb-5 gap-2">
                            <div class="flex font-bold mb-2">
                                STATUT
                            </div>
                            <div class="flex">
                                <div class="flex gap-1">
                                    <p-tag [style]="{ 'background-color': getTagBackgroundColor(transaction.status) }"
                                        [value]="transaction.status" [rounded]="true"
                                        [pTooltip]="setStatusTagTooltipDesc(transaction.status)" />
                                    <p-tag severity="danger" [value]="transaction.status_reason" [rounded]="true"
                                        *ngIf="transaction.status_reason"
                                        [pTooltip]="setStatusReasonTagTooltipDesc(transaction.status_reason)" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-column p-2 mb-5 gap-2">
                            <div class="flex font-bold mb-2">
                                BENEFICIAIRE
                            </div>
                            <div class="flex gap-2">
                                <span>
                                    <i class="pi pi-user"></i>
                                </span>
                                <span>
                                    {{ transaction.beneficiary.last_name }} {{ transaction.beneficiary.first_name }}
                                </span>
                            </div>
                            <div class="flex gap-2">
                                <span pTooltip="Référence client">
                                    <i class="pi pi-link"></i>
                                </span>
                                <span>
                                    {{ transaction.client_reference }}
                                </span>
                            </div>
                            <div class="flex gap-2">
                                <span>
                                    <i class="pi pi-credit-card"></i>
                                </span>
                                <span>
                                    {{ transaction.beneficiary.iban }}
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-column p-2 mb-5 gap-2">
                            <div class="flex font-bold mb-2">
                                DETAILS
                            </div>
                            <div class="flex gap-2">
                                <span pTooltip="Date d'exécution">
                                    <i class="pi pi-calendar"></i>
                                </span>
                                <span>
                                    {{ transaction.execution_date | date: 'medium' }}
                                </span>
                            </div>
                            <div class="flex gap-2">
                                <span pTooltip="End to end ID">
                                    <i class="pi pi-link"></i>
                                </span>
                                <span>
                                    <a target="_blank"
                                        [routerLink]="['/portal/pro/admin/sponsorship-details', transaction.end_to_end_id]">
                                        {{ transaction.end_to_end_id }}&nbsp;<i class="pi pi-external-link"
                                            style="font-size: 0.6rem"></i>
                                    </a>
                                </span>
                            </div>
                            <div class="flex gap-2">
                                <span>
                                    <i class="pi pi-wallet"></i>
                                </span>
                                <span>
                                    {{ transaction.amount }} €
                                </span>
                            </div>
                            <div class="flex gap-2">
                                <span pTooltip="Libellé">
                                    <i class="pi pi-tag"></i>
                                </span>
                                <span>
                                    {{ transaction.label }}
                                </span>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>

    </div>
</div>