import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from '../../../../../services/admin.service';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { LoadingService } from '../../../../../services/loading.service';

@Component({
  selector: 'app-employee-details',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    ButtonModule,
    TooltipModule,
    SkeletonModule,
    ButtonModule
  ],
  templateUrl: './employee-details.component.html',
  styleUrl: './employee-details.component.sass'
})
export class EmployeeDetailsComponent implements OnInit, OnDestroy {

  employeeDetails: any = {};

  initialIsCommercial: boolean = false;
  initialIsAdmin: boolean = false;

  isCommercial: boolean = false;
  isAdmin: boolean = false;

  hideActionRoles: boolean = true;

  private _destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.adminService.getEmployeeDetails(this.activatedRoute.snapshot.paramMap.get('reference')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.employeeDetails = response;
          this.setCheckbox(this.employeeDetails.roles);
        }
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  setCheckbox(roles: any[]) {
    this.initialIsAdmin = false;
    this.initialIsCommercial = false;
    this.isAdmin = false;
    this.isCommercial = false;

    roles.forEach((role: any) => {
      if (role.name === 'ROLE_COMPANY_ADMIN') {
        this.initialIsAdmin = true;
        this.isAdmin = true;
      } else if (role.name === 'ROLE_COMPANY_COMMERCIAL') {
        this.initialIsCommercial = true;
        this.isCommercial = true;
      }
    });
  }

  disableIsAdminCheckbox() {
    if (!this.isCommercial) {
      return true;
    }

    return false;
  }

  disableIsCommercialCheckbox() {
    if (!this.isAdmin) {
      return true;
    }

    return false;
  }

  onChangeCheckbox() {
    if ((this.isAdmin === this.initialIsAdmin) && (this.isCommercial === this.initialIsCommercial)) {
      this.hideActionRoles = true;
    } else {
      this.hideActionRoles = false;
    }
  }

  updateRoles() {
    this.adminService.updateRoles(this.employeeDetails.id, this.isAdmin, this.isCommercial)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.hideActionRoles = true;
          this.setCheckbox(response.roles);
          this.messageService.addAll([{ severity: 'success', detail: 'Rôles mis à jour', life: 5000 }, { severity: 'warn', detail: "Si vous avez mis à jour vos propres rôles, déconnectez et re connectez vous afin de mettre à jour votre portail", life: 5000 }]);
        }
      });
  }

  revertRoles() {
    this.hideActionRoles = true;
    this.isAdmin = this.initialIsAdmin;
    this.isCommercial = this.initialIsCommercial;
  }

  identifyRole(index: number) {
    return index;
  }

  employeeDetailsIsSet(): boolean {
    if(!Object.keys(this.employeeDetails).length) {
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
