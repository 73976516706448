import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-sponsorships',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    TooltipModule
  ],
  templateUrl: './sponsorships.component.html',
  styleUrl: './sponsorships.component.sass'
})
export class SponsorshipsComponent {

  @Input() sponsorships: any[] = [];
  @Input() activeUser: string = "";

  @Output() newSponsorshipEmitter = new EventEmitter<any>();

  constructor(
    private router: Router
  ) { }

  onRowSelectSponsorship(event: TableRowSelectEvent) {
    if (this.activeUser === 'admin') {
      this.router.navigate([`portal/pro/admin/sponsorship-details/${event.data.reference}`]);
    } else if (this.activeUser === 'commercial') {
      this.router.navigate([`portal/pro/commercial/sponsorship-details/${event.data.reference}`]);
    } else if (this.activeUser === 'customer') {
      this.router.navigate([`portal/customer/sponsorship-details/${event.data.reference}`]);
    }
  }

  getTagBackgroundColor(status: string): string {
    switch (status) {
      case 'STATUS_COMPLETED':
        return "#689f38";
      case 'STATUS_CANCELED':
        return "#d32f2f";
      case 'CREA':
        return "#2196F3";
      case 'ACTC':
        return "#2196F3";
      case 'PDNG':
        return "#fbc02d";
      case 'ACSC':
        return "#689f38";
      case 'RJCT':
        return "#d32f2f";
      default:
        return "#2196F3";
    }
  }

  showNewSponsorshipDialog() {
    this.newSponsorshipEmitter.emit();
  }

}
