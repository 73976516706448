import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MyProfileComponent } from './components/portal/my-profile/my-profile.component';
import { authCanActivateGuard } from './auth-can-activate.guard';
import { PortalComponent } from './components/portal/portal.component';
import { EmployeesComponent } from './components/portal/pro/admin/employees/employees.component';
import { CustomerComponent } from './components/portal/customer/customer.component';
import { ProComponent } from './components/portal/pro/pro.component';
import { AdminComponent } from './components/portal/pro/admin/admin.component';
import { CommercialComponent } from './components/portal/pro/commercial/commercial.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CompaniesComponent as CompaniesComponentCustomer } from './components/portal/customer/companies/companies.component';
import { UnavailableComponent } from './components/unavailable/unavailable.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { CustomersComponent as CustomersComponentAdmin } from './components/portal/pro/admin/customers/customers.component';
import { SuperAdminComponent } from './components/portal/super-admin/super-admin.component';
import { CompaniesComponent as CompaniesComponentSuperAdmin } from './components/portal/super-admin/companies/companies.component';
import { CompanyDetailsComponent as CompanyDetailsComponentAdmin } from './components/portal/pro/admin/company-details/company-details.component';
import { CompanyDetailsComponent as CompanyDetailsComponentSuperAdmin } from './components/portal/super-admin/company-details/company-details.component';
import { EmployeeDetailsComponent } from './components/portal/pro/admin/employee-details/employee-details.component';
import { SponsorshipDetailsComponent as SponsorshipDetailsComponentCustomer } from './components/portal/customer/sponsorship-details/sponsorship-details.component';
import { SponsorshipDetailsComponent as SponsorshipDetailsComponentCommercial } from './components/portal/pro/commercial/sponsorship-details/sponsorship-details.component';
import { SponsorshipsComponent as SponsorshipsComponentCustomer } from './components/portal/customer/sponsorships/sponsorships.component';
import { SponsorshipsComponent as SponsorshipsComponentCommercial } from './components/portal/pro/commercial/sponsorships/sponsorships.component';
import { SponsorshipsComponent as SponsorshipsComponentAdmin } from './components/portal/pro/admin/sponsorships/sponsorships.component';
import { CustomersComponent as CustomersComponentCommercial } from './components/portal/pro/commercial/customers/customers.component';
import { SponsorshipDetailsComponent as SponsorshipDetailsComponentAdmin } from './components/portal/pro/admin/sponsorship-details/sponsorship-details.component';
import { LeadsComponent as LeadsComponentAdmin } from './components/portal/pro/admin/leads/leads.component';
import { LeadDetailsComponent as LeadDetailsComponentAdmin } from './components/portal/pro/admin/lead-details/lead-details.component';
import { LeadsComponent as LeadsComponentCommercial } from './components/portal/pro/commercial/leads/leads.component';
import { LeadDetailsComponent as LeadDetailsComponentCommercial } from './components/portal/pro/commercial/lead-details/lead-details.component';
import { PendingPaymentsComponent } from './components/portal/pro/admin/pending-payments/pending-payments.component';
import { PaymentsHistoryComponent } from './components/portal/pro/admin/payments-history/payments-history.component';
import { PaymentDetailsComponent } from './components/portal/pro/admin/payment-details/payment-details.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    title: 'Accueil'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
    canActivate: [authCanActivateGuard]
  },
  {
    path: 'portal',
    component: PortalComponent,
    title: 'Portail',
    canActivate: [authCanActivateGuard],
    children: [
      {
        path: 'my-profile',
        component: MyProfileComponent,
        title: 'Mon profil',
        canActivate: [authCanActivateGuard]
      },
      {
        path: 'super-admin',
        component: SuperAdminComponent,
        title: 'Super admin',
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'companies',
            component: CompaniesComponentSuperAdmin,
            title: 'Partenaires'
          },
          {
            path: 'company-details/:reference',
            component: CompanyDetailsComponentSuperAdmin,
            title: 'Détails entreprise'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/super-admin/companies'
          }
        ]
      },
      {
        path: 'customer',
        component: CustomerComponent,
        title: 'Client',
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'sponsorship-details/:reference',
            component: SponsorshipDetailsComponentCustomer,
            title: 'Détails parrainage'
          },
          {
            path: 'sponsorships',
            component: SponsorshipsComponentCustomer,
            title: 'Mes parrainages'
          },
          {
            path: 'companies',
            component: CompaniesComponentCustomer,
            title: 'Mes entreprises'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/customer/sponsorships'
          }
        ]
      },
      {
        path: 'pro',
        component: ProComponent,
        title: 'Pro',
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'admin',
            component: AdminComponent,
            title: 'Admin',
            canActivate: [authCanActivateGuard],
            children: [
              {
                path: 'company-details',
                component: CompanyDetailsComponentAdmin,
                title: 'Données entreprise'
              },
              {
                path: 'employees',
                component: EmployeesComponent,
                title: 'Collaborateurs'
              },
              {
                path: 'customers',
                component: CustomersComponentAdmin,
                title: 'Portefeuille client'
              },
              {
                path: 'employee-details/:id',
                component: EmployeeDetailsComponent,
                title: 'Détails employée'
              },
              {
                path: 'sponsorships',
                component: SponsorshipsComponentAdmin,
                title: 'Parrainages entreprise'
              },
              {
                path: 'sponsorship-details/:reference',
                component: SponsorshipDetailsComponentAdmin,
                title: 'Détails parrainage'
              },
              {
                path: 'pending-payments',
                component: PendingPaymentsComponent,
                title: 'Parrainages en attente de paiement'
              },
              {
                path: 'payments-history',
                component: PaymentsHistoryComponent,
                title: 'Historique des paiements'
              },
              {
                path: 'payment-details/:id',
                component: PaymentDetailsComponent,
                title: 'Détails paiement'
              },
              {
                path: 'leads',
                component: LeadsComponentAdmin,
                title: 'Leads entreprise'
              },
              {
                path: 'lead-details/:reference',
                component: LeadDetailsComponentAdmin,
                title: 'Détails lead'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/portal/pro/admin/company-details'
              }
            ]
          },
          {
            path: 'commercial',
            component: CommercialComponent,
            title: 'Commercial',
            canActivate: [authCanActivateGuard],
            children: [
              {
                path: 'sponsorship-details/:reference',
                component: SponsorshipDetailsComponentCommercial,
                title: 'Détails parrainage'
              },
              {
                path: 'sponsorships',
                component: SponsorshipsComponentCommercial,
                title: 'Mes parrainages'
              },
              {
                path: 'customers',
                component: CustomersComponentCommercial,
                title: 'Mes clients'
              },
              {
                path: 'leads',
                component: LeadsComponentCommercial,
                title: 'Mes leads'
              },
              {
                path: 'lead-details/:reference',
                component: LeadDetailsComponentCommercial,
                title: 'Détails lead'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/portal/pro/commercial/sponsorships'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    title: 'Not found'
  },
  {
    path: 'unavailable',
    component: UnavailableComponent,
    title: 'Unavailable'
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    title: 'Forbidden'
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];
