import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { LoadingService } from '../services/loading.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private jwtService: JwtService,
    private router: Router,
    private loadingService: LoadingService,
    private messageSevice: MessageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.totalRequests++;
    this.loadingService.show();
    this.messageSevice.clear();

    // Do not set Authorization header on login & public paths
    if (!request.url.includes("/api/auth/login") && !request.url.includes("/api/public")) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.jwtService.getToken()}`
        }
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loadingService.hide();
        }
      }),
      catchError(err => {
        const error = err.error;

        if (error.status === 401) { // Full authentication required
          this.jwtService.clear();
          this.router.navigate(['login'])
        } else if (error.status === 403 && error.description.includes("JWT")) { // JWT errors
          this.jwtService.clear();
          this.router.navigate(['login'], { queryParams: { error: error.description } });
        } else if (error.status != 400) { // Manage bad request directly into components
          this.messageSevice.add({ severity: 'error', detail: error.description ? error.description : error.detail });
        }

        return throwError(() => error);
      }));
  }
}

export const httpInterceptorProviders = [
  provideHttpClient(
    withInterceptorsFromDi()
  ),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtTokenInterceptor,
    multi: true
  }
]