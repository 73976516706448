import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { LoadingService } from '../../services/loading.service';
import { DividerModule } from 'primeng/divider';
import { version } from '../../../../package.json';
import { DialogModule } from 'primeng/dialog';
import { JwtService } from '../../services/jwt.service';
import { Router } from '@angular/router';
import { PublicService } from '../../services/public.service';
import { BookDemoRequest } from '../../payloads/requests/bookDemoRequest';
import { Subject, takeUntil } from 'rxjs';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    AvatarModule,
    InputTextModule,
    DividerModule,
    DialogModule,
    MessagesModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.sass'
})
export class HomeComponent implements OnDestroy {

  version: string = version;

  visibleLegalNotices: boolean = false;
  visibleLicenseAgreement: boolean = false;

  teams: any[] = [
    {
      name: "Alrik Schlauder", function: "Fondateur & Commercial", description: "Fondateur de Linkupp, Alrik pilote le développement commercial, assurant les rendez-vous, les ventes et les relations avec les partenaires."
    },
    {
      name: "Jérémie Mankouri", function: "Ventes & Réseau", description: "En charge des ventes et de la stratégie réseau, Jérémie accompagne les clients avec une approche sur-mesure pour soutenir leur croissance."
    },
    {
      name: "Hugo Fraix", function: "Leader Technique", description: "Créateur de la plateforme Linkupp, Hugo en est le développeur principal. Il a conçu et développé l'ensemble du projet, transformant une idée en une solution clé en main pour les entreprises."
    }
  ];

  benefits: any[] = [
    {
      icon: "pi pi-users", title: "Parrainage simplifié", description: "Un processus fluide et intuitif pour vos clients qui souhaitent recommander vos services."
    },
    {
      icon: "pi pi-chart-line", title: "Suivi en temps réel", description: "Visualisez et analysez les performances de votre programme de parrainage."
    },
    {
      icon: "pi pi-gift", title: "Récompenses automatisées", description: "Distribution automatique des récompenses en cash à vos parrains."
    },
    {
      icon: "pi pi-shield", title: "Sécurité garantie", description: "Protection des données et transactions sécurisées pour tous les utilisateurs."
    }
  ];

  private _destroy$ = new Subject<void>();

  demoForm = this.formBuilder.group({
    companyName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')])],
    firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    lastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
  });

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private jwtService: JwtService,
    private router: Router,
    private publicService: PublicService,
    private messageService: MessageService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  getLoading(): boolean {
    return this.loadingService.get();
  }

  goToLoginOrPortal() {
    if (this.jwtService.isValidAuthentication() && this.jwtService.defaultUserRoute) {
      this.router.navigate([this.jwtService.defaultUserRoute]); 
    } else {
      this.router.navigate(['login']);
    }
  }

  getLoginOrPortalLabel(): string {
    return this.jwtService.isValidAuthentication() && this.jwtService.defaultUserRoute ? "Mon portail" : "Se connecter";
  }

  onSubmitDemoForm() {
    const request: BookDemoRequest = new BookDemoRequest(
      this.demoForm.get('companyName')?.value!,
      this.demoForm.get('firstName')?.value!,
      this.demoForm.get('lastName')?.value!,
      this.demoForm.get('email')?.value!,
      this.demoForm.get('phone')?.value!
    );
    this.publicService.bookDemo(request)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: response => {
        this.demoForm.reset();
        this.messageService.add({ severity: 'success', detail: 'Formulaire envoyé', life: 5000 });
      }
    });
  }

  scrollIntoView(elem: string) {
    this.document.querySelector(elem)!.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  showLegalNotices() {
    this.visibleLegalNotices = true;
  }

  showLicenseAgreement() {
    this.visibleLicenseAgreement = true;
  }

  identifyBenefit(index: number) {
    return index;
  }
  
  identifyTeammate(index: number) {
    return index;
  }

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
