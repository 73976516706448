<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="p-2 surface-card" *ngIf="!leadDetailsIsSet() || getLoading()">
            <div class="flex mb-3">
                <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                <div>
                    <p-skeleton width="10rem" styleClass="mb-2" />
                    <p-skeleton width="5rem" styleClass="mb-2" />
                    <p-skeleton height=".5rem" />
                </div>
            </div>
            <p-skeleton width="100%" height="150px" />
            <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
            </div>
        </div>

        <div *ngIf="leadDetailsIsSet()">
            <div class="md:flex justify-content-between">
                <div class="p-2 mb-2 md:mb-5 text-sm text-500 font-medium">
                    Référence: {{ leadDetails.reference}}
                </div>
                <div class="p-2 mb-5">
                    <p-tag
                        [style]="{ 'background-color': getTagBackgroundColor(leadDetails.status?.name), cursor: getTagCursor() }"
                        [value]="leadDetails.status?.label" [rounded]="true" (click)="onChangeStatus()" />
                </div>
            </div>

            <div class="flex justify-content-between p-2 mb-8">
                <div class="flex">
                    <div class="flex flex-column">
                        <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                            <img src="assets/images/web/no_pic.png" class="w-full"
                                *ngIf="leadDetails.company?.logoFilePath === null" [alt]="leadDetails.company?.name" />
                            <img [src]="getLogoFullUrl(leadDetails.company?.logoFilePath)" class="w-full border-round"
                                *ngIf="leadDetails.company?.logoFilePath != null" [alt]="leadDetails.company?.name" />
                        </div>
                        <div class="flex justify-content-center font-bold">
                            {{ leadDetails.company?.name | uppercase}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    LEAD
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-user"></i>
                    </span>
                    <span>
                        {{ leadDetails.leadCustomer?.firstName }} {{ leadDetails.leadCustomer?.lastName }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ leadDetails.leadCustomer?.phone }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ leadDetails.leadCustomer?.email }}
                    </span>
                </div>

                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-map-marker"></i>
                    </span>
                    <span>
                        {{ leadDetails.city }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-globe"></i>
                    </span>
                    <span>
                        {{ leadDetails.source }}
                    </span>
                </div>
            </div>

            <div class="md:flex justify-content-between mb-5">
                <div class="flex flex-column p-2 md:mb-0 mb-5 gap-2">
                    <div class="flex font-bold mb-2">
                        COMMERCIAL
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-user"></i>
                        </span>
                        <span>
                            {{ leadDetails.commercial?.firstName }} {{ leadDetails.commercial?.lastName }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-phone"></i>
                        </span>
                        <span>
                            {{ leadDetails.commercial?.phone }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-at"></i>
                        </span>
                        <span>
                            {{ leadDetails.commercial?.email }}
                        </span>
                    </div>
                </div>
                <div class="flex flex-column p-2 md:p-5 gap-2">
                    <div class="flex md:justify-content-end font-bold mb-2">
                        SAV
                    </div>
                    <div class="flex md:justify-content-end gap-2">
                        <span class="block md:hidden">
                            <i class="pi pi-phone"></i>
                        </span>
                        <span>
                            {{ leadDetails.company?.phone }}
                        </span>
                        <span class="hidden md:block">
                            <i class="pi pi-phone"></i>
                        </span>
                    </div>
                    <div class="flex md:justify-content-end gap-2">
                        <span class="block md:hidden">
                            <i class="pi pi-at"></i>
                        </span>
                        <span>
                            {{ leadDetails.company?.email }}
                        </span>
                        <span class="hidden md:block">
                            <i class="pi pi-at"></i>
                        </span>
                    </div>
                </div>
            </div>

            <p-accordion [multiple]="true">
                <p-accordionTab header="Suivi d'activité" *ngIf="leadDetails.events?.length > 0">
                    <div class="flex justify-content-center">
                        <div class="flex flex-column w-12 md:w-8">
                            <div class="flex flex-column p-2 w-12 mt-3"
                                *ngIf="activeUser === 'commercial' && leadDetails.updatable">
                                <span class="p-float-label">
                                    <textarea pInputTextarea [(ngModel)]="leadMessageEvent" maxlength="255"
                                        class="w-12"></textarea>
                                    <label for="float-input">Note (max. 255 caractères)</label>
                                </span>
                                <p-button label="Enregistrer" icon="pi pi-save" (onClick)="onClickAddNote()" class="text-right"
                                    [loading]="getLoading()" [disabled]="leadMessageEvent.length === 0 || getLoading()" />
                            </div>

                            <div *ngFor="let event of leadDetails.events; trackBy:identifyEvent" class="p-2 w-12">
                                <p-fieldset [legend]="getEventFieldsetHeader(event)">
                                    <p class="m-0">
                                        {{ event.eventMessage }}
                                    </p>
                                </p-fieldset>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>

    </div>
</div>