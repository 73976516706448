<app-lead-details [leadDetails]="leadDetails" [processingStatus]="processingStatus" [activeUser]="'commercial'" (addNoteEmitter)="addNote($event)" (changeStatusEmitter)="updateStatusDialog()"></app-lead-details>

<p-dialog 
    header="Changer le statut" 
    [(visible)]="showChangeStatusDialog" 
    [modal]="true" 
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [draggable]="false" 
    [resizable]="false"
    [style]="{ width: '25rem' }">
    <div class="w-full p-2 mb-2">
        <div class="flex flex-column gap-3">
            <div *ngFor="let status of processingStatus; trackBy:identifyStatus" class="field-checkbox">
                <p-radioButton 
                    [inputId]="status.id"
                    name="status" 
                    [value]="status.id" 
                    [(ngModel)]="selectedStatus" />
                <label [for]="status.id" class="ml-2">
                    {{ status.label }}
                </label>
            </div>
        </div>
    </div>
    <div class="flex gap-2 justify-content-end">
        <p-button icon="pi pi-times" label="Annuler" [rounded]="true" [text]="true" severity="danger"
            (onClick)="rejectUpdateStatus()" />
        <p-button type="submit" icon="pi pi-save" [loading]="getLoading()"
            label="Mettre à jour" [rounded]="true" [text]="true" [disabled]="selectedStatus == null || selectedStatus == initialSelectedStatus || getLoading()"
            (onClick)="acceptUpdateStatus()" />
    </div>
</p-dialog>