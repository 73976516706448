import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KnobModule } from 'primeng/knob';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { Subject, takeUntil } from 'rxjs';
import { ChartModule } from 'primeng/chart';
import { CommonModule, DatePipe } from '@angular/common';

@Component({
  selector: 'app-metrics',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    KnobModule,
    ChartModule
  ],
  templateUrl: './metrics.component.html',
  styleUrl: './metrics.component.sass'
})
export class MetricsComponent implements OnInit, OnDestroy {

  metrics: any = {};

  doughnutSubscriptionsData: any;

  barSubscriptionsData: any;
  barSubscriptionsOptions: any;

  date = new Date();

  private _destroy$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private superAdmin: SuperAdminService
  ) { }

  ngOnInit(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.superAdmin.getMetrics()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.metrics = response;

          this.doughnutSubscriptionsData = {
            labels: ['Start', 'Pro', 'Premium'],
            datasets: [
              {
                data: [response.totalStartSubscriptions, response.totalProSubscriptions, response.totalPremiumSubscriptions]
              }
            ]
          };

          var months = Array.from(new Set(response.totalSubscriptionsByMonthAndYear.map((item: any) => item[1]))).sort((a: any, b: any) => a - b);
          const plans = Array.from(new Set(response.totalSubscriptionsByMonthAndYear.map((item: any) => item[2])));
          const datasets = plans.map(plan => {
            return {
              label: plan,
              data: months.map(month => {
                const value = response.totalSubscriptionsByMonthAndYear.find((item: any) => item[1] === month && item[2] === plan);
                return value ? value[3] : 0;
              })
            };
          });

          months = months.map((monthNumber: any) => this.datePipe.transform(new Date(2024, monthNumber - 1), 'MMMM'));
          this.barSubscriptionsData = {
            labels: months,
            datasets: datasets
          };

          this.barSubscriptionsOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false
              },
              legend: {
                labels: {
                  color: textColor
                }
              }
            },
            scales: {
              x: {
                stacked: true,
                ticks: {
                  color: textColorSecondary
                },
                grid: {
                  color: surfaceBorder,
                  drawBorder: false
                }
              },
              y: {
                stacked: true,
                ticks: {
                  color: textColorSecondary
                },
                grid: {
                  color: surfaceBorder,
                  drawBorder: false
                }
              }
            }
          };
        }
      });
  }

  getKnobEmployeesValue(): string {
    return this.metrics ? this.metrics.totalEmployees + "/" + this.metrics.totalEmployeeLimits : "N/A";
  }

  getKnobTransactionsValue(): string {
    return this.metrics ? this.metrics.totalTransactions + "/" + this.metrics.totalTransactionsLimits : "N/A";
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
