<div class="block">
    <div>
        <div class="text-lg font-bold text-900 mb-3 p-2">MES CLIENTS</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="customers.length === 0">
            <div class="text-xl font-medium text-900">Aucun client trouvé...</div>
            <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewCustomerDialog()" [text]="true" />
        </div>

        <p-table #dt1 [value]="customers" sortField="firstName" [sortOrder]="1"
            [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']" [tableStyle]="{'min-width': '1rem'}"
            responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true" [rows]="5"
            [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="customers.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <p-button label="Créer" icon="pi pi-plus" (onClick)="showNewCustomerDialog()" [text]="true" />
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                    <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                    <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                    <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <td>
                        <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                        <span>{{ customer.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Nom:&nbsp;</span>
                        <span>{{ customer.lastName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Email:&nbsp;</span>
                        <span>{{ customer.email }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                        <span>{{ customer.phone }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="mt-3" *ngIf="prospects.length > 0">
        <div class="text-lg font-bold text-900 mb-3 p-2">MES PROSPECTS</div>

        <p-table #dt2 [value]="prospects" sortField="firstName" [sortOrder]="1"
            [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']" [tableStyle]="{'min-width': '1rem'}"
            responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true" [rows]="5"
            [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="prospects.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                    <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                    <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                    <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-prospect>
                <tr>
                    <td>
                        <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                        <span>{{ prospect.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Nom:&nbsp;</span>
                        <span>{{ prospect.lastName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Email:&nbsp;</span>
                        <span>{{ prospect.email }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                        <span>{{ prospect.phone }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Ajouter un client" [(visible)]="newCustomerDialogIsVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">

    <p-messages key="message-key-dialog-new-customer-commercial" />

    <form [formGroup]="newCustomerForm" (ngSubmit)="onSubmitNewCustomer()">
        <ul class="list-none p-0 m-0 mb-3">
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Email" pInputText formControlName="email"
                            aria-describedby="email-edit" (focusout)="focusOutCustomerEmail()">
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!newCustomerForm.controls.email.errors?.['pattern']">
                            Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                        </small>
                        <small class="text-red-600" id="email-edit"
                            [hidden]="!badRequestCustomerEmail.length">
                            {{ badRequestCustomerEmail }}
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Nom" pInputText formControlName="lastName"
                            aria-describedby="lastName-edit">
                        <small class="text-red-600" id="lastName-edit"
                            [hidden]="!newCustomerForm.controls.lastName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Prénom</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Prénom" pInputText formControlName="firstName"
                            aria-describedby="firstName-edit">
                        <small class="text-red-600" id="firstName-edit"
                            [hidden]="!newCustomerForm.controls.firstName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Téléphone" pInputText formControlName="phone"
                            aria-describedby="phone-edit">
                        <small class="text-red-600" id="phone-edit"
                            [hidden]="!newCustomerForm.controls.phone.errors?.['pattern']">
                            Règles: 10 chiffres.
                        </small>
                        <small class="text-red-600" id="phone-edit"
                            [hidden]="!badRequestCustomerPhone.length">
                            {{ badRequestCustomerPhone }}
                        </small>
                    </div>
                </div>
            </li>
        </ul>

        <div class="flex flex-row gap-3">
            <div class="flex">
                <p-checkbox formControlName="legalNoticesAccepted" [binary]="true" />
            </div>
            <div class="flex">
                <p-scrollPanel [style]="{ width: '100%', height: '100px' }">
                    « Les informations recueillies par <span class="font-medium font-italic">LINKUPP</span> font l'objet
                    d'un
                    traitement informatique ayant pour finalité la gestion de notre fichier clients / prospects,
                    <span class="font-medium font-italic">LINKUPP</span> ayant un intérêt légitime à développer et
                    entretenir une
                    relation commerciale avec ses clients / prospects. Nous vous assurons qu'aucune donnée
                    ne sera communiquée, cédée ni revendue à des tiers. Les données seront conservées pour
                    une durée maximale de 3 ans après la fin de la relation commerciale, ou après votre
                    dernière demande. Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
                    modifiée et au Règlement Européen 2016/679 du 27 avril 2016 relatif à la protection des
                    personnes physiques à l'égard du traitement des données à caractère personnel, vous
                    disposez d'un droit d'accès et de rectification aux informations qui vous concernent ainsi que
                    d'un droit d'opposition, du droit à la limitation du traitement et à l'effacement dans le cadre
                    de la réglementation en vigueur. Vous pouvez exercer ces droits, il vous suffit d'envoyer un
                    email à cette adresse : <span class="font-medium font-italic">contact&#64;linkupp.fr</span>. Si vous
                    estimez, après nous avoir contactés, que vos
                    droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation
                    à la CNIL. »
                </p-scrollPanel>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" severity="danger" (onClick)="closeNewCustomerDialog()" />
            <p-button label="Enregistrer" icon="pi pi-save" type="submit"
                [loading]="getLoading()" [disabled]="!newCustomerForm.valid || getLoading()" />
        </div>
    </form>
</p-dialog>