<div class="text-lg font-bold text-900 mb-3 p-2">PARRAINAGES</div>

<div class="flex justify-content-between mb-3 p-2" *ngIf="sponsorships.length === 0">
    <div class="text-xl font-medium text-900">Aucun parrainage trouvé...</div>
    <p-button label="Parrainer" icon="pi pi-plus" (onClick)="showNewSponsorshipDialog()" [text]="true" *ngIf="activeUser === 'customer'" />
</div>

<p-table #dt1 [value]="sponsorships" selectionMode="single" (onRowSelect)="onRowSelectSponsorship($event)"
    [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack" [breakpoint]="'767px'" sortField="createdAt"
    [sortOrder]="-1"
    [globalFilterFields]="['createdAt', 'reference', 'company.name', 'commercial.firstName', 'commercial.lastName', 'godFather.firstName', 'godFather.lastName', 'godChild.firstName', 'godChild.lastName', 'status.label']"
    [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="sponsorships.length > 0">

    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-end gap-2">
            <p-button label="Parrainer" icon="pi pi-plus" (onClick)="showNewSponsorshipDialog()" [text]="true" *ngIf="activeUser === 'customer'" />
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                    placeholder="Chercher..." />
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="createdAt">Date de création<p-sortIcon field="createdAt" /></th>
            <th pSortableColumn="reference">Référence<p-sortIcon field="reference" /></th>
            <th pSortableColumn="company.name" *ngIf="activeUser === 'customer'">Entreprise<p-sortIcon
                    field="company.name" /></th>
            <th pSortableColumn="commercial.lastName" *ngIf="activeUser === 'customer' || activeUser === 'admin'">
                Commercial<p-sortIcon field="commercial.lastName" /></th>
            <th pSortableColumn="godFather.lastName">Parrain<p-sortIcon field="godFather.lastName" /></th>
            <th pSortableColumn="godChild.lastName">Filleul<p-sortIcon field="godChild.lastName" /></th>
            <th pSortableColumn="status.label">Statut<p-sortIcon field="status.label" /></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sponsorship>
        <tr [pSelectableRow]="sponsorship">
            <td>
                <span class="md:hidden font-medium">Date:&nbsp;</span>
                <span>{{ sponsorship.createdAt | date: 'medium' }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Référence:&nbsp;</span>
                <span>{{ sponsorship.reference }}</span>
            </td>
            <td *ngIf="activeUser === 'customer'">
                <span class="md:hidden font-medium">Entreprise:&nbsp;</span>
                <span>{{ sponsorship.company.name }}</span>
            </td>
            <td *ngIf="activeUser === 'customer' || activeUser === 'admin'">
                <span class="md:hidden font-medium">Commercial:&nbsp;</span>
                <span>{{ sponsorship.commercial.lastName }} {{ sponsorship.commercial.firstName }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Parrain:&nbsp;</span>
                <span>{{ sponsorship.godFather.lastName }} {{ sponsorship.godFather.firstName }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Filleul:&nbsp;</span>
                <span>{{ sponsorship.godChild.lastName }} {{ sponsorship.godChild.firstName }}</span>
            </td>
            <td>
                <span class="md:hidden font-medium">Statut:&nbsp;</span>
                <span class="flex gap-1">
                    <p-tag [style]="{ 'background-color': getTagBackgroundColor(sponsorship.status.name) }"
                        [value]="sponsorship.status.label" [rounded]="true" />
                    <p-tag icon="pi pi-euro"
                        [style]="{ 'background-color': getTagBackgroundColor(sponsorship.bridgeTransactionDetailsOrStatus) }"
                        [value]="sponsorship.bridgeTransactionDetailsOrStatus" [rounded]="true"
                        *ngIf="sponsorship.bridgeTransactionDetailsOrStatus" pTooltip="Statut paiement" />
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>