import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommercialService } from '../../../../../services/commercial.service';
import { Subject, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SponsorshipDetailsComponent as ReusableSponsorshipDetails } from '../../../../../reusable-components/sponsorship-details/sponsorship-details.component';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../../../../services/loading.service';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-sponsorship-details-commercial',
  standalone: true,
  imports: [
    ReusableSponsorshipDetails,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './sponsorship-details.component.html',
  styleUrl: './sponsorship-details.component.sass'
})
export class SponsorshipDetailsComponent implements OnInit, OnDestroy {

  sponsorshipDetails: any = {};
  transactionDetails: any = {};

  showChangeStatusDialog: boolean = false;

  processingStatus: any[] = [];
  initialSelectedStatus: number = 0;
  selectedStatus: number = 0;

  private _destroy$ = new Subject<void>();

  constructor(
    private loadingService: LoadingService,
    private commercialService: CommercialService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    const reference = this.route.snapshot.paramMap.get('reference')!;
    this.commercialService.getSponsorshipDetails(reference)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails = response;
          this.sponsorshipDetails.bridgeTransactionDetails = JSON.parse(this.sponsorshipDetails.bridgeTransactionDetails);
          // To prevent submit same status
          this.initialSelectedStatus = response.status.id;
          this.selectedStatus = this.initialSelectedStatus;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });

    this.commercialService.getProcessingStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.processingStatus = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  getLoading() {
    return this.loadingService.get();
  }

  addNote(event: any) {
    this.commercialService.addNoteToSponsorship(this.sponsorshipDetails.id, event.message)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.sponsorshipDetails.events = [...this.sponsorshipDetails.events, response];

          this.sponsorshipDetails.events.sort((e1: any, e2: any) => {
            const date1 = new Date(e1.createdAt);
            const date2 = new Date(e2.createdAt);

            return date2.getTime() - date1.getTime();
          });
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  updateStatusDialog() {
    this.showChangeStatusDialog = true;
  }

  rejectUpdateStatus() {
    this.showChangeStatusDialog = false;
  }

  acceptUpdateStatus() {
    this.commercialService.updateSponsorshipStatus(this.sponsorshipDetails.id, this.selectedStatus)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.showChangeStatusDialog = false;
          this.sponsorshipDetails = response;
          // To prevent submit same status
          this.initialSelectedStatus = response.status.id;
          this.selectedStatus = this.initialSelectedStatus;

          this.messageService.add({ severity: 'success', detail: 'Statut mis à jour', life: 8000 });
        }, error: error => {
          this.messageService.add({ severity: 'error', detail: error.description });
        }
      });
  }

  identifyStatus(index: any, item: any) {
    return item.id
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
