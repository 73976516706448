export class UpdatePasswordeRequest {

  currentPassword: string | null;
  newPassword: string | null;

  constructor(currentPassword: string | null, newPassword: string | null) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }

}