export class UpdateCompanySubscriptionPlanRequest {

  companyId:  number| null;
  planId: number | null;
  premiumCustomPrice: number | null;
  premiumCustomMaxUsers: number | null;
  premiumCustomMaxBridgeTransastionPerMonth: number | null;

  constructor(companyId:  number| null, planId: number | null, premiumCustomPrice: number | null, premiumCustomMaxUsers: number | null,  premiumCustomMaxBridgeTransastionPerMonth: number | null) {
    this.companyId = companyId;
    this.planId = planId;
    this.premiumCustomPrice = premiumCustomPrice;
    this.premiumCustomMaxUsers = premiumCustomMaxUsers;
    this.premiumCustomMaxBridgeTransastionPerMonth = premiumCustomMaxBridgeTransastionPerMonth;
  }

}