import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { LoadingService } from '../../services/loading.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { PasswordModule } from 'primeng/password';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PublicService } from '../../services/public.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MessagesModule,
    ButtonModule,
    InputTextModule,
    DividerModule,
    PasswordModule,
    RouterModule
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.sass'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  passwordRegex: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+])[A-Za-z\\d!@#$%^&*()_+]{12,20}$';

  resetPasswordForm: FormGroup;

  token: string;

  hideResetForm: boolean = false;

  private _destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private publicService: PublicService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this._destroy$))
      .subscribe(paramMap => {
        if (paramMap.has("token")) {
          this.token = paramMap.get("token")!;

          this.publicService.checkTokenBeforeReset(this.token)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              error: error => {
                this.hideResetForm = true;
              }
            });
        } else {
          this.hideResetForm = true;
        }
      });

    this.resetPasswordForm = this.formBuilder.group({
      newPwd: [null, [Validators.required, Validators.pattern(this.passwordRegex)]],
      confirmPwd: [null, [Validators.required, Validators.pattern(this.passwordRegex)]],
    },
      {
        validators: this.confirmPasswordValidator()
      });
  }

  getLoading(): boolean {
    return this.loadingService.get();
  }

  onSubmitResetPassword() {
    const password: string = this.resetPasswordForm.get("newPwd")?.value!;
    this.publicService.resetPassword(this.token, password)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.router.navigate(['login'], { queryParams: { success: "Le mot de passe a correctement été réinitialisé" } })
        }, error: error => {
          this.hideResetForm = true;
        }
      })
  }

  pwdLowerCase() {
    const password = this.resetPasswordForm.controls['newPwd'].value;
    const lower = /[a-z]/.test(String(password));
    if (password && lower) {
      return true;
    }
    return false;
  }

  pwdUpperCase() {
    const password = this.resetPasswordForm.controls['newPwd'].value;
    const upper = /[A-Z]/.test(String(password));
    if (password && upper) {
      return true;
    }
    return false;
  }

  pwdDigit() {
    const password = this.resetPasswordForm.controls['newPwd'].value;
    const digit = /[\d]/.test(String(password));
    if (password && digit) {
      return true;
    }
    return false;
  }

  pwdSpecialChar() {
    const password = this.resetPasswordForm.controls['newPwd'].value;
    const special = /[!@#$%^&*()_+]/.test(String(password));
    if (password && special) {
      return true;
    }
    return false;
  }

  pwdLength() {
    const password = this.resetPasswordForm.controls['newPwd'].value;
    const length = /^.{12,20}$/.test(String(password));
    if (password && length) {
      return true;
    }
    return false;
  }

  confirmPasswordValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const password = formGroup.get('newPwd')?.value;
      const confirmPassword = formGroup.get('confirmPwd')?.value;

      if (password !== confirmPassword) {
        return { passwordMismatch: true };
      }

      return null;
    };
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
