<div class="block">
    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">

        <div class="p-2 surface-card" *ngIf="!sponsorshipDetailsIsSet() || getLoading()">
            <div class="flex mb-3">
                <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                <div>
                    <p-skeleton width="10rem" styleClass="mb-2" />
                    <p-skeleton width="5rem" styleClass="mb-2" />
                    <p-skeleton height=".5rem" />
                </div>
            </div>
            <p-skeleton width="100%" height="150px" />
            <div class="flex justify-content-between mt-3">
                <p-skeleton width="4rem" height="2rem" />
                <p-skeleton width="4rem" height="2rem" />
            </div>
        </div>

        <div *ngIf="sponsorshipDetailsIsSet()">
            <div class="md:flex justify-content-between">
                <div class="p-2 mb-2 md:mb-5 text-sm text-500 font-medium">
                    Référence: {{ sponsorshipDetails.reference}}
                </div>
                <div class="p-2 mb-5">
                    <p-tag
                        [style]="{ 'background-color': getTagBackgroundColor(sponsorshipDetails.status?.name), cursor: getTagCursor() }"
                        [value]="sponsorshipDetails.status?.label" [rounded]="true" (click)="onChangeStatus()" />
                </div>
            </div>

            <div class="md:text-right font-bold p-2 mb-5 gap-2">
                Récompense&nbsp;<span *ngIf="sponsorshipDetails.updatable">minimale&nbsp;</span>: {{ sponsorshipDetails.rewardAmount }} €
            </div>

            <div class="flex flex-column p-2 mb-8 gap-2">
                <div class="flex">
                    <div class="flex flex-column">
                        <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                            <img src="assets/images/web/no_pic.png" class="w-full"
                                *ngIf="sponsorshipDetails.company?.logoFilePath === null"
                                [alt]="sponsorshipDetails.company?.name" />
                            <img [src]="getLogoFullUrl(sponsorshipDetails.company?.logoFilePath)"
                                class="w-full border-round" *ngIf="sponsorshipDetails.company?.logoFilePath != null"
                                [alt]="sponsorshipDetails.company?.name" />
                        </div>
                        <div class="flex justify-content-center font-bold">
                            {{ sponsorshipDetails.company?.name | uppercase}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    PARRAIN
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-user"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.godFather?.firstName }} {{ sponsorshipDetails.godFather?.lastName }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.godFather?.phone }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.godFather?.email }}
                    </span>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5 gap-2">
                <div class="flex font-bold mb-2">
                    FILLEUL
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-user"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.godChild?.firstName }} {{ sponsorshipDetails.godChild?.lastName }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-phone"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.godChild?.phone }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-at"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.godChild?.email }}
                    </span>
                </div>
                <div class="flex gap-2">
                    <span>
                        <i class="pi pi-map-marker"></i>
                    </span>
                    <span>
                        {{ sponsorshipDetails.city }}
                    </span>
                </div>
            </div>

            <div class="md:flex justify-content-between mb-5">
                <div class="flex flex-column p-2 md:mb-0 mb-5 gap-2">
                    <div class="flex font-bold mb-2">
                        COMMERCIAL
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-user"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.commercial?.firstName }} {{ sponsorshipDetails.commercial?.lastName }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-phone"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.commercial?.phone }}
                        </span>
                    </div>
                    <div class="flex gap-2">
                        <span>
                            <i class="pi pi-at"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.commercial?.email }}
                        </span>
                    </div>
                </div>
                <div class="flex flex-column p-2 md:p-5 gap-2">
                    <div class="flex md:justify-content-end font-bold mb-2">
                        SAV
                    </div>
                    <div class="flex md:justify-content-end gap-2">
                        <span class="block md:hidden">
                            <i class="pi pi-phone"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.company?.phone }}
                        </span>
                        <span class="hidden md:block">
                            <i class="pi pi-phone"></i>
                        </span>
                    </div>
                    <div class="flex md:justify-content-end gap-2">
                        <span class="block md:hidden">
                            <i class="pi pi-at"></i>
                        </span>
                        <span>
                            {{ sponsorshipDetails.company?.email }}
                        </span>
                        <span class="hidden md:block">
                            <i class="pi pi-at"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-column p-2 mb-5" *ngIf="sponsorshipDetails.description">
                <div class="flex font-bold mb-2">
                    DESCRIPTION DU BESOIN
                </div>
                <div class="flex">
                    {{ sponsorshipDetails.description }}
                </div>
            </div>

            <p-accordion [multiple]="true">
                <p-accordionTab header="Suivi d'activité" *ngIf="sponsorshipDetails.events?.length > 0">
                    <div class="flex justify-content-center">
                        <div class="flex flex-column w-12 md:w-8">
                            <div class="flex flex-column p-2 w-12 mt-3"
                                *ngIf="(activeUser === 'customer' || activeUser === 'commercial') && sponsorshipDetails.updatable">
                                <span class="p-float-label">
                                    <textarea pInputTextarea [(ngModel)]="sponsorshipMessageEvent" maxlength="255"
                                        class="w-12"></textarea>
                                    <label for="float-input">Note (max. 255 caractères)</label>
                                </span>
                                <p-button label="Enregistrer" icon="pi pi-save" (onClick)="onClickAddNote()" class="text-right"
                                    [loading]="getLoading()" [disabled]="sponsorshipMessageEvent.length === 0 || getLoading()" />
                            </div>

                            <div *ngFor="let event of sponsorshipDetails.events; trackBy:identifyEvent"
                                class="p-2 w-12">
                                <p-fieldset [legend]="getEventFieldsetHeader(event)">
                                    <p class="m-0">
                                        {{ event.eventMessage }}
                                    </p>
                                </p-fieldset>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <p-accordionTab *ngIf="sponsorshipDetails.bridgeTransactionDetailsOrStatus">
                    <ng-template pTemplate="header">
                        <span class="flex align-items-center w-full">
                            <span class="font-medium">
                                Suivi paiement
                            </span>
                            <p-badge value="!" class="ml-auto mr-2" severity="danger"
                                [hidden]="hideErrorBadge(sponsorshipDetails.bridgeTransactionDetailsOrStatus.status)"
                                pTooltip="Erreur de paiement" tooltipPosition="left" />
                        </span>
                    </ng-template>

                    <div class="flex flex-column p-2 mb-3 text-sm text-500 font-medium">
                        Bridge transaction ID: {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.id }}
                    </div>

                    <div class="flex flex-column p-2 mb-5 gap-2">
                        <div class="flex font-bold mb-2">
                            STATUT
                        </div>
                        <div class="flex">
                            <div class="flex gap-1">
                                <p-tag
                                    [style]="{ 'background-color': getTagBackgroundColor(sponsorshipDetails.bridgeTransactionDetailsOrStatus.status) }"
                                    [value]="sponsorshipDetails.bridgeTransactionDetailsOrStatus.status"
                                    [rounded]="true"
                                    [pTooltip]="setStatusTagTooltipDesc(sponsorshipDetails.bridgeTransactionDetailsOrStatus.status)" />
                                <p-tag severity="danger"
                                    [value]="sponsorshipDetails.bridgeTransactionDetailsOrStatus.status_reason"
                                    [rounded]="true"
                                    *ngIf="sponsorshipDetails.bridgeTransactionDetailsOrStatus.status_reason"
                                    [pTooltip]="setStatusReasonTagTooltipDesc(sponsorshipDetails.bridgeTransactionDetailsOrStatus.status_reason)" />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column p-2 mb-5 gap-2">
                        <div class="flex font-bold mb-2">
                            BENEFICIAIRE
                        </div>
                        <div class="flex gap-2">
                            <span>
                                <i class="pi pi-user"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.beneficiary?.last_name }} {{
                                sponsorshipDetails.bridgeTransactionDetailsOrStatus.beneficiary?.first_name }}
                            </span>
                        </div>
                        <div class="flex gap-2">
                            <span pTooltip="Référence client">
                                <i class="pi pi-link"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.client_reference }}
                            </span>
                        </div>
                        <div class="flex gap-2">
                            <span>
                                <i class="pi pi-credit-card"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.beneficiary?.iban }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-column p-2 mb-5 gap-2">
                        <div class="flex font-bold mb-2">
                            DETAILS
                        </div>
                        <div class="flex gap-2">
                            <span pTooltip="Date d'exécution">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.execution_date | date: 'medium'
                                }}
                            </span>
                        </div>
                        <div class="flex gap-2">
                            <span pTooltip="End to end ID">
                                <i class="pi pi-link"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.end_to_end_id }}
                            </span>
                        </div>
                        <div class="flex gap-2">
                            <span>
                                <i class="pi pi-wallet"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.amount }} €
                            </span>
                        </div>
                        <div class="flex gap-2">
                            <span pTooltip="Libellé">
                                <i class="pi pi-tag"></i>
                            </span>
                            <span>
                                {{ sponsorshipDetails.bridgeTransactionDetailsOrStatus.label }}
                            </span>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>